<template>
    <div id="hgp-image">
      <div class="picture-loding" v-if="loading">
        <img src="../../assets/images/gb/loading.gif">
        <p>正在加载图纸...</p>
      </div>
      <viewer v-else class="viewer" @inited="inited" :options="options" ref="viewer" :key="pictureKey" :style="{'height': height}">
        <img :src="imgUrl" style='zoom:100%;opacity:0;display:none;' @error="error($event)">
      </viewer>
	</div>
</template>
<script>

export default {
  name: "hgp-image",
	props:{
	  src:{
			type: String,
			default: ''
		},
		tranform: {
			type: Boolean,
			default() {
				return true
			}
		},
		height: {
			type: String,
			default: '516px'
		}
	},
	data() {
		return {
       options:{
         inline: true,
         button: false,
         navbar: false,
         title: false,
         toolbar: false,
         tooltip: false,
         movable: true,
         zoomable: true,
         rotatable: false,
         scalable: true,
         transition: false,
         fullscreen: true,
         keyboard: false,
         minWidth: 730,
				 minHeight: 516,
         zoomRatio: 0.2
       },
       pictureKey: '563289',
       defaultImage: require("../../assets/images/default.png"),
       imgUrl: '',
       loading: true
		 }
    },
    created(){
			if(this.tranform) this.toBase64(this.src);
			else {
				this.loading = false;
				this.imgUrl = this.src;
			}
    },
    watch:{
      src(val){
        this.loading = true;
        this.pictureKey = new Date().getTime();
				if(this.tranform) this.toBase64(val);
				else {
					// setTimeout(() => {
						this.loading = false;
						this.imgUrl = val;
					// },700)
				}
      }
    },
		mounted() {

		},
    methods: {
			inited(viewer) {
				console.log(viewer)
			},
      error(e){
        e.srcElement.src = this.defaultImage;
      },
      toBase64(imgUrl) {
          // 一定要设置为let，不然图片不显示
          let image = new Image();
          // 解决跨域问题
          image.setAttribute('crossOrigin', 'use-credentials');
          const imageUrl = imgUrl;
          image.src = imageUrl + '&time=' + new Date().getTime();
          // image.onload为异步加载
          image.onload = () => {
              let canvas = document.createElement('canvas');
              canvas.width = image.width;
              canvas.height = image.height;
              let context = canvas.getContext('2d');
              context.drawImage(image, 0, 0, image.width, image.height);
              // 这里的dataurl就是base64类型
              // 使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
              this.imgUrl = canvas.toDataURL('image/jpeg', 1);
              this.loading = false;
							this.$nextTick(() => {
								console.log(this.$refs.viewer)
							})
          }
      }
    }
}
</script>
<style lang="scss">
  #hgp-image{
    width: 100%;
    .viewer{
      // height: 610px;
      width: 100%;
    }
    .viewer-backdrop{
      background: #fff;
    }
    .picture-loding{
      width: 100%;
      height: 300px;
      text-align: center;
    }
  }
</style>
