<template>
    <div id="hgp-dialog">
		<el-dialog
		:title="title"
		:visible.sync="visible"
		:width="width"
		:top="top"
		:custom-class="appendBodyClass?appendBodyClass:''"
		:before-close="handleClose"
		:append-to-body="appendBody"
    :modal-append-to-body="appendToBody"
    :close-on-click-modal="closeModel" v-dialogDrag>
			<slot name="content"></slot>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: "hgp-dialog",
	props:{
		visible: {
			type: Boolean
		},
		title: {
			type: String,
			default: ''
		},
		width: {
			type: String,
			default: '50%'
		},
		top: {
			type: String,
			default: '15vh'
		},
		appendBody: {
			type: Boolean,
			default() {
				return false
			}
		},
		appendBodyClass: {
			type: String,
			default: ''
		},
		closeModel: {
			type: Boolean,
			default: true
		},
    appendToBody: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {

		}
	},
	methods: {
		handleClose() {
		  this.$emit('handleClose')
		},
	}
}
</script>
<style lang="scss">
	.appendBody {
		.el-dialog__body {
			padding: 16px;
			border-top: 1px solid #E8E8E8;
			margin-top: 12px;
		}
	}
  .el-dialog{
    display: flex;
    display: -ms-flex; /* 兼容IE */
    flex-direction: column;
    -ms-flex-direction: column; /* 兼容IE */
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
  }
  .el-dialog .el-dialog__body{
    max-height: 100%;
    flex: 1;
    -ms-flex: 1 1 auto; /* 兼容IE */
    overflow-y: auto;
    overflow-x: hidden;
  }

  .el-dialog__wrapper {
    /*隐藏ie和edge中遮罩的滚动条*/
    overflow: hidden;
  }
	#hgp-dialog,.hgp-dialog{
		font-size: 12px;
		background-color: #FAFAFA;
		.el-dialog__body {
			padding: 0px;
			border-top: 1px solid #E8E8E8;
			background-color: #FAFAFA;
			border-radius: 0 0 4px 4px;
		}
		.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
			margin-bottom: 12px;
		}
		.el-dialog__headerbtn .el-dialog__close {
			color: #333333;
		}
		.el-dialog__headerbtn {
			top: 14px;
			right: 16px;
		}
		.el-dialog__header {
			padding: 10px 16px;
			line-height: 20px;
			background-color: #F6F6F6;
			border-radius: 4px 4px 0 0;
			text-align: left;
      position: relative;
			.el-dialog__title {
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
</style>
