import request from "@/api_lm/request"
import api from "@/api";
const baseUrl = process.env.VUE_APP_WLM_BASE_URL

// 获取 banner
export function shopBanner(params) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/shop/banner`,
    method: "get",
    params,
  });
}

// 获取店铺介绍信息
export function shopProfile(params) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/shop/profile`,
    method: "get",
    params,
  });
}

/**
 *  获取供应商店铺基本信息接口
 * @param tenantId
 * 返回空没有开通店铺功能
 */
export function getSupplierShopInfo(params) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/shop/base-info`,
    method: "get",
    params,
  });
}

/**
 * 获取我的供应商（不登录可看）
 */
export function getTenantInfo(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/tenant-info`,
    method: "get",
    params: query,
  });
}
