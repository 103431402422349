import request from "@/api_lm/request"
const baseUrl = process.env.VUE_APP_WLM_BASE_URL

/**
 * 我的商品档 - filter接口
 */
export function apiGetFilter(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/my-goods-filter`,
    method: "post",
    data: query,
  });
}

/**
 * 我的商品档 - list接口
 */
export function apiGetList(data={},params={}) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/my-goods-list`,
    method: "post",
    data,
    params,
  });
}

/**
 * 我的商品档 - 收藏/取消收藏
 */
export function settingLmMyfavorite(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/setting-lm-myfavorite`,
    method: "post",
    data: query,
  });
}

/**
 * 我的商品档 - 更改料号
 * @params params
 */
export function settingLmMypartno(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/setting-lm-mypartno`,
    method: "post",
    data: query,
  });
}

/**
 * 我的商品档 - 删除商品
 * @params params
 */
export function removeMyGoods(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/remove-my-goods`,
    method: "post",
    data: query,
  });
}


/**
 * 我的商品档 - 清空已架商品
 * @params params
 */
export function clearDownGoods(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/clear-down-goods`,
    method: "get",
    params: query,
  });
}

/**
 * 快速下单-购物车列表
 * @params params
 */
export function getShoppingCartList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/fastorder/fast-order-car/list`,
    method: "get",
    params: query,
  });
}


/**
 * 快速下单-加入到购物车
 * @params params
 */
export function goToShoppingcart(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/fastorder/fast-order-car/go-to-shoppingcart`,
    method: "post",
    data: query,
  });
}

/**
 * 快速下单-根据我的料号批量加入
 * @params params
 */
export function batchAddMypartno(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/fastorder/fast-order-car/batch-add-mypartno`,
    method: "post",
    data: query,
  });
}


/**
 * 快速下单-批量删除
 * @params params
 */
export function batchDelete(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/fastorder/fast-order-car/batch-delete`,
    method: "post",
    data: query,
  });
}


/**
 * 快速下单-导出我的自定义物料号
 * @params params
 */
export function exportMypartno(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/fastorder/fast-order-car/export-mypartno`,
    method: "get",
    params: query,
    responseType: 'blob',
  });
}

/**
 * 我的商品档，导出
 */
export function mygoodsExport(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/export`,
    method: "post",
    data: query,
    responseType: 'blob',
  });
}

/**
 * 我的商品档，导入
 */
export function mygoodsImport(file = {}) {
  const formData = new FormData();
  formData.append('file', file);
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/import`,
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * 快速下单-导入快速下单
 */
export function fastOrderCarImport(file = {}) {
  const formData = new FormData();
  formData.append('file', file);
  return request({
    url: `${baseUrl}/wlm-api/lmap//v1/lm/fastorder/fast-order-car/import`,
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    responseType: 'blob',
  });
}

// 我的商品档弹窗-下拉供应商


/**
 * 我的商品档弹窗-下拉供应商
 * @params params
 */
export function getMygoodsSellerTenant(query= {}) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mygoods/get-mygoods-seller-tenant`,
    method: "get",
    params: query,
  });
}


/**
 * 采购单中，采购产品数增加或减少，重算合计
 * 工宝对应API
 * export const getSelectAmount = (params) => api.post(`/apigb/v2/mall-shoppingcart/select-amount`,params)
 * * @params params
 */
export function getSelectAmount(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/fastorder/select-amount`,
    method: "post",
    data: query,
  });
}

/**
 * 加入购物车接口
 * 工宝对应API
 * export const addCart = (params) => api.post(`/apigb/v2/mall-shoppingcart/shopping-cart-save${params.length>0 && params[0].hideLoading ? ('?hideLoading='+params[0].hideLoading) : ''}`, params);
 * @params params
 */
export function addCart(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/fastorder/fast-order-car/save-data${query.length > 0 && query[0].hideLoading ? ('?hideLoading=' + query[0].hideLoading) : ''}`,
    method: "post",
    data: query,
  });
}
