/**
 * 创建带有固定 STORAGE_KEY 的存储工具函数
 * 用于 Vuex store 模块持久化本地存储
 * @param {string} key 存储的键 (通常与 Vuex 模块命名相关)
 * @returns {Object} 存储工具函数
 */
export function createStorageUtils(key) {
  return {
    // 从本地存储中获取 Vuex 模块数据
    get() {
      return JSON.parse(localStorage.getItem(key) || '{}');
    },

    // 将 Vuex 模块数据存储到本地
    set(data) {
      localStorage.setItem(key, JSON.stringify(data));
    },

    // 清空本地存储中的 Vuex 模块数据
    clear() {
      localStorage.setItem(key, '{}');
    },

    /**
     * 更新本地存储中 Vuex 模块数据的指定属性
     * @param {string} itemKey 要更新的键 (模块中的某个 state 属性)
     * @param {*} value 更新的值
     */
    updateItem(itemKey, value) {
      const storage = this.get();
      storage[itemKey] = value;
      this.set(storage);
    },

    /**
     * 删除本地存储中 Vuex 模块数据的指定属性
     * @param {string} itemKey 要删除的键 (模块中的某个 state 属性)
     */
    removeItem(itemKey) {
      const storage = this.get();
      delete storage[itemKey];
      this.set(storage);
    },
  };
}
