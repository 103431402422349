// 获取特定的 cookie
export function getCookie(name) {
	const cookies = document.cookie.split('; ');
	for (let cookie of cookies) {
		const [key, value] = cookie.split('=');
		if (key === name) return decodeURIComponent(value);
	}
	return null;
}

// 节流函数
export function throttle(func, limit) {
	let inThrottle;
	return function() {
		const args = arguments;
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => inThrottle = false, limit);
		}
	};
}

// 防抖函数
export function debounce(func, delay) {
	let inDebounce;
	return function() {
		const context = this;
		const args = arguments;
		clearTimeout(inDebounce);
		inDebounce = setTimeout(() => func.apply(context, args), delay);
	};
}

function strHandle(str) {
	if (/\./.test(str)) {
		return str.replace(/\d(?=(\d{3})+\.)/g, "$&,").replace(/\d{3}(?![,.]|$)/g, "$&,");
	} else {
		return str.replace(/\d(?=(\d{3})+$)/g, "$&,");
	}
};
function getExternalThreadString(DataType, ToothType1Id, ToothType2Id, ExternalThread1, ExternalThread2, ToothPrefix1,
	ToothPrefix2, specViewType) {
	/// 获取牙距拼接字段
	/// DataType:公称直径 公英制类型 1 公制 2英制
	/// ToothType1Id:牙形id1
	/// ToothType2Id:牙形id2
	/// ExternalThread1:牙距1 对应ToothType1Id
	/// ExternalThread2:牙距2 对应ToothType2Id
	let ExternalThreadString = '';
	let prefix = "P";
	if (DataType == 2) {
		if (specViewType != 1) {
			if (ToothType1Id > 0 && ToothType2Id == 0) {
				ExternalThreadString = "(" + ToothPrefix1 + ExternalThread1 + ")";
			}
			if (ToothType1Id > 0 && ToothType2Id > 0) {
				ExternalThreadString = "(" + ToothPrefix1 + ExternalThread1 + "-" + ToothPrefix2 +
					ExternalThread2 + ")";
			}
		} else {
			if (ToothType1Id > 0) {
				ExternalThreadString = "(" + ToothPrefix1 + ExternalThread1 + ")";
			}
		}
	} else {
		if (specViewType != 1) {
			if (ToothType1Id > 0 && ToothType2Id == 0 && ExternalThread1 != '') {
				ExternalThreadString = "(" + prefix + ExternalThread1 + ")";
			}
			if (ToothType1Id > 0 && ToothType2Id > 0 && ExternalThread1 != '' && ExternalThread2 != '') {
				ExternalThreadString = "(" + prefix + ExternalThread1 + "-" + prefix + ExternalThread2 + ")";
			}
		} else {
			if (ToothType1Id > 0 && ExternalThread1 != '') {
				ExternalThreadString = "(" + prefix + ExternalThread1 + ")";
			}
		}
	}
	return ExternalThreadString;
}
function getNominalAndLengthString(DataType, BnName, Len) {
	//公称直径和长度拼接成规格
	// DataType:公称直径 公英制类型 1 公制 2英制
	/// BnName:公称直径名称
	/// Len:长度
	if (!Len || Len == 0) {
		return BnName;
	}
	if (DataType == 2) {
		return BnName + "×" + Len; //TODO:Len需要转换为分数
	}
	return BnName + "×" + Len;
}
const gather = {
	apiFilter: ['/Authority/GBLogin', '/Authority/IsEnableVerifyCode', '/Authority/Logout', '/Authority/GetLoginSMSCode',
		'/authority/InternalLogin', '/payOrder/order-status', '/customized/shared-info', '/customized/shared-detail',
		'/standard/mating-standards', '/customized/shared-memberproduct-images', 'customized/shared-get-submemberproducts',
		'customized/process',
		'standard/simple-standard-page', 'standard/stdtype-items','customizationcenter/properties/surface-content',
		'customizationcenter/properties/material-components-and-mechanical','searchgoods/goods-classify',
    'component/filter-nominal','component/filter-tooth-type','component/filter-threadRotation',
    'component/filter-surface','component/filter-material','/promo-info/tenant-info','component/module-types-and-categorys',
    'purchase/pub/list','find-goods/leftover-filter'
	], // 自定义loding接口地址
	formateDate: (times) => { // 日期格式化函数 - 链接
		let time = new Date(times);

		let year = time.getFullYear()
		let month = time.getMonth() + 1
		let day = time.getDate()

		if (month < 10) {
			month = '0' + month
		}

		if (day < 10) {
			day = '0' + day
		}
		return year + '-' + month + '-' + day + ''
	},
	chinDate: (times) => { // 日期格式化函数中文日期
		let time = new Date(times);
		let year = time.getFullYear()
		let month = time.getMonth() + 1
		let day = time.getDate()
		return month + '月' + day + '日'
	},
	filteAmout: (str) => { // 千分符函数
		str = gather.formateMoney(str);
		str = gather.filteComma(str);
		if (str.indexOf('.') != -1) {
			// str.splice()
			let leftStr = str.split('.')[0];
			let rightStr = str.split('.')[1];
			let newStr = strHandle(leftStr) + '.' + rightStr;
			if (newStr == "undefined" || newStr == 'null' || !newStr) {
				return '';
			} else {
				return newStr;
			}
		} else {
			if (strHandle(str) == "undefined" || strHandle(str) == 'null' || !strHandle(str)) {
				return '';
			} else {
				return strHandle(str);
			}
		}
	},
	filteComma: (str) => { // 去除千分符
		str = String(str);
		let reg = new RegExp(',', 'g');
		str = str.replace(reg, ""); // 先删除所有的,防止重复
		return str;
	},
	formateMoney: (money) => { // 保留两位小数
		let f = parseFloat(money);
		if (isNaN(f)) {
			return false;
		}
		f = Math.round(money * 100) / 100;
		let s = f.toString();
		let rs = s.indexOf('.');
		if (rs < 0) {
			rs = s.length;
			s += '.';
		}
		while (s.length <= rs + 2) {
			s += '0';
		}
		return s;
	},
	paramToString: (query) => {
		return Object.keys(query).map(n => encodeURIComponent(n) + '=' + encodeURIComponent(query[n] || '')).join('&');
	},
	// 小数化分数
	decimalsToFractional: (decimals) => {
		let formatDecimals = decimals.toFixed(2);
		let denominator = 100; //初始化分母
		let numerator = formatDecimals * 100; //初始化分子
		let bigger = 0;

		function recursion() {
			bigger = denominator > numerator ? denominator : numerator;
			for (let i = bigger; i > 1; i--) {
				if (Number.isInteger(numerator / i) && Number.isInteger(denominator / i)) {
					numerator = numerator / i;
					denominator = denominator / i;
					recursion();
				}
			}
		}
		recursion();
		return `${numerator}/${denominator}`
	},
	// 字节数
	getByteLen: (val) => {
		let len = 0;
		for (let i = 0; i < val.length; i++) {
			let a = val.charAt(i);
			if (a.match(/[^\x00-\xff]/ig) != null) {
				len += 2;
			} else {
				len += 1;
			}
		}
		return len;
	},
	getExplorerInfo: () => {
		let explorer = window.navigator.userAgent.toLowerCase();
		//ie
		if (explorer.indexOf('msie') >= 0) {
			let ver = explorer.match(/msie ([\d.]+)/)[1];
			return {
				type: 'IE',
				version: ver
			};
		}
		//firefox
		else if (explorer.indexOf('firefox') >= 0) {
			let ver = explorer.match(/firefox\/([\d.]+)/)[1];
			return {
				type: 'Firefox',
				version: ver
			};
		}
		//Chrome
		else if (explorer.indexOf('chrome') >= 0) {
			let ver = explorer.match(/chrome\/([\d.]+)/)[1];
			return {
				type: 'Chrome',
				version: ver
			};
		}
		//Opera
		else if (explorer.indexOf('opera') >= 0) {
			let ver = explorer.match(/opera.([\d.]+)/)[1];
			return {
				type: 'Opera',
				version: ver
			};
		}
		//Safari
		else if (explorer.indexOf('Safari') >= 0) {
			let ver = explorer.match(/version\/([\d.]+)/)[1];
			return {
				type: 'Safari',
				version: ver
			};
		}else {
			let ver = explorer.match(/rv\/([\d.]+)/);
			return {
				type: 'IE',
				version: ver
			};
		}
	},
	// 截取url参数
	getUrlParam: (e, n) => {
		if (!e) throw new Error("parameter url is required.");
		if (!n) throw new Error("parameter key is required.");
		return decodeURIComponent(e).replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(n).replace(
			/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1").split("#")[0]
	},
	//json转url参数
	parseParam: (param, key) => {
	    let paramStr = "";
	    if (param instanceof String || param instanceof Number || param instanceof Boolean) {
	        paramStr += "&" + key + "=" + encodeURIComponent(param);
	    } else {
	        $.each(param, function(i) {
	            let k = key == null ? i : key + (param instanceof Array ? "[" + i + "]" : "." + i);
	            paramStr += '&' + parseParam(this, k);
	        });
	    }
	    return paramStr.substr(1);
	},
	hasScrollbar: () => {
		return document.body.scrollHeight > (window.innerHeight || document.documentElement.clientHeight);
	},
	judgeDivisor: (m, n) => {
			let num = {};
			let i = 0;
			let x = parseInt(m / n);
			m = m % n;
			let result = "";
			while (m != 0 && !(m in num)) {
					num[m] = i++;
					result += parseInt(m * 10 / n);
					m = m * 10 % n;
			}
			return m == 0;
	},
	// 开发用户权限列表
	premissionUserList: [
		15157138175,
		15168318512,
		18668005388,
		18668005911,
		13685798000,
		15824029269,
		13456892555,
		17757147843,
		18658870596,
		13676393951,
		15158198631,
		15715774579,
		15394257266,
		13456892552,
		13673963951
	],
	dateDispose(n){
    n=n.replace(/-/g, "/"); // 兼容safari浏览器
		let now = new Date();
		let aftertime = new Date(n);
		let year = now.getFullYear();
		let mon= now.getMonth()+1;
		let day= now.getDate();
		let year_after = aftertime.getFullYear();
		let mon_after = aftertime.getMonth()+1;
		let day_after = aftertime.getDate();
		let chs = 0;
		let dateTypes = function(s) {
			if (s === 0) return '今日'
			if (s === 1) return '昨日'
			if (s > 1 && s <= 7) return '本周'
			if (s > 7 && s <= 14) return '上周'
			if (s > 14 && s <= 30) return '本月'
			if (s > 30) return n
		}
		//获取当月的天数
		function DayNumOfMonth(Year,Month)
		{
			return 32 - new Date(Year,Month-1,32).getDate();
		}
		if(aftertime.getTime() - now.getTime() < 0){
			let temp1 = day_after;
			let temp2 = mon_after;
			let temp3 = year_after;
			day_after = day;
			mon_after = mon;
			year_after = year;
			day = temp1;
			mon = temp2;
			year = temp3;
		}
		if(year == year_after){//不跨年
			if(mon == mon_after){//不跨年不跨月
				chs += day_after-day;
			}else{//不跨年跨月
				chs += DayNumOfMonth(year,mon)- day+1;//加上第一个不满的
				for(let i=1;i< mon_after-mon;i++){
					chs += DayNumOfMonth(year,mon+i);
				}
				chs += day_after-1;//加上
			}
		}else{//存在跨年
			chs += DayNumOfMonth(year,mon)- day+1;//加上开始年份不满的一个月
			for(let m=1;m<12-mon;m++){
				chs += DayNumOfMonth(year,mon+m);
			}
			for(let j=1;j < year_after-year;j++){
				if((year+j)%400 == 0 || (year+j)%4 == 0 && (year+j)%100 != 0){
					chs += 366;
				}else{
					chs += 365;
				}
			}
			for(let n=1;n <= mon_after;n++){
				chs += DayNumOfMonth(year_after,n);
			}
			chs += day_after-1;
		}
		if(aftertime.getTime() - now.getTime() < 0){
			return dateTypes(chs);
		}else{
			 return chs;
		}
	},
	isWeiXin() {
		let ua = navigator.userAgent.toLowerCase();
	　　let isWeixin = ua.indexOf('micromessenger') != -1;
	　　if (isWeixin) {
	   　　 return true;
	　　}else{
	   　　 return false;
	　　}
	},
	judge() {
		if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
			return "mobile"
		}
		else {
			return "pc"
		}
	},
	getSpecification(DataType, BnName, Len, ToothType1Id, ToothType2Id, ExternalThread1, ExternalThread2,
		ToothPrefix1,
		ToothPrefix2, specViewType) { //获取规格
		let NominalLengthString = getNominalAndLengthString(DataType, BnName, Len);
		let ExternalThreadString = getExternalThreadString(DataType, ToothType1Id, ToothType2Id,
			ExternalThread1,
			ExternalThread2, ToothPrefix1, ToothPrefix2, specViewType);
		return NominalLengthString + ExternalThreadString;
	},
	removeZero: function(num) { //去掉数据中  .0
		let strNum = num + "";
		// let strArr = strNum.match(/\.+0+$/);
		// debugger
		// if(strArr!=null && strArr.length>=0){
		// 	let _num = strArr[0].replace(/\.+0+$/,'');
		// 	// console.log(num + " " + strNum.replace(/\.*0+$/, _num));
		// 	return strNum.replace(/\.+0+$/, _num);
		// }
		return parseFloat(strNum);
	},
	// 单支 转 千支，例：1000支  转换为 1千支
	oneToThousand: function(num, unitName) {
		if (num == null && unitName == null) {
			return '';
		}
		if ((num == 0 || num < 0) && unitName == '支') {
			return '0千支';
		} else {
			if (num == 0 || num < 0) {
				return '0' + unitName + '';
			}
		}
		let branchNum = 0;
		if (unitName == '支') {
			branchNum = num / 1000.0;
			return gather.removeZero(branchNum.toFixed(3)) + '千' + unitName;
		} else {
			// return this.removeZero(num) + '' + unitName;
			return num + "" + unitName;
		}
	},
	// 判断是否允许输入小数
	isBaseUnitDecimalFraction: function(unit) {
		 if (!unit || unit == undefined || unit == null || isNaN(unit)) return true;
		//基础单位枚举值
		let Piece = 1;//支
		let Suit = 2;//套
		let KG = 3;//公斤
		let Thousand = 4;//千支
		let Sheng = 5;//升
		let Zhi = 6;//只
		let PingFang = 7;//平方
		let Fu = 8;//副
		let Tong = 9;//桶
		let Ping = 10;//瓶
		let Mi = 11;//米
		let Ge = 12;//个
		let Dai = 13;//袋
		let Tiao = 14;//条
		let Jian = 15;//件
		let Dun = 16;//吨
		let Gen = 17;//根
		let FenMi = 18;//分米
		let LiMi = 19;//厘米
		let HaoMi = 20;//毫米
		let ThousandSuit = 21;//千套

		if (unit == Piece) {
				return false;
		}
		if (unit == Suit) {
				return false;
		}
		//if (unit == KG) {
		//    return true;
		//}
		//if (unit == Thousand) {
		//    return true;
		//}
		//if (unit == Sheng) {
		//    return true;
		//}
		if (unit == Zhi) {
				return false;
		}
		//if (unit == PingFang) {
		//    return true;
		//}
		if (unit == Fu) {
				return false;
		}
		if (unit == Tong) {
				return false;
		}
		if (unit == Ping) {
				return false;
		}
		//if (unit == Mi) {
		//    return true;
		//}
		if (unit == Ge) {
				return false;
		}
		if (unit == Dai) {
				return false;
		}
		if (unit == Tiao) {
				return false;
		}
		if (unit == Jian) {
				return false;
		}
		//if (unit == Dun) {
		//    return true;
		//}
		if (unit == Gen) {
				return false;
		}
		//if (unit == FenMi) {
		//    return true;
		//}
		//if (unit == LiMi) {
		//    return true;
		//}
		//if (unit == HaoMi) {
		//    return true;
		//}
		//if (unit == ThousandSuit) {
		//    return true;
		//}
		return true;
	},


}

export default gather;
