<template>
	<div id="hgp-table">
		<el-table :header-cell-class-name="headerClass" :cell-class-name="cellClass" :data="tableData" class="hgp-table" :height="height" :border="isBorder" @row-dblclick="dbSelected"
		 @selection-change="handleSelectionChange" @header-dragend="headerDragend" @row-click="handleRowClick" ref="commonRef" :max-height="maxHeight" :empty-text="emptyText">
			<el-table-column align="center" v-if="hasSelect" type="selection" fixed style="text-align: center" width="40"></el-table-column>
			<el-table-column v-if="hasRadio" fixed style="text-align: center" width="40">
			  <template slot-scope="scope">
			    <el-radio :ref="'radio'+scope.$index" :label="scope.$index" v-model="radio">&nbsp;</el-radio>
			  </template>
			</el-table-column>
			<el-table-column :sortable="item.sortable" v-for="(item,index) in tableColumn" :class-name="item.className?item.className:''"
			 v-if="item.isShow != false" :key="index" :align="item.align ? item.align : 'center'" :prop="item.prop" :label="item.label"
			 :min-width="item.minWidth ? item.minWidth : ''" :width="item.width ? item.width : ''" :fixed="item.fixed ? item.fixed : false"
       :render-header="item.renderHeader">
				<template slot-scope="scope">
					<slot v-if="item.slot" :name="item.prop" :obj="scope"></slot>
					<div v-else>{{ scope.row[item.prop] }}</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default {
		name: "y-table-old",
		props: {
			tableColumn: {
				type: Array,
				default () {
					return []
				}
			},
			isBorder: {
				type: Boolean,
				default: true
			},
			hasSelect: {
				type: Boolean,
				default: false
			},
			hasRadio: {
				type: Boolean,
				default: false
			},
			dbClick: {
				type: Boolean,
				default: false
			},
			defaultSelected:{
				type: Boolean,
				default: false
			},
			tableData: {
				type: Array,
				default () {
					return []
				}
			},
			maxHeight: {
				type: [Number, String],
				default: null
			},
			height: {
				type: [Number, String],
				default: null
			},
			headerClass: [Function, String],
			cellClass: [Function, String],
			emptyText: {
				type: String,
				default: '暂无数据'
			}
		},
		data() {
			return {
				radio: ''
			}
		},
    watch: {
      defaultSelected:{  // 只有一条数据时默认选中
        handler(val){
          if(val){
            this.$nextTick(function() {
              this.$refs.commonRef.setCurrentRow(this.tableData[0])
              this.$emit('handleSelection', [this.tableData[0]]);
            })
          }
        },
        deep: true,
        immediate: true
      },
    },
		methods: {
			handleSelectionChange(rows) {
				this.$emit('handleSelection', rows);
			},
			toggleAllSelection() {
				this.$refs.commonRef.toggleAllSelection();
			},
			headerDragend(newWidth, oldWidth, column, event) {
				this.$emit('headerDragend',newWidth, column)
			},
      dbSelected(row){  //双击事件
        if(this.dbClick){
          this.$emit('dbSelected', row);
        }
      },
      handleRowClick(row, column, event) {
		  console.log(column,'-----column')
				if (!column || column.type === 'selection' || column.columnKey === 'operation' || column.type === 'expand') {
				  return
				}
				//解决点击单选按钮执行两次行点击事件的问题
				if (event.srcElement.className == "el-radio__original" || event.srcElement.className == "el-radio-button__inner") {
				  return
				}
				if (this.hasRadio) {
				  this.$refs.commonRef.setCurrentRow(row)
					this.$emit('handleSelection', [row]);
				}
				if (this.hasSelect) {
				  this.$refs.commonRef.toggleRowSelection(row)
				}
      },
			setRadio(index) {
				this.radio = index;
			},
			clearSelection() {
				this.$refs.commonRef.clearSelection();
			},
			toggleRowSelection(row) {
				this.$refs.commonRef.toggleRowSelection(row)
			}
		}
	}
</script>
<style lang="scss">
#hgp-table {
	.el-radio__label {
		padding-left: 0px;
	}
}
</style>
