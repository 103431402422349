<template>
    <div id="hgp-select">
		<div class="hgp-select-top" :style="{'min-width':iconShow?'':minWidth +'px','border-radius':isEnter?'4px 4px 0px 0px':'4px'}">
			<i class="iconfont icon-tupian" v-if="iconShow"></i>
			<span style="color: #333333;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" v-if="value!=''">{{value}}</span>
			<span v-if="keyWord.length==0 && value==''">{{placeholder}}</span>
			<span v-if="keyWord.length!=0 && value==''" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;"><span>请选择</span><span style="color: #333333;margin: 0px 3px;">{{keyWord}}</span>的{{placeholder}}</span>
			<i :class="isEnter?'arrow-up':'arrow'" :style="{'margin-left':iconShow?'':'8px'}"></i>
		</div>
		<hgp-filter
		ref="selectFilter"
		:isShow="filterShow"
		:mode="mode"
		:list="list"
		:shapeTypeList="shapeTypeList"
		:type="type"
		:productType="productType"
		:shapeType="shapeType"
		:defaultValue="defaultValue"
		:hasImage="hasImage"
    :hasHoverImage="hasHoverImage"
		:isCustomName="isCustomName"
		:extendParam="extendParam"
		@changeItem="changeItem"
		@selectProductType="selectProductType"
		@selectShapeType="selectShapeType"
		@deleteShapeType="deleteShapeType"
		@setDefaultName="setDefaultName"
		@clickShapeImg="clickShapeImg"></hgp-filter>
	</div>
</template>
<script>
import hgpFilter from './filter.vue'
export default {
	name: "hgp-select",
	components:{
		hgpFilter
	},
	props:{
		// 条目最小宽度
		minWidth: {
			type: String,
			default: '70'
		},
		// 自定义提示文字
		placeholder: {
			type: String,
			default: '请选择'
		},
		// 是文字选择还是图形选择
		mode:{
			type:[Number,Array],
			default: 1
		},
		// 显示关键词
		keyWord:{
			type: String,
			default: ''
		},
		// 过滤列表数据
		list: {
			type: Array,
			default () {
				return []
			}
		},
		// 图标
		imgShapeList: {
			type: Array,
			default () {
				return []
			}
		},
		// 图形列表
		shapeTypeList: {
			type: Array,
			default () {
				return []
			}
		},
		// 图形数据
		type: {
			type: Array,
			default () {
				return []
			}
		},
		// 是否显示图标
		iconShow: {
			type: Boolean,
			default: true
		},
		// 图形产品
		productType: {
			type:[Number,String],
			default: ''
		},
		// 图形标题选中值
		shapeType: {
			type:[Number,String],
			default: ''
		},
		// 模式一默认值
		defaultValue: {
			type:[Number,String],
			default: ''
		},
		// 是否显示图片
		hasImage: {
			type: Boolean,
			default(){
				return false
			}
		},
		// 是否悬浮显示图片
    hasHoverImage: {
			type: Boolean,
			default(){
				return false
			}
		},
		// 是否自定义名称
		isCustomName: {
			type: Boolean,
			default(){
				return false
			}
		},
		// 扩展字段
		// 1 区分图片大小
		extendParam: {
			type: Number,
			default(){
				return 0
			}
		},
	},
	watch: {
		keyWord: {
			handler(n,o){
			  // console.log(n)
			},
			immediate: true,
			deep: true
		},
		shapeTypeList: {
			handler(n,o){
				// console.log(n)
			  let temp = []
			  for (let i=0;i<this.shapeTypeList.length;i++) {
			  	temp.push(this.shapeTypeList[i].name)
			  }
			  return this.value = temp.join('/')
			},
			immediate: true,
			deep: true
		},
	},
	data() {
		return {
			isEnter: false,
			filterShow: false,
			value:''
		}
	},
	mounted() {
	},
	created() {
		// let _this =this
		// setTimeout(function(){
		// 	_this.iconShow = false
		// },3000)
	},
	methods: {
		enterEle() {
			this.isEnter = true
			this.filterShow = true
		},
		leaveEle() {
			this.$refs.selectFilter.resetOverLi()
			this.isEnter = false
			this.filterShow = false
		},
		clickShapeImg(item) {
			this.$emit('clickShapeImg', item)
		},
		selectShapeType(item) {
			this.$emit('selectShapeType', item)
		},
		selectProductType(item) {
			this.$emit('selectProductType', item)
		},
		deleteShapeType(item) {
			this.$emit('deleteShapeType', item)
		},
		changeItem(val) {
			this.value = val.name
			if(val.setDefault) this.value = item.name
			else this.$emit('changeItem', val)
		},
		clearVal() {
			this.value = ''
			this.$nextTick(() => {
				this.$refs.selectFilter.clearVal()
			})
		},
		setDefaultValue(val) {
			this.$nextTick(() => {
				this.$refs.selectFilter.setDefaultValue(val)
			})
		},
		setDefaultName(item) {
			this.value = item.name
		}
	}
}
</script>
<style lang="less" scoped>
	#hgp-select {
		display: inline-flex;
		position: relative;
		font-size: 12px;
		background: #FFFFFF;
		.arrow {
			font-size: 0;
			line-height: 0;
			border-width: 4px;
			border-color: #333333;
			border-bottom-width: 0;
			border-style: dashed;
			border-top-style: solid;
			border-left-color: transparent;
			border-right-color: transparent;
			margin-left: 6px;
		}
		.arrow-up {
			font-size: 0;
			line-height: 0;
			border-width: 4px;
			border-color: #767B8F;
			border-top-width: 0;
			border-style: dashed;
			border-bottom-style: solid;
			border-left-color: transparent;
			border-right-color: transparent;
			margin-left: 6px;
		}
		.hgp-select-top {
			height: 26px;
			line-height: 25px;
			text-align: center;
			background: #ffffff;
			border: 1px solid #D4D9E1;
			color: #767B8F;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			padding: 0px 7px 0px 7px;
			font-size: 12px;
			max-width: 154px;
		}
		.img-shape {
			min-width: 94px;
		}
		.iconyoujiantou {
			font-size: 8px;
			margin: 0px 2px;
		}
		.icon-tupian {
			margin: 1px 5px 0px 0px;
			font-size: 14px;
		}
		.iconshixinsanjiao {
			font-size: 6px;
			color: #333333;
			margin-left: 4px;
		}
	}
</style>
