import api from "@/api/index";
const preUrl = process.env.VUE_APP_BASE_URL

import request from "@/api_lm/request"
const baseUrl = process.env.VUE_APP_WLM_BASE_URL

/**
 * 获取标准分页列表接口
 * 工宝对应API export const getShopGoodsList = (params) => api.get(`/apigb/v1/shop/product/standard-page-list`,{params});
 */
export function getShopGoodsList(query,data) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/standard-page-list?${query}`,
    method: "post",
    data: data,
  });
}

/**
 * 大类小类列表
 * 工宝对应API export const getGoodsTopCategoryList = (params) => api.get(`/apigb/v1/find-goods/top-category`,{params})
 */
export function getGoodsTopCategoryList(query,data) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/top-category`,
    method: "post",
    params: query,
    data: data,
  });
}

/**
 * 标准列表filter
 * 工宝对应API export const getStdTypeItemList = (params) => api.post(`/apigb/v1/searchgoods/stdtype-item`,params)
 */
export function getStdTypeItemList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/std-filter`,

    method: "post",
    data: query,
  });
}

/**
 *  获取图样形状
 * @param  searchType -查询类型 1 非标 0 标准，固定传-1，categoryTopId-大类ID，categorySecondId- 二类ID；standardId-标准ID，standardTypeId-标准类型；
 * strengthId-强度；surfaceTreatmentId-表色；textureId-材质；nominalId-直径；length -长度；materialId-材料；brandId -品牌；tenantType-商户类型
 * toothTypeId-牙型；availabilityStatus-供货状态；keyWords-关键字，
 * 工宝对应API export const apiGetShapetype = (params) => api.post(`/apigb/v1/searchgoods/shapetype-item`, params)
 */
export function apiGetShapetype(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/shapetype-item`,
    method: "post",
    data: query,
  });
}

/**
 *  获取商品过滤条件
 * @param  searchType -查询类型 1 非标 0 标准，categoryTopId-大类ID，categorySecondId- 二类ID；standardId-标准ID，standardTypeId-标准类型；
 * strengthId-强度；surfaceTreatmentId-表色；textureId-材质；nominalId-直径；length -长度；materialId-材料；brandId -品牌；tenantType-商户类型
 * toothTypeId-牙型；availabilityStatus-供货状态；keyWords-关键字
 * 工宝对应API export const apiGetFilter = (params) => api.post(`/apigb/v1/searchgoods/filter`, params)
 */
export function apiGetFilter(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/filter`,
    method: "post",
    data: query,
  });
}

/**
 *  获取商品列表
 * @param  searchType -查询类型 1 非标 0 标准，categoryTopId-大类ID，categorySecondId- 二类ID；standardId-标准ID，standardTypeId-标准类型；
 * strengthId-强度；surfaceTreatmentId-表色；textureId-材质；nominalId-直径；length -长度；materialId-材料；brandId -品牌；tenantType-商户类型
 * toothTypeId-牙型；availabilityStatus-供货状态；keyWords-关键字，start-0开始页数，limit-限制条数
 * 工宝对应API export const apiGetList = (params) => api.post(`/apigb/v1/searchgoods/list`, params)
 */
export function apiGetList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/list`,
    method: "post",
    data: query,
  });
}


/**
 *  获取3d图地址
 * 工宝对应API export const getweb3dUrlPortal = (params) => api.post('/apigb/v1/customizationcenter/customized/web3ddisplayurl-standard-portal', params);
 */
export function getweb3dUrlPortal(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/find-goods/web3ddisplayurl-standard-portal`,
    method: "post",
    data: query,
  });
}

/**
 * 获取3d图地址
 * 工宝对应API
 * export const getweb3dCustomizedUrl = (params) => api.get('/apigb/v1/customizationcenter/customized/web3ddisplayurl', {params});
 * @params params
 */
export function getweb3dCustomizedUrl(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/find-goods/web3ddisplayurl`,
    method: "get",
    params: query,
  });
}

/**
 * 检查是否是组合件
 * 工宝对应API
 * export const getMating = (params) => api.get('/apigb/v1/customizationcenter/standard/mating-standards', {params});
 * @params params
 */
export function getMating(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/baike/standard/mating-standards`,
    method: "get",
    params: query,
  });
}

/**
 * 非标获取组合件
 * 工宝对应API
 * export const submemberproducts = (params) => api.get(`/apigb/v1/customizationcenter/customized/get-submemberproducts`, {params});
 * @params params
 */
export function submemberproducts(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/get-submemberproducts`,
    method: "get",
    params: query,
  });
}

/**
 * 首页-产品分类接口
 * 工宝对应API
 * 全部商品分类接口 按分类
 * export const getTypeSearchListV2 = () => api.get(`/apigb/v1/searchgoods/goods-classifyv2?dataType=1`)
 * 全部商品分类接口 按标准
 * export const getSTypeSearchListV2 = () => api.get(`/apigb/v1/searchgoods/goods-classifyv2?dataType=3`)
 * 全部商品分类接口 按非标
 * export const getNonSTypeSearchListV2 = () => api.get(`/apigb/v1/searchgoods/goods-classifyv2?dataType=4`)
 * @params params
 * dataType ：   1 按分类     2 按标准   3按标准（非15和25）   4 按标准（只有15和25）；
 */
export function getTypeSearchListV2(query) {
  return request({
    url: `${preUrl}/apiz/rest/asm/center/v1/lm/find-goods/goods-classifyv2`,
    method: "post",
    params: query,
  });
}


/**
 * 产品详情接口，用于在列表上加购
 * 工宝对应API
 * export const apiGetDetailToCart = (params) => api.get(`/apigb/v1/searchgoods/new/detail-to-cart`, {params})
 * @params params
 */
export function apiGetDetailToCart(query) {
  return api({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/new/detail-to-cart`,
    method: "get",
    params: query,
  });
}


/**
 * 加入购物车接口
 * 工宝对应API
 * export const addCart = (params) => api.post(`/apigb/v2/mall-shoppingcart/shopping-cart-save${params.length>0 && params[0].hideLoading ? ('?hideLoading='+params[0].hideLoading) : ''}`, params);
 * @params params
 */
export function addCart(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/shopping-cart-save${query.length > 0 && query[0].hideLoading ? ('?hideLoading=' + query[0].hideLoading) : ''}`,
    method: "post",
    data: query,
  });
}

/**
 * 获取购物车列表
 * 工宝对应API
 * export const getShoppingCartList = (params) => api.get(`/apigb/v2/mall-shoppingcart/list`, {params});
 * * @params params
 */
export function getShoppingCartList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/shopping-car/list`,
    method: "get",
    params: query,
  });
}

/**
 * 删除购物车中商品项接口NEW
 * 工宝对应API
 * export const deleteCartItems = (params) => api.post(`/apigb/v2/mall-shoppingcart/shopping-delete`, params);
 * * @params params
 */
export function deleteCartItems(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/shopping-delete`,
    method: "post",
    data: query,
  });
}

/**
 * 采购单中，查看商户信息
 * 工宝对应API
 * export const getTenantInfo = (params) => api.get(`/apigb/v1/promo-info/tenant-info`, {params})
 * * @params params
 */
export function getTenantInfo(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/tenant-info`,
    method: "get",
    params: query,
  });
}


/**
 * 获取购物车数量（采购单中，产品数量）
 * 工宝对应API
 * export const getCartCount = (params) => api.get(`/apigb/v2/mall-shoppingcart/count`, {params});
 * * @params params
 */
export function getCartCount(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/shopping-car/count`,
    method: "get",
    params: query,
  });
}

/**
 * 采购单中，采购产品数增加或减少，重算合计
 * 工宝对应API
 * export const getSelectAmount = (params) => api.post(`/apigb/v2/mall-shoppingcart/select-amount`,params)
 * * @params params
 */
export function getSelectAmount(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/shopping-car/select-amount`,
    method: "post",
    data: query,
  });
}


/**
 * 获取字典数据
 * 工宝对应API
 * export const commonGetDicConfigList = (params) => api.get('apigb/v1/basic/comm/dic', {params});
 * * @params params
 */
export function commonGetDicConfigList(query) {
  return request({
    url: `${preUrl}/apiz/rest/asm/center/v1/lm/dic`,
    method: "get",
    params: query,
  });
}

/**
 * 获取机械性能参数 （产品及详情）
 * 工宝对应API
 * export const getMachinePropertiesForFree = (params) => api.post(`/apigb/v1/mechanical-behavior/detail-for-free`, params)
 *
 * 原接口：/wlm-api/lmap/v1/lm/mechanical-behavior 换成 /wlm-api/apigb/v1/searchgoods/mechanical-behavior
 * * @params params
 */
export function getMachinePropertiesForFree(query) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/searchgoods/mechanical-behavior`,
    method: "post",
    data: query,
  });
}

export function getMachinePropertiesForFreeBaike(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/mechanical-behavior`,
    method: "post",
    data: query,
  });
}

/**
 * 获取收货地址分页列表接口
 * 工宝对应API
 * export const getAddressList = (params) => api.get(`/apigb/v1/user/shipping-address/list`, {params});
 * * @params params
 */
export function getAddressList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/user/shipping-address/list`,
    method: "get",
    params: query,
  });
}

/**
 * 设置默认收货地址接口
 * 工宝对应API
 * export const setDefaultAddress = (params) => api.post(`/apigb/v1/user/shipping-address/set-is-default`, params);
 * * @params params
 */
export function setDefaultAddress(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/user/shipping-address/set-is-default`,
    method: "post",
    data: query,
  });
}

/**
 * 新增/修改买家收货地址接口
 * 工宝对应API
 * export const saveAddress = (params) => api.post(`/apigb/v1/user/shipping-address/save`, params);
 * * @params params
 */
export function saveAddress(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/user/shipping-address/save`,
    method: "post",
    data: query,
  });
}

/**
 * 获取预提交订单列表
 * 工宝对应API
 * export const getConfirmOrderList = (params) => api.post(`/apigb/v2/mall-shoppingcart/gb-confirm-order`, params);
 * * @params params
 */
export function getConfirmOrderList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/gb-confirm-order`,
    method: "post",
    data: query,
  });
}

/**
 * 删除收货地址接口
 * 工宝对应API
 * export const deleteAddress = (params) => api.post(`/apigb/v1/user/shipping-address/delete`, params);
 * * @params params
 */
export function deleteAddress(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/user/shipping-address/delete`,
    method: "post",
    data: query,
  });
}

/**
 * 创建订单新接口
 * 工宝对应API
 * export const createMarketOrder = (params) => api.post(`/apigb/v1/marketorder/submit-order`, params);
 * * @params params
 */
export function createMarketOrder(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/order/submit-order?ispc=true`,
    method: "post",
    data: query,
  });
}
