<template>
    <div id="y-dialog">
		<el-dialog
		:title="title"
		:visible.sync="visible"
		:width="width"
		:top="top"
		:custom-class="appendBodyClass?appendBodyClass:''"
		:before-close="handleClose"
		:append-to-body="appendBody"
		:close-on-click-modal="closeModel" v-dialogDrag>
			<slot name="content"></slot>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: "y-dialog",
	props:{
		visible: {
			type: Boolean
		},
		title: {
			type: String,
			default: ''
		},
		width: {
			type: String,
			default: '50%'
		},
		top: {
			type: String,
			default: '15vh'
		},
		appendBody: {
			type: Boolean,
			default() {
				return false
			}
		},
		appendBodyClass: {
			type: String,
			default: ''
		},
		closeModel: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {

		}
	},
	methods: {
		handleClose() {
		  this.$emit('handleClose')
		},
	}
}
</script>
<style lang="scss">
	.appendBody {
		.el-dialog__body {
			padding: 16px;
			border-top: 1px solid #E8E8E8;
			margin-top: 12px;
		}
	}
	#y-dialog,.y-dialog{
		font-size: 12px;
		background-color: #FAFAFA;
		.el-dialog__body {
			padding: 0px;
			border-top: 1px solid #E8E8E8;
			background-color: #FAFAFA;
			border-radius: 0 0 4px 4px;
		}
		.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
			margin-bottom: 12px;
		}
		.el-dialog__headerbtn .el-dialog__close {
			color: #333333;
		}
		.el-dialog__headerbtn {
			top: 14px;
			right: 16px;
		}
		.el-dialog__header {
			padding: 10px 16px;
			line-height: 20px;
			background-color: #F6F6F6;
			border-radius: 4px 4px 0 0;
			text-align: left;
			.el-dialog__title {
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
</style>
