<template>
  <div id="hgp-edit-address" v-if="addressVisible">
    <el-dialog :close-on-click-modal="false" custom-class="hgp-dialog edit" :visible="addressVisible" top="15vh"
               :title="title" width="520px" @close="close" v-dialogDrag :custom-class="addFirstAddress?'edit hideClose':'hgp-dialog edit'">
      <div>
        <el-form label-width="100px" ref="editAddress" :model="addressInfo" label-position="right">
          <el-form-item label="收货人：" required>
            <el-input v-model.trim="addressInfo.receiverName" type="text" placeholder="请填写收货人姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" required>
            <el-input v-model="addressInfo.receiverContactInfo" type="number" :maxlength="11"
                      placeholder="请填写收货人手机号"></el-input>
          </el-form-item>
          <el-form-item label="所在区域：" required>
            <el-cascader placeholder="省市区县、乡镇等" ref="addrEl" v-model="cityCode" @change="handleChange"
                         :options="syntheticalData.city"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：" required>
            <el-input v-model.trim="addressInfo.address" type="text" placeholder="街道、楼牌号等"></el-input>
          </el-form-item>
          <el-form-item v-if="isCheckbox" label="">
            <el-checkbox v-model="addressInfo.isDefault"></el-checkbox>
            <label style="margin-left: 8px">设为默认收货地址</label>
          </el-form-item>
        </el-form>
        <div class="dialog-footer1">
          <span v-if="!addFirstAddress" class="dialog-footer-btn cancel" @click="close">取消</span>
          <span v-if="!addFirstAddress" class="dialog-footer-btn confirm" @click="saveEdit">保存</span>
          <span v-else class="dialog-footer-btn confirm" @click="saveEdit">保存并使用该地址</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "hgp-edit-address",
  props: {
    addressVisible: {
      type: Boolean,
      default() {
        return false;
      }
    },
    addressData: {
      type: Object,
      default() {
        return {};
      }
    },
    title: {
      type: String,
      default: ''
    },
    isCheckbox: {
      type: Boolean,
      default() {
        return true;
      }
    },
    addFirstAddress:{ // 控制弹窗关闭按钮显示和按钮显示,无地址必须填写不可关闭
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      syntheticalData: {industry: [], city: []},
      cityCode: [],
      addressInfo: {
        receiverName: '',
        receiverContactInfo: '',
        address: '',
        isDefault: false
      }
    }
  },
  created() {
    // this.url = this.url + '&tk=' + this.token
    axios.get('static/baseData.json').then(res => {
      this.syntheticalData = res.data;
    })
  },
  computed: {
    // ...mapGetters({
    //   token: 'home/token'
    // }),
  },
  watch: {
    addressVisible: {
      handler(val) {
        if (val && this.addressData.regionCode) {
          this.addressInfo = JSON.parse(JSON.stringify(this.addressData))
          this.addressInfo.isDefault = this.addressInfo.isDefault == 1 ? true : false
          this.cityCode = this.addressInfo.regionCode.split(",")
        } else {
          this.addressInfo = {}
          this.cityCode = []
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    handleChange(val) { // 地址选择
      this.cityCode = val[val.length - 1];
      this.addressInfo.regionCode = val.join(',')
      this.$nextTick(() => {
        this.addressInfo.regionName = this.$refs.addrEl.presentText.replace(/\/\ /gi, '')
      })
    },
    saveEdit() {
      if (this.valiDateSubmitInfo()) {
        this.addressInfo.isDefault = this.addressInfo.isDefault == true ? 1 : 0
        this.$emit('successEdit', this.addressInfo);
        this.$emit('update:addressVisible', false);
      }
    },
    // 验证表单信息
    valiDateSubmitInfo() {
      let params = this.addressInfo
      if (!params.receiverName) {
        this.$message.warning('请填写收货人姓名')
        return
      }
      if (!/(^1[3|4|5|6|7|8|9][0-9]{9}$)/.test(params.receiverContactInfo)) {
        this.$message.warning('请输入正确的手机号码')
        return
      }
      if (!params.regionName) {
        this.$message.warning('请选择省市区');
        return
      }
      if (!params.address) {
        this.$message.warning('请输入街道、楼牌号')
        return
      }
      return true
    },
    close() {
      this.$emit('update:addressVisible', false);
    },
  }
}
</script>
<style lang="scss">
#hgp-edit-address {
  .hideClose{
    .el-dialog__headerbtn{
      display: none;
    }
  }
  .el-dialog__body {
    padding: 30px 20px;
    background-color: #FFFFFF;
  }

  .el-checkbox {
    margin-right: 0;
  }

  .el-dialog__header {
    background: #F5F5F5;
    padding: 14px 16px;

    .el-dialog__title {
      font-size: 14px;
      color: #333333;
    }
  }

  .edit {
    .el-select, .el-input {
      width: 320px;
    }

    .dialog-footer1 {
      position: unset;
      text-align: right;
      margin-top: 24px;
      right: 20px;

      .dialog-footer-btn {
        line-height: 16px;
        width: 56px;
        text-align: center;
        border-radius: 4px;
        font-size: 12px;
        box-sizing: border-box;
        color: #333333;
        background: #FFFFFF;
        border: 1px solid #D4D9E1;
        padding: 6px 12px;
        cursor: pointer;
        & + .dialog-footer-btn{
          margin-left: 10px;
        }
      }

      .confirm {
        background: #0C65E7;
        color: #FFFFFF;
        border: none;
      }
    }
  }

  .nav-rt {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;

    i {
      font-size: 16px;
    }
  }

  .footer-btn {
    text-align: right;
    margin-top: 8px;

    .el-button--primary {
      margin-left: 8px;
    }
  }
}
</style>
