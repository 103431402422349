import request from "@/api_lm/request"
// const preUrl = process.env.VUE_APP_GBAPI_URL+"/apigb";
const wlmUrl = process.env.VUE_APP_WLM_BASE_URL+"/wlm-api";
/**
 * 获取标准数据接口 /lmap/v1/baike/standard/standard-info-baike
 * @params params
 */
export function sqstandardInfo(query) {
 return request({
  url: wlmUrl + `/apigb/v1/customizationcenter/standard/standard-info-baike`,
  method: "get",
  params: query,
 });
}
/**
 * 获取直径接口  /lmap/v1/baike/fastener/sqattrnominal-promote
 * @params params
 */
export function sqattrNominal(query) {
 return request({
  url: wlmUrl + `/apigb/v1/baike/fastener/sqattrnominal-promote`,
  method: "get",
  params: query,
 });
}
/**
 * 获取材质接口  /lmap/v1/baike/fastener/sqattrtexture-promote
 * @params params
 */
export function sqattrTexture(query) {
 return request({
  url: wlmUrl + `/apigb/v1/baike/fastener/sqattrtexture-promote`,
  method: "get",
  params: query,
 });
}
/**
 * 获取强度接口  /lmap/v1/baike/fastener/sqattrstrength-promote
 * @params params
 */
export function sqattrStrength(query) {
 return request({
  url: wlmUrl + `/apigb/v1/baike/fastener/sqattrstrength-promote`,
  method: "get",
  params: query,
 });
}
/**
 * 获取材料接口  /lmap/v1/baike/fastener/sqattrmaterial-promote
 * @params params
 */
export function sqattrMaterial(query) {
 return request({
  url: wlmUrl + `/apigb/v1/baike/fastener/sqattrmaterial-promote`,
  method: "get",
  params: query,
 });
}
/**
 * 获取表面处理接口  /lmap/v1/baike/standard/strength-surface-items-promote
 * @params params
 */
export function sqAttrSurface(query) {
 return request({
  url: wlmUrl + `/apigb/v1/customizationcenter/standard/strength-surface-items-promote`,
  method: "get",
  params: query,
 });
}

/**
 * 获取长度接口  /lmap/v1/baike/fastener/sqattrlength-promote
 * @params params
 */
export function sqattrLength(query) {
 return request({
  url: wlmUrl + `/apigb/v1/baike/fastener/sqattrlength-promote`,
  method: "get",
  params: query,
 });
}
/**
 * 获取牙型接口  /lmap/v1/baike/fastener/sqattrtoothtype
 * @params params
 */
export function sqattrToothtype(query) {
 return request({
  url: wlmUrl + `/apigb/v1/baike/fastener/sqattrtoothtype`,
  method: "get",
  params: query,
 });
}
/**
 * 获取商品接口  /lmap/v1/baike/fastener/sqspecifications-pc
 * @params params
 */
export function sqsPecifications(query) {
 return request({
  url: wlmUrl + `/apigb/v1/baike/fastener/sqspecifications-pc`,
  method: "get",
  params: query,
 });
}

/**
 * 检查是否是组合件  /lmap/v1/baike/standard/mating-standards
 * @params params
 */
export function getMating(query) {
 return request({
  url: wlmUrl + `/apigb/v1/customizationcenter/standard/mating-standards`,
  method: "get",
  params: query,
 });
}

/**
 * 临时接口，用于检查非标是否已达上限。主要用于APP非标引入时调用
 * @params params
 */
// export function fbcheck(query) {
//  return request({
//   url: preUrl + `/v1/license/temp-fbcheck`,
//   method: "post",
//   data: query,
//  });
// }

