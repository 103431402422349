<template>
  <div class="info-card">
    <el-popover popper-class="lmPortals-info-card" v-model="visible" placement="bottom" width="632" trigger="click">
      <div class="font14" v-loading="!detailsInfo">
        <div style="color: #333;">卖家信息</div>
        <div class="content" style="margin: 15px 13px 0 13px;">
          <div class="flex">
            <div class="flex-sub flex">
              <div>
                <i class="iconfont icon-a-gongsi1 font16"/>
                <span class="m-l-5">公司：</span>
              </div>
              <div class="flex-sub tx1">{{detailsInfo.companyName}}</div>
            </div>
            <div class="flex-sub flex m-l-5">
              <div>
                <i class="iconfont icon-dizhi font16"/>
                <span class="m-l-5">地址：</span>
              </div>
              <div class="flex-sub tx1">{{detailsInfo.areaName + detailsInfo.address}}</div>
            </div>
          </div>
          <div class="flex m-t-15" v-for="(item,index) of detailsInfo.contacts" :key="index">
            <div class="flex-sub flex">
              <div>
                <i class="iconfont icon-lianxiren font16"/>
                <span class="m-l-5">联系人：</span>
              </div>
              <div class="flex-sub tx1">{{item.contactName}}</div>
            </div>
            <div class="flex-sub flex">
              <div>
                <i class="iconfont icon-zhiwei font16"/>
                <span class="m-l-5">职位：</span>
              </div>
              <div class="flex-sub tx1">{{item.position}}</div>
            </div>
            <div class="flex-sub flex">
              <div>
                <i class="iconfont icon-shouji font16"/>
                <span class="m-l-5">联系电话：</span>
              </div>
              <div class="flex-sub tx1">{{item.mobilePhoneNum}}</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="reference">
        <slot>
          <el-tooltip class="item" effect="dark" content="联系方式" placement="top">
            <i class="iconfont icon-gongyingshangxinxi m-l-10 hand stdInfo" style="color: #999"/>
          </el-tooltip>
        </slot>
      </span>
    </el-popover>
  </div>
</template>

<script>
import {getTenantInfo} from "@/api_lm/lmPortals/gbApi";
import {mapState} from "vuex";

export default {
  name: "info-card",
  props: {
    erpTenantId: {
      type: [Number, String],
    },
  },
  data(){
    return {
      detailsInfo:'',
      visible:false
    }
  },
  computed:{
    ...mapState('lmPortals', ['userInfo']),
  },
  methods:{
    // 供应商信息
    async getTenantInfo(){
      if (!this.userInfo) {
        this.$nextTick(()=>this.visible = false)
        return this.$emit('goLogin')
      }
      let {data} = await getTenantInfo({
        erpTenantId:this.erpTenantId
      })
      // console.log(data,'供应商信息')
      this.detailsInfo =  data
    }
  },
  watch:{
    visible:{
      handler(val){
        if (val) this.getTenantInfo()
      },
      immediate:true,
    }
  }
}
</script>


<style lang="scss" scoped>
.info-card{
  display: inline-block;
}
.lmPortals-info-card{
  .tx1{
    padding-top: 2px;
    color: #333;
  }
  .stdInfo:hover{
    color: #2068DD!important;
  }
}
</style>
