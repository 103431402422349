<template>
  <div>
    <h1 class="title">注册{{$route.meta?.module !== 'lmShop' ? '紧优网':''}}</h1>
    <div>
      <el-input class="m-t-30 inp" v-model="phoneNumber" placeholder="请输入手机号">
        <template slot="prefix">
          <div style="width: 70px;height: 100%;display: flex;align-items: center;justify-content: center;">
            <span style="color: #D93845;">*</span>
            <span style="color: #333333">手机号</span>
          </div>
        </template>
      </el-input>
      <!--短信验证码-->
      <div class="m-t-15 flex align-center">
        <el-input v-model="smsCode" class="inp" placeholder="请输入短信验证码">
          <template slot="prefix">
            <div style="width: 70px;height: 100%;display: flex;align-items: center;justify-content: center;">
              <span style="color: #D93845;">*</span>
              <span style="color: #333333">短信码</span>
            </div>
          </template>
        </el-input>
        <div class="smsCode" :class="{disabled:countdown || checkPhone()}" @click="handleGetSMSCode" v-text="countdown ? `重新发送(${countdown})` : '发送验证码'" />
      </div>
      <el-input class="m-t-15 inp" v-model="userName" placeholder="请输入您的姓名">
        <template slot="prefix">
          <div style="width: 70px;height: 100%;display: flex;align-items: center;justify-content: center;">
            <span style="color: #D93845;">*</span>
            <span style="color: #333333">姓名</span>
          </div>
        </template>
      </el-input>
      <el-input class="m-t-15 inp" v-model="companyName" placeholder="请输入公司或个体户名称">
        <template slot="prefix">
          <div style="width: 70px;height: 100%;display: flex;align-items: center;justify-content: center;">
            <span style="color: #D93845;">*</span>
            <span style="color: #333333">企业</span>
          </div>
        </template>
      </el-input>
      <div class='login-btn m-t-16' @click="register">注册</div>
      <div class="plain-btn m-t-16" @click="changeType">已有账号？立即登录</div>

    </div>

  </div>
</template>

<script>
import { getCaptcha, sendSmsRApi, smsRegister2Api } from "@/api_lm/lmPortals";
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      smsCode: '',
      userName: '',
      phoneNumber: '',
      companyName: "",
      countdown: 0,
      graphCode: {},
    }
  },
  methods: {
    ...mapActions('lmPortals', ['setToken', 'getUserInfo', 'resetState']),
    async register() {
      if (!/^1[3-9]\d{9}$/.test(this.phoneNumber)) return this.$message.error('请正确输入有效的手机号！');
      if (!this.smsCode) return this.$message.error('短信验证码不能为空！');
      if (!this.userName) return this.$message.error('姓名不能为空！');
      if (/^\d+$/.test(this.userName)) return this.$message.error('姓名不允许为数字！');
      if (!this.companyName) return this.$message.error('企业名称不能为空！');
      if (!this.graphCode.cid) return this.$message.error('请正确输入有效的验证码！');

      let { data } = await smsRegister2Api({
        cid: this.graphCode.cid,
        phoneNumber: this.phoneNumber,
        smsCode: this.smsCode,
        userName: this.userName,
        companyName: this.companyName,
      })
      if (data.success) {
        if (data.errorType == 6) this.$message.error(data.message);
        this.setToken(data.token)
        await this.getUserInfo()
        // await this.$router.push('/lmPortals/home')
        await this.onSuccess()
      } else this.$message.error(data.message)
    },
    //校验手机号
    checkPhone() {
      return !/^1[3-9]\d{9}$/.test(this.phoneNumber)
    },
    // 监听 发送验证码
    handleGetSMSCode() {
      if (this.countdown > 0 || this.checkPhone()) return;
      if (!this.phoneNumber) return this.$message.warning('请正确输入手机号！');
      this.sendSms()
    },
    // 请求 获取短信验证码接口
    async sendSms() {
      await this.getCaptcha()
      let { data } = await sendSmsRApi({
        phoneNumber: this.phoneNumber,
        captcha: this.captcha,
        cid: this.graphCode.cid
      })
      if (data.captcha) this.graphCode = data.captcha
      // console.log(data, '获取短信验证码接口')
      if (data.success) {
        // 设置倒计时为60秒
        this.countdown = 60;
        // 启动倒计时
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } else {
        this.$message.error(data.message)
        await this.getCaptcha
      }
    },
    // 请求 获取图形验证码接口
    async getCaptcha() {
      let { data } = await getCaptcha({
        width: 80,
        height: 40
      })
      console.log(data, '获取图形验证码接口')
      this.graphCode = data
      return Promise.resolve()
    },

    // 注册成功后续操作
    onSuccess(){
      this.$emit('onSuccess')
    },

    changeType(){
      this.$emit('changeType')
    }
  }
}
</script>

<style lang="scss" scoped>

:deep(.inp) {
  position: relative;

  .el-input__inner {
    height: 48px;
    padding-left: 96px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 44px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    content: "|";
    position: absolute;
    left: 80px;
    top: 50%;
    transform: translateY(-50%);
    color: #eee;
  }
}
.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}
.login-btn {
  cursor: pointer;
  color: #fff;
  height: 40px;
  background: #0c65e7;
  border-radius: 4px;
  border: 1px solid #0c65e7;
  display: flex;
  justify-content: center;
  align-items: center;
  //position: absolute;
  bottom: 40px;
  width: 360px;
}
.plain-btn {
  cursor: pointer;
  color: #0C65E7;
  height: 40px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #0C65E7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
}
.smsCode {
  cursor: pointer;
  margin-left: 8px;
  width: 104px;
  height: 48px;
  color: #fff;
  font-size: 14px;
  background: #0c65e7;
  font-weight: bold;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  &.disabled {
    color: white;
    background-color: rgba(12, 101, 231, 0.40);
    border-color: #d9ecff;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
