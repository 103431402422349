// src/store/modules/union.js
import {getUserRights} from "@/api_lm/union";
import {getCartCount} from "@/api_lm/union/gbApi";

const state = {
  namespaced: true,
  unionState: false, // 联盟权限
  orderNum: {}, // 采购数量
};

const getters = {
  // 如果需要，可以在这里添加 getters
};

const mutations = {
  SET_UNION_STATE(state, value) {
    state.unionState = value;
  },

  SET_ORDER_NUM(state, value) {
    state.orderNum = value;
  }
};

const actions = {
  // 请求 联盟权限控制
  async getUserRights({ commit }, isError = true) {
    let keys = [
      'gyl_platform_alliance_auth', // 联盟功能权限
      'gyl_alliance_trader_func', // 我发起的联盟功能
      'purchasemanage_purchase_createsave', // 老的 是否有采购权限
      'gyl_po_bill_fastener_save', // 新的 是否有采购权限
    ];
    try {
      const { data } = await getUserRights(keys,isError);
      // console.log(data, '权限控制');
      commit('SET_UNION_STATE', data);
    } catch (error) {
      console.error('获取用户权限失败:', error);
    }
  },

  async getCartCount({ commit }) {
    const { data } = await getCartCount();
    // console.log(data, '获取购物车数量');
    commit('SET_ORDER_NUM', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
