<template>
	<div id="hgp-select-filter">
		<!--  -->
		<ul :class="[judgeiIE?'dropdown-list-ie':'dropdown-list']" :style="{'min-height': overLi?'80px':''}" v-if="isShow&&mode==1&&list.length>0&&hasHoverImage">
			<li class="standar-img" v-for="(item,index) in list" :key="index" @click="changeItem(item)" :class="item.id==value?'customized-select-active':''"
			 @mouseenter.stop="enterLi(item,index)" @mouseleave="leave">
				<div class="des" style="text-align: left;overflow: unset;text-overflow: unset;white-space: unset;" :style="{'width':isCustomName?'100%':'100%'}">{{item.name}}</div>

				<!-- :style="{'margin-top':index>list.length-4&&index!=0&&index!=1?'-80px':''}" -->
				<div class="filter-img" v-if="item.id==overLi">
					<!-- <img :src="item[0].src?item[0].src+'?x-oss-process=image/resize,m_fixed,w_360':''" /> -->
					<el-image :style="{'width':item.src || item.imageUrl?'68px':'68px'}" :src="item.src+'?x-oss-process=image/resize,m_fixed,w_360' || item.imageUrl+'?x-oss-process=image/resize,m_fixed,w_360'">
						<!-- <div slot="error" class="image-slot">
							<img class="default-img" :src="src" />
						</div> -->
					</el-image>
				</div>
			</li>
		</ul>
		<ul :class="[judgeiIE?'dropdown-list-ie':'dropdown-list']" :style="{'height': 'calc(100%+40px)'}" v-if="isShow&&mode==1&&list.length>0&&!hasHoverImage">
			<li v-for="(item,index) in list" :key="index" @click="changeItem(item)" :class="item.id==value?'customized-select-active':''"
			 @mouseenter.stop="enterLi(item,index)" @mouseleave="leave">
				<!-- <div class="el-image" style="width: 68px;height: 68px;"> -->
				<el-image :class="{'el-image-module-type' : extendParam!=0}" :style="{'width':item.imageUrl || item.src?'68px':'68px'}" :src="item.imageUrl+'?x-oss-process=image/resize,m_fixed,w_360' || item.src+'?x-oss-process=image/resize,m_fixed,w_360'"
				 v-if="hasImage">
					<!-- <div slot="error" class="image-slot">
						<img class="default-img" :src="src" />
					</div> -->
				</el-image>
				<!-- </div> -->
				<div class="des" :style="{'width':isCustomName?'100%':'68px'}">{{item.name}}</div>
				<div class="filter-name" :style="{'margin-left': (index+1)%6==0?'0px':'40px'}" :class="list.length<=6 || (list.length>6&& list.length%6==0) || (list.length>6&&index>list.length-(list.length%6 + 1))?'up':''"
				 v-if="item.id==overLi&&item.name.length>5">{{item.name}}</div>
			</li>
		</ul>
		<div class="dropdown-list-2" v-show="isShow&&mode==2&&type.length>0">
			<div class="dropdowm-head">
				<div class="dropdowm-head-tit">
					<div class="dropdowm-head-tit__item" :class="item.id==productType?'active':''" v-for="(item,index) in type" :key="index"
					 @click="selectProductType(item,index)"><span>{{item.name}}</span></div>
				</div>
				<div class="dropdowm-head-name">
					<div class="dropdowm-head-name__item" :class="item.id==shapeType?'active1':''" v-for="(item,index) in shapeTypeList"
					 :key="index" @click="selectShapeType(item,index)">
						<div class="alias">{{item.alias}}：{{item.name}}</div><i class="iconfont iconshanchu" @click.stop="deleteShapeType(item)"></i>
					</div>
				</div>
			</div>
			<ul class="dropdowm-content" v-for="(item, index) in type" :key="index" v-show="item.id==productType">
				<li class="item" v-for="(childrenItem,childrenIndex) in item.children" :key="childrenIndex" @click="clickImg(childrenItem)"
				 @mouseenter.stop="enterLi(childrenItem,childrenIndex)" @mouseleave="leave">
					<div class="img" :style="{'background':childrenItem.imageUrl?'#E8E8E8':'#FFFFFF'}" :class="item.check==childrenItem.id?'active':''">
						<el-image :style="{'width':childrenItem.imageUrl?'68px':'68px'}" :src="childrenItem.imageUrl+'?x-oss-process=image/resize,m_fixed,w_360'">
							<!-- <div slot="error" class="image-slot">
								<img class="default-img" :src="src" />
							</div> -->
						</el-image>
					</div>
					<!--  @mouseenter.stop="enterLi(childrenItem)" @mouseleave="leave" -->
					<div class="des" :class="item.check==childrenItem.id?'active1':''">{{childrenItem.name}}</div>
					<div class="filter-name1" :style="{'margin-left': (childrenIndex+1)%7==0?'0px':'40px'}" :class="item.children.length<=7 || (item.children.length>7&& item.children.length%7==0) || (item.children.length>7&&childrenIndex>item.children.length-(item.children.length%7 + 1))?'up':''"
					 v-if="childrenItem.id==overLi&&childrenItem.name.length>5">{{childrenItem.name}}</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import img from '@/assets/images/default.png'
	export default {
		name: 'hgp-select-filter',
		props: {
			list: {
				type: Array,
				default () {
					return []
				}
			},
			shapeTypeList: {
				type: Array,
				default () {
					return []
				}
			},
			type: {
				type: Array,
				default () {
					return []
				}
			},
			isShow: {
				type: Boolean,
				default () {
					return true
				}
			},
			mode: {
				type: [Number, String],
				default: 1
			},
			productType: {
				type: [Number, String],
				default: ''
			},
			shapeType: {
				type: [Number, String],
				default: ''
			},

			// 模式一默认值
			defaultValue: {
				type: [Number, String],
				default: ''
			},
			hasImage: {
				type: Boolean,
				default () {
					return false
				}
			},
			hasHoverImage: {
				type: Boolean,
				default () {
					return false
				}
			},
			isCustomName: {
				type: Boolean,
				default () {
					return false
				}
			},
			// 扩展字段
			extendParam: {
				type: Number,
				default(){
					return 0
				}
			},
		},
		data() {
			return {
				value: '',
				indexImg: '',
				src: img,
				imgShapeList1: [],
				overLi: '',
				showImg: false,
			}
		},
		watch: {
			// defaultValue(n, o) {
			// 	this.value = n
			// 	let key = this.list.findIndex((val) => val.id == this.value)
			// 	if (key != -1) this.$emit('changeItem', this.list[key])
			// },
			type: {
				handler(n, o) {
					// console.log(n)
				},
				immediate: true,
				deep: true
			}
		},
		mounted() {},
		created() {},
		methods: {
			resetOverLi() {
				this.overLi = ''
			},
			judgeiIE() {
				if (!!window.ActiveXObject || "ActiveXObject" in window) {
					return true;
				} else {
					return false;
				}
			},
			setDefaultValue(val) {
				let key = this.list.findIndex((item) => item.id == val)
				if (key != -1) {
					this.value = val
					this.$emit('setDefaultName', this.list[key])
				}
			},
			changeItem(val) {
				this.value = val.id
				this.$emit('changeItem', val)
			},
			clickImg(item) {
				// let index = this.type.findIndex((val) => val.id == item.parentId)
				// // 判断点击已选择图形
				// if(this.type[index].check==item.id) {
				// 	let shapeImgIndex = this.shapeTypeList.findIndex((val) => val.id == item.id)
				// 	this.deleteShapeType(this.shapeTypeList[shapeImgIndex])
				// }
				// this.indexImg = item
				this.$emit('clickShapeImg', item)
			},
			selectProductType(item, index) {
				this.$emit('selectProductType', item)
			},
			selectShapeType(item, index) {
				this.$emit('selectShapeType', item)
			},
			deleteShapeType(item) {
				// console.log(item)
				this.$emit('deleteShapeType', item)
			},
			clearVal() {
				this.value = ''
			},
			enterLi(item, index) {
				if (this.isCustomName == true) return
				if (this.hasHoverImage) {
					// 显示悬浮图片
					this.overLi = item.id
				} else {
					// 显示悬浮文字
					var totalCount = 0;
					for (var i = 0; i < item.name.length; i++) {
						var c = item.name.charCodeAt(i);
						if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
							totalCount++;
						} else {
							totalCount += 2;
						}
					}
					if (totalCount > 8) this.overLi = item.id
					else this.overLi = ''
				}
				// 判断
				// this.showImg = true
			},
			leave() {
				if (this.hasHoverImage) return
				this.overLi = ''
				// this.showImg = false
			},
		}
	}
</script>
<style lang="less" scoped>
	#hgp-select-filter {
		font-size: 12px;

		.dropdown-list-ie {
			position: absolute;
			border: 1px solid #dadada;
			background: #ffffff;
			text-align: center;
			padding: 16px 0px 8px 16px;
			border-radius: 0 4px 4px 4px;
			z-index: 100;
			// min-width: 150px;
			// max-width: 550px;
			max-height: 530px;
			top: 23px;
			left: 0px;
			line-height: initial;
			width: max-content;
			width: -webkit-max-content; // 兼容
			width: -moz-max-content; // 兼容
			width: 400px;
			display: flex;
			flex-wrap: wrap;
			overflow-y: auto;

			li {
				float: left;
				margin: 0px 20px 8px 0px;
				text-align: left;
				cursor: pointer;
				width: max-content;
				width: -webkit-max-content; // 兼容
				width: -moz-max-content; // 兼容
			}

			li:hover {
				color: #0965E6;
			}

			.el-image {
				height: 68px;
				border: 1px solid #ffffff;
				border-radius: 4px;
				background-color: #E8E8E8;
				display: flex;
				align-items: center;
				justify-content: center;
				/deep/ .el-image__inner {
					width: 68px;
					height: auto;
					// margin-top: 10px;
				}
			}
			.el-image-module-type {
				/deep/ .el-image__inner{
					width: 36px !important;
				}
			}

			.standar-img {
				/deep/ .el-image__inner {
					width: 68px;
					height: 51px;
					margin-top: 10px;
				}
			}

			/deep/ .image-slot {
				background-color: #FFFFFF !important;
			}

			.default-img {
				width: 50px;
				margin: 8px 9px;
			}

			.customized-select-active {
				color: #0965E6 !important;

				.des {
					font-weight: bold;
				}

				.el-image {
					border: 1px solid #0965E6;
				}
			}

			.des {
				height: 16px;
				line-height: 16px;
				width: 68px;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.dropdown-list {
			position: absolute;
			border: 1px solid #dadada;
			background: #ffffff;
			text-align: center;
			padding: 16px 0px 8px 16px;
			border-radius: 0 4px 4px 4px;
			z-index: 100;
			// min-width: 150px;
			// max-width: 550px;
			max-height: 530px;
			top: 25px;
			left: 0px;
			line-height: initial;
			width: 400px;
			// width: -webkit-max-content; // 兼容
			// width: -moz-max-content; // 兼容
			overflow-y: auto;

			li {
				float: left;
				margin: 0px 20px 8px 0px;
				text-align: left;
				cursor: pointer;
				width: max-content;
				width: -webkit-max-content; // 兼容
				width: -moz-max-content; // 兼容
			}

			li:hover {
				color: #0965E6;
			}

			.el-image {
				height: 68px;
				border: 1px solid #ffffff;
				border-radius: 4px;
				background-color: #E8E8E8;

				/deep/ .el-image__inner {
					width: 68px;
					height: 51px;
					margin-top: 10px;
				}
			}

			.standar-img {
				/deep/ .el-image__inner {
					width: 68px;
					height: 51px;
					margin-top: 10px;
				}
			}

			/deep/ .image-slot {
				background-color: #FFFFFF !important;
			}

			.default-img {
				width: 50px;
				margin: 8px 9px;
			}

			.customized-select-active {
				color: #0965E6 !important;
				font-weight: bold;

				.el-image {
					border: 1px solid #0965E6;
				}
			}

			.des {
				height: 16px;
				line-height: 16px;
				width: 68px;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.dropdown-list-ie::-webkit-scrollbar {
			/*滚动条整体样式*/
			width: 10px;
			/*高宽分别对应横竖滚动条的尺寸*/
			height: 6px;
		}

		.dropdown-list-ie::-webkit-scrollbar-thumb {
			/*滚动条里面小方块*/
			border-radius: 10px;
			background-color: #999999;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
		}

		.dropdown-list-ie::-webkit-scrollbar-track {
			/*滚动条里面轨道*/
			background: #FFFFFF;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
		}

		.dropdown-list::-webkit-scrollbar {
			/*滚动条整体样式*/
			width: 10px;
			/*高宽分别对应横竖滚动条的尺寸*/
			height: 6px;
		}

		.dropdown-list::-webkit-scrollbar-thumb {
			/*滚动条里面小方块*/
			border-radius: 10px;
			background-color: #999999;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
		}

		.dropdown-list::-webkit-scrollbar-track {
			/*滚动条里面轨道*/
			background: #FFFFFF;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
		}

		.dropdown-list-ie::-webkit-scrollbar {
			width: 4px;
		}

		.dropdown-list-ie::-webkit-scrollbar-track {
			background-color: #FFFFFF;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		.dropdown-list-ie::-webkit-scrollbar-thumb {
			background-color: #999999;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		.dropdown-list::-webkit-scrollbar {
			width: 4px;
		}

		.dropdown-list::-webkit-scrollbar-track {
			background-color: #FFFFFF;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		.dropdown-list::-webkit-scrollbar-thumb {
			background-color: #999999;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		.dropdown-list-2::-webkit-scrollbar {
			width: 4px;
		}

		.dropdown-list-2::-webkit-scrollbar-track {
			background-color: #FFFFFF;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		.dropdown-list-2::-webkit-scrollbar-thumb {
			background-color: #999999;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		.dropdown-list-2 {
			position: absolute;
			border: 1px solid #dadada;
			background: #ffffff;
			text-align: center;
			// padding: 16px 8px 16px 16px;
			border-radius: 0 4px 4px 4px;
			z-index: 100;
			width: 400px;
			// min-width: 558px;
			top: 25px;
			left: 0px;
			max-height: 530px;

			li {
				cursor: pointer;
			}

			.dropdowm-head {
				display: flex;
				// width: 100%;
				height: 24px;
				line-height: 24px;
				padding: 12px 0px 14px 16px;

				.dropdowm-head-tit {
					display: flex;

					&__item {
						margin-right: 24px;
						cursor: pointer;
						min-width: 30px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.active {
						color: #0965E6;
						border-bottom: 2px solid;
					}
				}

				.dropdowm-head-name {
					display: flex;

					&__item {
						display: flex;
						align-items: center;
						cursor: pointer;
						height: 24px;
						line-height: 13px;
						margin-right: 8px;
						box-sizing: border-box;
						border: 1px solid #D4D9E1;
						border-radius: 4px;
						text-align: center;
						padding: 3px 7px;

						.iconfont {
							margin-left: 5px;
						}

						.alias {
							white-space: nowrap;
						}
					}

					&__item:hover {
						color: #0965E6;
						border: 1px solid #0965E6;
						background: #F7F9FD;
					}

					.active1 {
						color: #0965E6;
						border: 1px solid #0965E6;
						background: #F7F9FD;
					}

					.active {
						color: #0965E6;
						border: 1px solid #0965E6;
						background: #F7F9FD;
					}
				}
			}

			.dropdowm-content {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				text-align: center;
				padding: 0px 8px 12px 16px;
				box-sizing: border-box;

				.item {
					margin-right: 8px;
					padding-bottom: 8px;
					width: 68px;
				}

				.img {
					box-sizing: border-box;
					border: 1px solid #ffffff;
					// background: #E8E8E8;
					border-radius: 4px;
					width: 68px;
					height: 68px;
					// padding-top: 10px;

					.el-image {
						width: 68px;
						border-radius: 4px;
						// height: 68px;
					}

					/deep/ .el-image__inner {
						margin-top: 10px;
					}

					.default-img {
						width: 50px;
					}
				}

				/deep/ .el-image__error {
					width: 66px;
					height: 66px;
				}

				.des {
					height: 16px;
					line-height: 16px;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-top: 5px;
				}

				.item:hover .img {
					// border: 1px solid #0965E6;
					// background: #FFFFFF;
				}

				.item:hover .des {
					color: #0965E6;
				}

				.active {
					border: 1px solid #0965E6;
					background: #FFFFFF;

					/deep/ .el-image__error {
						border-radius: 4px;
					}
				}

				.active1 {
					color: #0965E6;
					font-weight: bold;
				}
			}
		}

		.filter-img {
			width: 68px;
			height: 68px;
			box-sizing: border-box;
			position: absolute;
			background: #FFFFFF;
			margin-left: 40px;
			z-index: 2000;

			img {
				width: 68px;
			}
		}

		.filter-name {
			box-sizing: border-box;
			position: absolute;
			background: #FFFFFF;
			border: 1px solid #dadada;
			padding: 0px 4px;
			line-height: 17px;
			z-index: 2000;
			margin-left: 40px;
			margin-bottom: 0px;
			margin-top: 10px;
			word-break: break-all;

			img {
				width: 68px;
			}
		}

		.filter-name.up {
			margin-top: -40px;
			margin-bottom: 0px;
		}

		.filter-name1 {
			box-sizing: border-box;
			position: absolute;
			background: #FFFFFF;
			margin-top: 7px;
			line-height: 17px;
			border: 1px solid #dadada;
			padding: 0px 4px;
			z-index: 2000;
			margin-left: 40px;
			margin-bottom: 8px;
			word-break: break-all;

			img {
				width: 68px;
			}
		}

		.filter-name1.up {
			margin-top: -40px;
			margin-bottom: 0px;
		}
	}
</style>
