<template>
	<div id="hgp-no-result">
		<div class="main">
			<img src="../../assets/images/gb/common/no-result.png"/>
			<section class="section-content">
				<p class="title">抱歉，没找到相关的商品哦，要不您换个条件我帮您再找找看</p>
				<p class="tip">建议您:</p>
				<p class="tip">1.看看输入的文字是否有误；</p>
				<p class="tip">2.调整关键词，关键词用空格隔开；</p>
			</section>
		</div>
	</div>
</template>
<script>
	export default {
		name: "hgpNoResult",
	}
</script>
<style lang="scss">
	#hgp-no-result {
		.main {
			display: flex;
			justify-content: center;
			align-items: center;
      padding: 200px 0;
		}
		img {
			width: 120px;
			height: 120px;
			margin-top: -48px;
			margin-right: 20px;
		}
		.section-content {
			.title {
				color: #333333;
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 4px;
			}
			.tip {
				color: #666666;
				font-size: 14px;
				line-height: 20px;
				margin-top: 8px;
			}
			.btn {
				margin-top: 12px;
				color: #fff;
				font-size: 14px;
				background-color: $hgp-main-color;
				border-radius: 4px;
				width: 96px;
				height: 28px;
				line-height: 28px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
