import router from '../router/index';

// ERP中跳转链盟
export function toLmErp(config={}) {
  let path, newPage;
  if (typeof config === 'string') {
    path = config;
    newPage = false; // 默认值为 false
  } else if (typeof config === 'object') {
    ({ path, newPage = false } = config); // 解构并设置 newPage 的默认值为 false
  }
  try {
    let url = '/gyl/#/alliance';
    if (path) url = url + `?childUrl=${encodeURIComponent(path)}`;
    routeERP(`gyl_alliance_page${new Date().getTime()}`, '紧优网', url); // 其他环境跳转公用方法
  } catch (e) {
    if (path) router.push({path});
    else router.push({path: '/union/home'});
  }
}

// ERP页签跳转
export function routeERP(key, title, route) {
  top.removeTab(key); // 删除tab页
  top.createTab(key, title, route);
  console.log(key, title, route, "==routeERP");
}

export function replaceUrl(url) {
  return url.replace('#', '_')
    .replace('#', '')
    .replace(/\//g, '_')
    .replace(/\\/g, '_')
    .replace(/\?/g, '_')
    .replace(/\&/g, '_')
    .replace(/\=/g, '_')
    .replace(/\-/g, '_')
    .replace(/\:/g, '_')
    .replace(/\,/g, '_')
    .replace(/\./g, '_')
    .replace(/\|/g, '_');
}


/**判断是手机还是浏览器 */
export function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

/**获取当前完整域名 */
export function getDomain() {
  return document.location.protocol + '//' + document.domain
}

/**获取当前url地址栏某个参数 */
export function getQueryVariable(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.href.split('?')[1].match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}


window.openKefuWindow=function (url) {
  var windowName = 'jywKefuWindow'; // 窗口名称
  var windowFeatures = 'height=568, width=860, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no, status=no,alwaysRaised=yes,z-look=true'; // 窗口特征
  // url = url.replace('https://localhost:8080','http://localhost:5173');//开发环境，调试使用
  // url = url + "&tk=eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ3d3cuNTFzZXJwLmNvbSIsImF1ZCI6IndsbS5pbnRlcm5hbC10ZXN0LjUxaGdwLmNvbSIsInN1YiI6IjZMODJGd3hnSkRtUDdOTjFUQ2cxMUxmcnB2bTJXMGNBUnE1d3Q0RWFxc202QTVxc2FTTkZUMm9DM3FSZGtLNTZKV0JWdGcxVkNOY24vNmsyQ3h0ZEpRPT0iLCJpYXQiOjE3MzU5NTYzMzcsImV4cCI6MTczNTk5OTUzN30.ECPXbSfWWfqtsckaXqfr1YD5-H7U3l4yUCt5Y_Z9up4"
  // 检查窗口是否已经打开
    let message = {reloadFlag: 1}
 // 检查窗口是否已经打开
  var win = window.open(url, windowName, windowFeatures);
  win.postMessage(JSON.stringify(message), '*')
  // var win = window.open(url, windowName);
  if (win) {
    // 如果已打开，则获取该窗口的引用并带到前台
    win = window.open('', windowName);
    win.focus();
  }
}