import axios from 'axios';
import router from '../router/index';
import gather from '../utils/utils';
import {
    Loading,
    Message
} from 'element-ui';
import store from '@/store';

const api = axios.create({
    timeout: 10000,
    withCredentials: true,
});


let clearTime = null,
  clearMessage = null;


axios.defaults.transformRequest = [function(data) {
    let newData = ''
    for (let k in data) {
        newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
    }
    return newData
}]

// 请求发送拦截，目前需要结合各项目自身来处理...
let loadingInstance;
api.interceptors.request.use(
  config => {
    if (!(config.params && config.params.hideLoading == true)) {
      loadingInstance = Loading.service({
        lock: true,
        text: '拼命加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)',
        customClass: 'create-isLoading',
      })
    }
      return config
  },
  req => Promise.reject(req),
);

// 请求返回拦截，目前需要结合各项目自身来处理...
api.interceptors.response.use(
  res => {
      if (loadingInstance) loadingInstance.close();
      return Promise.resolve(res);
  },
  err => {
      if (err.toString().indexOf('401') > -1) {
          // router.push('/login');
      }
      if (loadingInstance) loadingInstance.close();
      return Promise.reject(err)
  },
);

export default api;
