<template>
  <div class="y-category">
    <div v-clickoutside="close" class="button" @click.stop="handleClickCategory">标准库目录  <i class="icon-icon_xiala iconfont" :class="listShow?'rotate':''"></i> </div>
    <div class="list-box" v-if="listShow" @click.stop="">
      <div class="main-cont">
        <ul class="search-list">
          <li>
            <label>大类</label>
            <el-select v-model="checkBigId" @change="checkBig(checkBigId)" placeholder="请选择" :popper-append-to-body="false"  clearable>
              <el-option
                  v-for="(item,index) in bigcategoryList"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
                <div class="img-container">
                  <img :src="item.imageUrl + '?x-oss-process=image/resize,m_fixed,w_120'" />
                  <span>{{ item.name }}</span>
                </div>
              </el-option>
            </el-select>
          </li>
          <li>
            <label>小类</label>
            <el-select v-model="checkSmallId" placeholder="请选择" @change="checkSmall(checkSmallId)" :popper-append-to-body="false" clearable>
              <el-option
                  v-for="(item,index) in smallCategoryList"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
                <div class="img-container">
                  <img :src="item.imageUrl + '?x-oss-process=image/resize,m_fixed,w_120'" />
                  <span>{{ item.name }}</span>
                </div>
              </el-option>
            </el-select>
          </li>
          <li v-if="standardList.length > 0">
            <label>标准</label>
            <el-select v-model="checkStandardId" placeholder="请选择" @change="checkStand" :popper-append-to-body="false" clearable>
              <el-option
                  v-for="(item,index) in standardList"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="search-list1">
          <li v-if="shapeList.length > 0">
            <label>图样形状</label>
            <ol class="sr-right" :class="checShapekList.filter(e=>e.checkId).length?'has-selected':''">
              <li class="shape-item" :class="checShapekList.find((it) => it.id == item.id).checkId?'has-shape':''" v-for="(item,index) in shapeList"
                  :key="index">
                <div class="shape-tit">
                  <div class="">
                    <!--                    <img v-if="checShapekList.find((it) => it.id == item.id)&&checShapekList.find((it) => it.id == item.id).checkId" :src="checShapekList.find((it) => it.id == item.id)&&checShapekList.find((it) => it.id == item.id).checkId?checShapekList.find((it) => it.id == item.id).imageUrl:item.imageUrl">-->
                    <p>{{checShapekList.find((it) => it.id == item.id)&&checShapekList.find((it) => it.id == item.id).checkId?checShapekList.find((it) => it.id == item.id).name.split('：')[1]:item.name}}</p>
                  </div>
                  <i class="el-icon-arrow-down"></i>
                </div>
                <ul class="shape-it-pit">
                  <li v-for="(n,childIndex) in item.children" :key="childIndex" :class="checShapekList.find((it) => it.id == item.id).checkId == n.id?'check-it-pit':''"
                      @click.stop="shapeCheck(n)">
                    <img v-if="n.imageUrl" :src="n.imageUrl + '?x-oss-process=image/resize,m_fixed,w_120'" />
                    <img v-else src="../../../assets/images/default.png">
                    <p>{{n.name}}</p>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ul>
        <div class="standCount" v-if="standData.length>0">
          <div class="std-item" v-for="(item,index) in standData" :key="index" @click.stop="jumpGoods(item)">
            <img :src="item.imageUrl + '?x-oss-process=image/resize,m_fixed,w_240'">
            <p class="st-it-tl">{{item.standardName}}</p>
            <p class="st-it-ex" v-if="item.standardTypeId !=15 && item.standardTypeId!=25">{{item.productName}}</p>
            <!-- <span v-if="item.specRange" class="replace">直径：{{item.specRange}}</span> -->
            <div class="replace" v-if="item.replaceStandardList.length"
                 @click.stop="showReplaceData(item.replaceStandardList)">
              <el-tooltip popper-class="remark-popper" trigger="click" effect="light"
                          :content="item.replaceStandardList.map(rItem => rItem.standardName).join(',')"
                          placement="bottom">
                <span class="replace-text highlight-text">代用
                  ：{{item.replaceStandardList.map(rItem => rItem.standardName).join(',')}}</span>
              </el-tooltip>
              <span class="highlight-text">{{item.replaceStandardNum}}种</span>
            </div>
            <div class="replace" v-else>代用：无</div>
          </div>
        </div>
        <div class="blank" v-else>
          <i class="iconfont icon-zanwushuju"></i>
          <p>暂无数据</p>
        </div>
        <el-pagination class="hgp-pagination" v-if="standData.length>0" background @size-change="handleSizeChange"
                       @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10]"
                       :page-size="limt" layout="prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBigcategory,
  getShapeType,
  getSmallCategory,
  getSqstandardsV2,
  getStandardList
} from "@/api_lm/lmPortals/utilityTools/standardview/fastener";

export default {
  name: "yCategory",
  data() {
    return {
      listShow:false,
      bigcategoryList: [],
      smallCategoryList: [],
      standardList: [],
      shapeList: [],
      standData: [],
      checkBigId: '',
      checkSmallId: '',
      keyword: '',
      currentPage: 1,
      limt: 10,
      checkStandardId: '',
      checShapekList: [],
      cid: ''
    };
  },
  created() {
    this.into()
  },
  methods: {
    handleClickCategory(){
      this.listShow=!this.listShow;

      if(this.listShow){
        this.$emit('handleToIsHidden');
      }else{
        this.$emit('handleCloseCategory');
      }
    },
    close(){
      this.listShow = false;
    },
    jumpGoods(item){
      this.$emit('handleCheckIndex',item.standardId)
      this.listShow = false
    },
    into(type) {
      getBigcategory({
        keyword: this.keyword,
        showInPc: true,
        isGb: 0
      }).then(res => {
        if (res.status == 200) {
          this.bigcategoryList = res.data;
        }
      }).catch(err => {
        this.$message.error("请求错误，请重试！");
      });
      this.getList();
      this.getStandardType()
    },
    getList() {
      let prames = {
        smallCategory: 0,
        start: this.currentPage,
        standardTypeId: this.checkStandardId,
        keyword: this.keyword,
        shapeTypes: "",
        bigCategoryId: this.checkBigId,
        smallCategoryId: this.checkSmallId,
        length: this.limt,
        isGb: 0
      }
      if (this.checkBigId && this.checkSmallId) {
        prames.smallCategory = this.checkSmallId;
      } else if (this.checkBigId && !this.checkSmallId) {
        prames.smallCategory = this.smallCategoryList[0].id;
      }
      if (this.checShapekList.length > 0) {
        this.checShapekList.forEach((item) => {
          if (!prames.shapeTypes && item.code) {
            prames.shapeTypes = item.code;
          } else if (item.code) {
            prames.shapeTypes = prames.shapeTypes + ',' + item.code;
          }
        })
      }
      getSqstandardsV2(prames).then(res => {
        if (res.status == 200) {
          this.standData = res.data.data;
          this.total = res.data.total;
        }
      }).catch(err => {
        this.$message.error("请求错误，请重试！");
      });
    },
    getStandardType() {
      let shapeTypes = '';
      if (this.checShapekList.length > 0) {
        this.checShapekList.forEach((item) => {
          if (!shapeTypes && item.code) {
            shapeTypes = item.code;
          } else if (item.code) {
            shapeTypes = shapeTypes + ',' + item.code;
          }
        })
      }
      let params = {
        showInPc: true,
        bigCategoryId: this.checkBigId,
        smallCategoryId: this.checkSmallId,
        shapeTypes: shapeTypes,
        keyword: this.keyword,
        from: 1,
        isGb: 0
      };
      getStandardList(params).then(res => {
        if (res.status == 200) {
          this.standardList = res.data;
        }
      })
          .catch(err => {
            this.$message.error("请求错误，请重试！");
          });
    },
    getHeaderPicture() {
      let shapeTypes = '';
      this.checkStandardId = '';
      if (this.checShapekList.length > 0) {
        this.checShapekList.forEach((item) => {
          if (!shapeTypes && item.code) {
            shapeTypes = item.code;
          } else if (item.code) {
            shapeTypes = shapeTypes + ',' + item.code;
          }
        })
      }
      getShapeType({
        standardTypeId: this.checkStandardId,
        smallCategoryId: this.checkSmallId,
        shapeTypes: shapeTypes,
        isGb: 0
      })
          .then(res => {
            if (res.status == 200) {
              this.shapeList = res.data;
            }
          })
          .catch(err => {
            this.$message.error("请求错误，请重试！");
          });
    },
    checkStand(id) {
      // 标准选择事件
      this.checkStandardId = id;
      this.currentPage = 1;
      this.getList();
    },
    checkBig(id, type) {
      // 大类的选择事件
      this.checkBigId = id;
      this.checkSmallId = '';
      this.smallCategoryList = [];
      this.shapeList = [];
      this.checShapekList = [];
      this.checkStandardId = '';
      getSmallCategory({
        bigCategoryId: this.checkBigId,
        standardTypeId: this.checkStandardId,
        keyword: this.keyword,
        showInPc: true,
        isGb: 0
      })
          .then(res => {
            if (res.status == 200) {
              if(res.data.length) {
                this.smallCategoryList = res.data[0].smallItems;
                if (type) this.checkSmall(this.smallCategoryList[0].id)
                else {
                  this.currentPage = 1;
                  this.getStandardType();
                  this.getList();
                }
              }else{
                this.currentPage = 1;
                this.smallCategoryList = []
                this.standardList = []
                this.standData = [];
                this.total = 0;
              }
            }
          })
          .catch(err => {
            this.$message.error("请求错误，请重试！");
          });
    },
    checkSmall(id) {
      this.checkSmallId = id;
      this.checkStandardId = '';
      if(!this.checkSmallId){
        this.shapeList = [];
        this.checShapekList = [];
        this.checkStandardId = '';
      }else{
        getShapeType({
          standardTypeId: this.checkStandardId,
          smallCategoryId: this.checkSmallId,
          shapeTypes: '',
          isGb: 0
        })
            .then(res => {
              if (res.status == 200) {
                this.shapeList = res.data;
                this.checShapekList = [];
                this.shapeList.forEach(item => {
                  let obj = {
                    id: item.id,
                    name: "",
                    checkId: '',
                    code: ''
                  };
                  this.checShapekList.push(obj);
                });
              }
            })
            .catch(err => {
              this.$message.error("请求错误，请重试！");
            });
      }
      this.currentPage = 1;
      this.getStandardType();
      this.getList();
    },
    shapeCheck(val) {
      let index = this.checShapekList.findIndex(
          item => item.id == val.parentId
      );
      if (this.checShapekList[index].checkId && this.checShapekList[index].code == val.code) {
        this.checShapekList[index].checkId = '';
        this.checShapekList[index].code = '';
        this.checShapekList[index].name = '';
        this.checShapekList[index].imageUrl = ''
      } else {
        this.checShapekList[index].checkId = val.id;
        this.checShapekList[index].code = val.code;
        this.checShapekList[index].name = val.parentName + "：" + val.name;
        this.checShapekList[index].imageUrl = val.imageUrl;
      }
      this.currentPage = 1;
      this.getHeaderPicture();
      this.getStandardType();
      this.getList();
    },
    handleSizeChange(val) { //每页显示条数改变触发函数
      this.limt = val;
      this.currentPage = 1;
      this.getStandardType();
      this.getList();
    },
    handleCurrentChange(val) { // 改变当前页数
      this.currentPage = val;
      this.getStandardType();
      this.getList();
    },
  }
};
</script>
<style lang="scss" scoped>
.y-category {
  position: relative;
  .main-cont{
    padding: 12px;
  }
  .search-list1{
    margin-top: 12px;
    >li{
      display: flex;
      align-items: center;
      border: 1px solid #e8e8e8;
      height: 40px;
      //margin-right: 12px;
      position: relative;
      label {
        width: 70px;
        height: 40px;
        line-height: 40px;
        background: #f8f8f8;
        text-align: center;
        border-right: 1px solid #e8e8e8;
        font-size: 12px;
        //margin-right: 8px;
      }
      .el-select{
        width: 104px;
        margin-right: 12px;
      }
    }
    .img-container{
      width: 40px;
      height: 30px;
      img{
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
    .sr-right {
      display: inline-flex;
      box-sizing: border-box;
      flex-wrap: wrap;
      background: #fff;
      flex: 1;
      border: 1px solid #e8e8e8;
      border-left: none;
      border-right: none;
      padding: 6px 12px;
      position: relative;
      .sd-item {
        cursor: pointer;
        background: #fff;
        p {
          padding: 0px 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .img-item1 {
        height: 78px;
      }

      .img-item {
        width: 73px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        cursor: pointer;
        margin-right: 8px;
        padding-bottom: 2px;
        &:hover {
          border: 1px solid $blue;
          color: $blue;
        }

        img {
          height: 42px;
          margin-bottom: 4px;
        }
      }

      .shape-item {
        width: 100px;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
        margin-right: 12px;
        .shape-tit {
          position: relative;
          z-index: 100;
          width: 100%;
          height: 100%;
          background-color: #FFFFFF;
          border: 1px solid #DCDEE0;
          font-size: 12px;
        }

        &:hover {
          height: 31px;
          background: #fff;
          z-index: 9;
          color: $blue;
          .el-icon-arrow-down{
            color: $blue;
          }
          .shape-it-pit {
            display: flex;
            color: #333333;
          }
        }
      }

      &.has-selected {
        .has-shape{
          color: $blue;
        }
      }

      .el-icon-arrow-down {
        margin-left: 4px;
      }

      .shape-tit {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
          height: 40px;
        }
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          max-width: 100px;
          //height: 100%;
        }

      }

      .shape-it-pit {
        width: 768px;
        display: none;
        flex-wrap: wrap;
        position: absolute;
        top: 40px;
        left: -72px;
        border: 1px solid #DCDEE0;
        background: #fff;
        z-index: 99;
        padding: 12px 0 12px 12px;
        box-sizing: border-box;

        li {
          text-align: center;
          display: inline-block;
          padding: 8px;
          border: 1px solid #ffffff;
          font-size: 12px;
          img {
            width: 72px;
            height: auto;
          }

          p {
            line-height: 17px;
          }

          &:hover {
            // box-sizing: border-box;
            color: $blue;
            border: 1px solid $blue;
          }
        }

        .check-it-pit {
          //border: 1px solid $blue;
          box-shadow: inset 0 0 3px 0 $blue;
        }
      }
    }
  }
  .search-list {
    //border: 1px solid #e8e8e8;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-select-dropdown__item{
      height: 40px;
      border-bottom: 1px solid #EBEDF0;
    }
    &>li {
      display: flex;
      align-items: center;
      border: 1px solid #e8e8e8;
      height: 38px;
      width: 246px;
      margin-right: 12px;
      position: relative;
      &:nth-child(3n){
        margin: 0;
      }
      //&:last-child {
      //  border: none;
      //}
      :deep .el-input__inner{
        text-align: center;
        border: none;
      }
      .select::-webkit-scrollbar {
        width: 4px;
      }

      .select::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
      }

      .select::-webkit-scrollbar-thumb {
        background-color: #D8D8D8;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
      }
      .sel-input{
        text-align: center;
        .el-input__inner{
          border: none;
          text-align: center;
        }
      }
      .img-container{
        width: 40px;
        height: 30px;
        img{
          max-width: 100%;
          max-height: 100%;
          vertical-align: middle;
          margin-right: 12px;
        }
      }

      .select{
        width: 190px;
        height: 280px;
        overflow-y: scroll;
        background: #fff;
        position: absolute;
        top: 41px;
        left: 54px;
        border: 1px solid #DCDEE0;
        li{
          height: 44px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #DCDEE0;
          padding: 0 8px;
          font-size: 14px;
          cursor: pointer;
        }
      }
      label {
        width: 70px;
        height: 38px;
        line-height: 38px;
        background: #f8f8f8;
        text-align: center;
        border-right: 1px solid #e8e8e8;
      }
      .sr-right1 {
        .sd-item {
          text-align: center;
          padding: 6px 8px;
          margin-right: 12px;
          p {
            padding: 0px 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:hover {
            color:$blue;
          }
        }
      }
      .sr-right {
        display: inline-flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        background: #fff;
        flex: 1;
        border-left: 1px solid #e8e8e8;
        padding: 6px 12px;
        position: relative;
        .sd-item {
          cursor: pointer;
          background: #fff;

          p {
            padding: 0px 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .img-item1 {
          height: 78px;
        }

        .img-item {
          width: 73px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
          cursor: pointer;
          margin-right: 8px;
          padding-bottom: 2px;
          &:hover {
            border: 1px solid $blue;
            color: $blue;
          }

          img {
            height: 42px;
            margin-bottom: 4px;
          }
        }

        .shape-item {
          width: 120px;
          text-align: center;
          line-height: 28px;
          cursor: pointer;
          margin-right: 12px;
          .shape-tit {
            position: relative;
            z-index: 100;
            width: 100%;
            height: 100%;
            background-color: #FFFFFF;
            border: 1px solid #DCDEE0;
          }

          &:hover {
            height: 31px;
            background: #fff;
            z-index: 9;
            color: $blue;

            .shape-tit {
              border-top-right-radius: 6px;
              border-top-left-radius: 6px;
              height: 35px;
              border: 1px solid $blue;
              border-bottom: none;
            }

            .shape-it-pit {
              display: flex;
              color: #333333;
            }
          }
        }

        &.has-selected {
          .has-shape{
            color: $blue;
          }
          .shape-item{
            height: 74px;
            line-height: inherit;
            &:hover{
              height: 74px;
              .shape-tit{
                height: 75px;
              }
              .shape-it-pit{
                top: 80px;
              }
            }
          }
        }

        .el-icon-arrow-down {
          margin-left: 4px;
        }

        .shape-tit {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          img {
            height: 40px;
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            max-width: 100px;
            //height: 100%;
          }
        }

        .shape-it-pit {
          width: 100%;
          display: none;
          flex-wrap: wrap;
          position: absolute;
          top: 40px;
          left: 0px;
          border: 1px solid $blue;
          background: #fff;
          z-index: 99;
          padding: 12px 0 12px 12px;
          box-sizing: border-box;

          li {
            text-align: center;
            display: inline-block;
            padding: 8px;
            border: 1px solid #ffffff;

            img {
              width: 95px;
              height: auto;
            }

            p {
              line-height: 17px;
            }

            &:hover {
              // box-sizing: border-box;
              color: $blue;
              border: 1px solid $blue;
            }
          }

          .check-it-pit {
            border: 1px solid $blue;
            box-shadow: inset 0 0 3px 0 $blue;
          }
        }
      }

      .active {
        color: $blue;
        border: 1px solid $blue;
      }
    }
  }

  .standCount {
    display: flex;
    flex-wrap: wrap;
    .std-item {
      text-align: center;
      width: 142px;
      height: 142px;
      background: #FFFFFF;
      border: 1px solid #DCDEE0;
      margin: 16px 12px 0 0;
      cursor: pointer;
      &:nth-child(5n){
        margin: 16px 0 0;
      }
      &:hover {
        .st-it-tl {
          color: $blue;
        }

        border-color: $blue;
      }

      img {
        height: 60px;
      }

      .st-it-tl {
        font-size: 14px;
        font-weight: 600;
      }

      .st-it-ex {
        font-size: 12px;
        margin: 4px 0;
      }

      .replace {
        padding: 0 8px;
        width: 100%;
        color: #999999;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        box-sizing: border-box;
        &:hover .highlight-text {
          color: $blue;
        }

        .replace-text {
          width: 80px;
          margin-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .have-replace {
        font-size: 12px;
        color: #7D8BA4;
        display: inline-flex;
        justify-content: space-between;
        width: 180px;

        &>p {
          width: 120px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;

          span {
            margin: 0 2px;
          }
        }

        &:hover {
          color: $blue;
        }
      }
    }
  }
  .el-pagination {
    text-align: center;
    padding: 20px 12px 0;
  }

  .blank {
    margin-top: 160px;
    text-align: center;

    .icon-zanwushuju {
      font-size: 60px;
    }

    p {
      font-size: 14px;
      color: #999;
      text-align: center;
    }
  }
  .button {
    width: 94px;
    height: 100%;
    color: #323233;
    font-size: 12px;
    background:#F2F3F5;
    border-left:1px solid #DCDEE0;
    text-align:center;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    i{
      transform: rotate(180deg);
      transition: all .2s linear;
    }
    .rotate{
      transform: rotate(0);
    }

  }

  .list-box{
    width: 802px;
    //height: 445px;
    background: #ffffff;
    position: absolute;
    top:46px;
    left: -560px;
    z-index: 9999;
    box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
    border-radius: 3px;
    //padding: 15px 0 0;
    overflow-y: auto;
  }
  :deep .list-box::-webkit-scrollbar {
    width: 8px;
  }

  :deep .list-box::-webkit-scrollbar-track {
    background-color: #FFFFFF;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  :deep .list-box::-webkit-scrollbar-thumb {
    background: rgba(50,50,51,0.4);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  .sanjiao {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ebedf0;
    position: absolute;
    top: -10px;
    left: 340px;
    z-index: 998;
  }
  .sanjiao2 {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #ffffff;
    position: absolute;
    top: -9px;
    left: 342px;
    z-index: 1001;
  }
}
</style>
