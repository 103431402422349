<template>
  <div id="y-standard-selector" v-clickoutside="clickOut">
    <div class="main-container">
      <div class="cont">
        <ul class="srh-list" ref="searchCount">

          <!-- 图片 名称 -->
          <li class="sh-lt-sd">
            <div>
              <img :src="standObj.standardInfo.imageUrl + '?x-oss-process=image/resize,m_fixed,w_120'">
              <span
                v-if="standObj.standardInfo.standardTypeID == 15 || standObj.standardInfo.standardTypeID == 25">{{ standObj.standardInfo.standardTowName }}</span>
              <span v-else>{{ standObj.standardInfo.standardName }}
                {{ standObj.standardInfo.categoryShortName }}</span>
            </div>
          </li>

          <!-- 直径选择 -->
          <li class="sh-lt-row">
            <label>直径</label>
            <div class="lt-row-rt" :class="foldList[0].fold ? 'no-space' : ''" ref="searchCount0">
              <span class="nomi-it" :class="finalParams.nominalId == item.nominalID ? 'active' : ''"
                v-for="(item, index) in nominalList" :key="index"
                @click.stop="checkNominal(item.nominalID)">{{ item.nominalName }}
              </span>
            </div>
            <div v-if="foldList[0].isShow" class="row-fold" @click="foldList[0].fold = !foldList[0].fold">
              {{ foldList[0].fold ? '展开' : '收起' }}
              <i :class="foldList[0].fold ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
            </div>
          </li>

          <!-- 长度选择 -->
          <li class="sh-lt-row" v-if="lengthList.length > 0">
            <label>长度</label>
            <div class="lt-row-rt" :class="foldList[1].fold ? 'no-space' : ''" ref="searchCount1">
              <span class="nomi-it" :class="finalParams.length == item.lengthValue ? 'active' : ''"
                v-for="(item, index) in lengthList" :key="index"
                @click.stop="checkLength(item.lengthValue)">{{ item.lengthName }}
                <!-- 长度添加推荐图标 用户上架的产品参数会显示荐 -->
                <span class="recommend-tit hidden" v-if="item.adFlag == 1">
                  荐
                </span>
              </span>
            </div>
            <div v-if="foldList[1].isShow" class="row-fold" @click="foldList[1].fold = !foldList[1].fold">
              {{ foldList[1].fold ? '展开' : '收起' }}
              <i :class="foldList[1].fold ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
            </div>
          </li>

          <!-- 牙型选择 -->
          <li class="sh-lt-row" v-if="toothtypeList.length > 0">
            <label>牙型</label>
            <div class="lt-row-rt" :class="foldList[2].fold ? 'no-space' : ''" ref="searchCount2">
              <span class="nomi-it" :class="finalParams.toothType == item.toothTypeID ? 'active' : ''"
                v-for="(item, index) in toothtypeList" :key="index"
                @click.stop="checktoothType(item)">{{ item.toothTypeName }}</span>
              <span class="nomi-it" :class="finalParams.threadRotation == 1 ? 'active' : ''" @click="checkRotation(1)">正牙</span>
              <span class="nomi-it" :class="finalParams.threadRotation == 2 ? 'active' : ''" @click="checkRotation(2)">反牙</span>
            </div>
            <div v-if="foldList[2].isShow" class="row-fold" @click="foldList[2].fold = !foldList[2].fold">
              {{ foldList[2].fold ? '展开' : '收起' }}
              <i :class="foldList[2].fold ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
            </div>
          </li>

        </ul>
      </div>

      <div class="bot-btns">
        <el-button class="btn cancel" @click="cancel">取消</el-button>
        <el-button class="btn primary" @click="confirm">引入规格</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  sqattrLength,
  sqattrNominal,
  sqattrToothtype,
  sqstandardInfo
} from "@/api_lm/lmPortals/utilityTools/standardview/fastenerGoods";

export default {
  name: 'yStandardSelector',
  props: {
    passId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      standObj: {
        standardInfo: {}
      }, // 标准数据
      search: { // 检索条件
        start: 1,
        length: 10,
        keyword: '',
        standard: 0, // 标准id
        texture: '', // 材质id
        strength: '', // 强度
        material: '', // 材料
        surfaceTreatment: '', // 表面处理
        threadRotation: '', // 正反牙 1.正牙 2.反牙
        strLengths: '', // 长度
        nominal: '', // 直径
        toothTypeId: '', // 牙型
        toothType2Id: 0
      },
      nominalList: [], // 直径数据
      textureList: [], // 材质数据
      strengthList: [], // 强度数据
      materialList: [], // 材料数据
      surfaceArr: [], // 表面处理数据
      surfaceList: [], // 表面处理列表
      surfaceSave: [], // 表面处理全部数据存储
      materialSave: [], // 材料全部数据存储
      lengthList: [], // 长度数据
      toothtypeList: [], // 牙型数据
      checkStrLengths: [], // 选中长度数据
      foldList: [{
        isShow: false,
        fold: false
      }, {
        isShow: false,
        fold: false
      }, {
        isShow: false,
        fold: false
      }, {
        isShow: false,
        fold: false
      }, {
        isShow: false,
        fold: false
      }, {
        isShow: false,
        fold: false
      }, {
        isShow: true,
        fold: false
      }],

      finalParams: {
        standardId: '',
        standardName: '',
        nominalId: '',
        length: '',
        threadRotation: '',
        toothType: '',
        toothType2: '',
      },


      maxTableHeight: null,
      substitute: false,
      replaceData: [],
      constituteList: [],
      disintegrate: true,
      surfaceValue: '', // 表色检索
      materialValue: '', //材料检索
      checkSurObj: {}, // 表色选中数据
      bigSurfaceId: 0, // 表色大类id
      composeIndex: -1, // 组合件选中索引
      throttle: null,
      machinePropertiesParamsObj: {},
      machineBigId: null,
      supplierCategoryTopID: null,
      supplierCategorySecondID: null,
      isShowSupplier: false,
      supplierListParams: {},
      isBatch: false,
      rowData: {},
      headTabIndex: 0,
      unitVisible: false, // 加入尾货选择单位弹窗
      unitList: [], // 单位
      baseUnit: '',
      addSuccessDialog: false,

    };
  },
  created() {
    sqstandardInfo({
      standardId: this.passId
    }).then(res => {
      if (res.status == 200) {
        this.standObj = res.data;
        this.finalParams.standardId = res.data.standardInfo.standardID
        this.finalParams.standardName = res.data.standardInfo.standardName

        this.into();
      } else {
        this.$message.error('请求错误，请重试！');
      }
    });
  },
  methods: {
    clickOut(){
      console.log('eererererer');
      this.$emit('close')
    },
    into() {
      let nominalParam = {
        standard: this.standObj.standardInfo.standardID,
        textureId: this.standObj.textureID,
        strength: this.standObj.strengthID,
        material: this.standObj.materialID,
        surfaceTreatment: this.standObj.surfaceTreatmentID
      }
      sqattrNominal(nominalParam)
      .then(res => { // 直径请求
        if (res.status == 200) {
          this.nominalList = res.data;
          this.finalParams.nominalId = this.nominalList[0] ? this.nominalList[0].nominalID : ''
          setTimeout(() => {
            if (this.$refs.searchCount0.offsetHeight > 40) {
              this.foldList[0].isShow = true;
              this.foldList[0].fold = true;
            } else {
              this.foldList[0].isShow = false;
            }
          }, 0)
          this.checkNominal(this.finalParams.nominalId)
        } else {
          this.$message.error('请求错误，请重试！');
        }
      })
    },

    checkNominal(id) { // 直径选择函数
      this.finalParams.nominalId = id

      let lengthParam = {
        standard: this.standObj.standardInfo.standardID,
        nominal: id,
        textureId: this.standObj.textureID,
        strength: this.standObj.strengthID,
        material: this.standObj.materialID,
        surfaceTreatment: this.standObj.surfaceTreatmentID
      }
      sqattrLength(lengthParam).then(res => { // 长度请求处理
        if (res.status == 200) {
          this.lengthList = res.data;
          this.finalParams.length = this.lengthList[0] ? this.lengthList[0].lengthValue : ''
          this.foldList[1].isShow = false;
          this.foldList[1].fold = false;
          setTimeout(() => {
            if (this.$refs.searchCount1.offsetHeight > 40) {
              this.foldList[1].isShow = true;
              this.foldList[1].fold = true;
            }
          }, 0)
        } else {
          this.$message.error('请求错误，请重试！');
        }
      })
      sqattrToothtype({
        standard: this.standObj.standardInfo.standardID,
        nominal: id
      }).then(res => { // 牙型请求处理
        if (res.status == 200) {
          this.toothtypeList = res.data;
          this.finalParams.toothType = this.toothtypeList[0] ? this.toothtypeList[0].toothTypeID : ''
          this.finalParams.toothType2 = this.toothtypeList[0] ? this.toothtypeList[0].toothTypeID2 : ''
          setTimeout(() => {
            if (this.$refs.searchCount2.offsetHeight > 40) {
              this.foldList[2].isShow = true;
              this.foldList[2].fold = true;
            } else {
              this.foldList[2].isShow = false;
            }
          }, 0)
        } else {
          this.$message.error('请求错误，请重试！');
        }
      })
    },
    checkLength(val) { // 长度选择函数
      // console.log('length', val);
      this.finalParams.length = val
    },
    checktoothType(val) { // 牙型选择
      // console.log('tooth', val);
      this.finalParams.toothType = val.toothTypeID
      this.finalParams.toothType2 = val.toothTypeID2
    },
    checkRotation(val) { // 牙型正反选择
      // console.log('toothRotation', val);
      this.finalParams.threadRotation = val
    },
    // closeNominal() { // 直径取消选择
    //   this.search.nominal = '';
    //   this.toothtypeList = [];
    //   this.lengthList = [];
    //   this.checkStrLengths = [];
    //   this.getTextureStrength();
    //   this.search.start = 1;
    //   this.getGoods();
    // },
    // closeLength(index) { // 长度取消函数
    //   this.checkStrLengths.splice(index, 1);
    //   this.getTextureStrength();
    //   this.search.start = 1;
    //   this.getGoods();
    // },
    // closeToothType() {
    //   this.search.toothTypeId = '';
    //   this.search.toothType2Id = 0;
    //   this.getTextureStrength();
    //   this.search.start = 1;
    //   this.getGoods();
    // },
    // closeRotation() { // 取消反牙选择
    //   this.search.threadRotation = '';
    //   this.getTextureStrength();
    //   this.search.start = 1;
    //   this.getGoods();
    // },
    // cancel_() {
    //   // 全部取消函数
    //   this.checkStrLengths = [];
    //   this.search.nominal = '';
    //   this.search.threadRotation = '';
    //   this.search.toothTypeId = '';
    //   this.search.toothType2Id = 0;
    //   this.lengthList = [];
    //   this.toothtypeList = [];
    //   this.search.start = 1;
    //   this.getTextureStrength();
    //   this.getGoods();
    // },

    cancel(){
      this.$emit('close')
    },
    confirm(){
      if(!this.lengthList.length)this.finalParams.length = 0
      if(!this.toothtypeList.length){
        this.finalParams.threadRotation = 0
        this.finalParams.toothType = 0
        this.finalParams.toothType2 = 0
      }
      const params = {...this.finalParams}
      if(this.finalParams.nominalId === '')return this.$message.error('请选择直径！')
      if(this.finalParams.length === '')return this.$message.error('请选择长度！')
      if(this.finalParams.toothType === '')return this.$message.error('请选择牙型！')
      if(this.finalParams.threadRotation === ''){
        params.threadRotation = 1
      }
      this.$emit('close', params)
    }
  }
};
</script>
<style lang="scss" scoped>
#y-standard-selector {
  position: absolute;
  top: 50px;
  left: 50%;
  z-index: 1000;
  height: auto;
  width: 792px;
  transform: translateX(-50%);
  box-shadow: 0px 2px 8px 0px rgba(200,201,204,0.5);

  box-sizing: border-box;
  //padding: 0 24px;
  overflow: auto;

  .main-container {
    //padding: 0 12px;
    background: #f2f3f5;

    .cont {
      background: #fff;
      padding: 12px;
    }

    .bot-btns{
      display: flex;
      justify-content: flex-end;
      padding: 8px 24px;
      background: #fff;
      .btn{
        box-sizing: border-box;
        padding: 6px 16px;
        font-size: 14px;
        & + .btn{
          margin-left: 12px;
        }
        &.cancel{
          color: #333
        }
        &.primary{
          background-color: #155BD4FF;
          border-color: #155BD4FF;
          color: #fff
        }
      }
    }
  }

  .green {
    color: #155BD4;
  }

  .standNav {
    display: flex;
    align-items: center;
    height: 52px;
    font-size: 12px;

    .el-icon-arrow-right {
      margin: 0 3px;
    }

    .rou-tl {
      font-size: 14px;
      color: #155BD4;
    }

    .tag {
      margin: 0 12px;
      min-width: 400px;
      overflow: auto;
      white-space: nowrap;

    }

    .el-tag {
      cursor: pointer;
    }

    .el-tag--small {
      border-color: #cfd4db;
      color: #333333;
      margin-right: 10px;
      background: #ffffff;
    }

    .el-tag__close {
      color: #000000;

      &:hover {
        background: transparent;
      }
    }

    .nav-rt {
      margin-left: auto;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .btnShow {
        font-size: 12px;
        cursor: pointer;
        margin-right: 8px;
        padding: 0 12px;
        height: 28px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DCDEE0;
        text-align: center;
        display: flex;
        align-items: center;
        color: #323233;

        .iconfont {
          color: #969799;
          font-size: 10px;
          margin-left: 6px;
        }

        &.expand {
          color: $blue;
          border: 1px solid $blue;

          .iconfont {
            color: $blue;
          }
        }
      }

      span {
        color: #155BD4;
      }

      .line {
        width: 1px;
        height: 12px;
        background: #D4D9E1;
        margin: 0 8px;
      }

      .des {
        margin-right: 16px;

        .iconfont {
          color: #646566;
          font-size: 16px;
          margin-right: 2px;
        }

        span {
          font-size: 14px;
          color: #646566;
        }
      }
    }

    .rou-hov:hover {
      cursor: pointer;
      color: #155BD4;
    }
  }

  .return {
    width: 114px;
    height: 32px;
    margin-bottom: 12px;
    line-height: 30px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #DCDEE0;
    text-align: center;
    font-size: 14px;
    color: #323233;
    cursor: pointer;

    .iconfont {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .product-type-box {
    box-sizing: border-box;
    color: #333333;
    font-size: 14px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .type-item {
      margin-right: 30px;
      padding-bottom: 8px;
      cursor: pointer;
    }

    .active-type {
      color: #155BD4;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        background: #155BD4;
      }
    }
  }

  .srh-list {
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    margin-bottom: 20px;

    .sh-lt-sd {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        height: 36PX;
        margin: 0 8px;
        vertical-align: middle;
      }

      span {
        font-size: 12px;
        font-weight: 600;
        margin-left: 4px;
      }

      a {
        color: #155BD4;
        font-size: 12px;
        margin-right: 10px;
        display: inline-block;
        height: 36px;
        line-height: 36px;
      }
    }

    .sh-lt-row {
      display: flex;
      border-top: 1px solid #e8e8e8;
      font-size: 12px;

      label {
        width: 64px;
        background: #F8F8F8;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      .lt-row-rt {
        min-height: 36px;
        flex: 1;
        display: inline-flex;
        border-left: 1px solid #e8e8e8;
        line-height: 22px;
        flex-wrap: wrap;
        /*padding: 6px 0 6px 16px;*/
        padding: 0 0 0 16px;
      }

      .lt-row-sur {
        flex: 1;
        border-left: 1px solid #e8e8e8;
        padding: 6px 0 6px 16px;

        .el-input--mini .el-input__inner {
          height: 24px;
          line-height: 24px;
        }

        .el-input--mini .el-input__icon {
          line-height: 24px;
        }

        .el-input {
          width: 140px;
        }

        .surfaceBtn {
          position: relative;
          display: inline-block;
          border: 1px solid #E8E8E8;
          border-radius: 4px;
          padding: 3px 10px;
          cursor: pointer;
          margin-left: 10px;
          margin-bottom: 3px;

          &:hover {
            color: $blue;
          }

          .recommend-tit {
            position: absolute;
            height: 18px;
            width: 18px;
            margin-top: -10px;
            z-index: 12;
            margin-left: 1px;
          }

          .hidden {
            display: none;
          }
        }

        .surfaceActive {
          border-color: $blue;
          color: #fff;
          background: $blue;

          &:hover {
            color: #fff;
          }
        }

        .row-sur-ct {
          display: inline-flex;
          line-height: 20px;
          flex-wrap: wrap;
          padding: 4px 0;
        }
      }

      .no-space {
        height: 36px;
        overflow: hidden;
        white-space: nowrap;
      }

      .no-special {
        height: 40px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .row-fold {
      background: #fff;
      color: #155BD4;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 40px;
      margin-right: 14px;
      cursor: pointer;
    }

    .nomi-it {
      /*margin-right: 16px;*/
      /*margin-bottom: 0px;*/
      margin: 6px 16px 6px 0;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      border-radius: 2px;
      padding: 0 8px;

      &:hover {
        color: $blue;
      }

      .hidden {
        display: none !important;
      }

      .recommend-tit {
        position: absolute;
        top: -4px;
        right: -15px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 2px;
        background: #155BD4;
        font-size: 12px;
        transform: scale(.8);
      }
    }

    .active {
      color: #155BD4;
      border-color: #155BD4;
      //background: rgba(61, 110, 61, 0.06);
    }
  }

  .good-foot {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    font-size: 12px;

    .el-checkbox__label {
      font-size: 12px;
    }

    .check-num {
      margin-left: 24px;

      span {
        color: #155BD4;
      }
    }

    .batch {
      margin: 0 16px;
    }

    .add-publish {
      display: inline-flex;
      cursor: pointer;
      padding: 0 12px;
      height: 28px;
      color: #fff;
      border-radius: 4px;
      background-color: #155BD4;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      margin-left: 12px;
    }

    .batch-add {
      display: inline-flex;
      cursor: pointer;
      height: 32px;
      border: 1px solid #155BD4;
      background-color: #155BD4;
      border-radius: 2px;
      color: #FFFFFF;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      margin-left: 12px;
      padding: 0px 16px;
    }

    .batch-customize {
      display: inline-flex;
      cursor: pointer;
      height: 32px;
      border: 1px solid #155BD4;
      border-radius: 2px;
      color: #155BD4;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      margin-left: 12px;
      padding: 0px 16px;
    }
  }

  .dialog-footer {
    position: absolute;
    bottom: 25px;
    right: 20px;

    .dialog-footer-btn {
      line-height: 16px;
      width: 56px;
      text-align: center;
      border-radius: 4px;
      font-size: 12px;
      box-sizing: border-box;
      color: #333333;
      background: #FFFFFF;
      border: 1px solid #D4D9E1;
      padding: 6px 12px;
      cursor: pointer;
    }

    .confirm {
      background: #155BD4;
      color: #FFFFFF;
      border: none;
    }
  }

  .el-dialog__body {
    background: #fff;
  }

  .el-form.el-form--label-left {
    margin: 20px;
  }

  .pictureCount {
    box-sizing: border-box;
    padding: 19px 20px 16px;
    background: #fff;

    .container {
      width: 100%;
      min-height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .replaceTable {
    margin: 0 16px;
    padding: 16px 0 40px;
  }

  .customize-btn {
    margin-left: 12px;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      height: 12px;
      width: 1px;
      background-color: #DCDEE0;
      position: absolute;
      left: -7px;
      top: 3px;
    }
  }

  .unit {
    font-size: 14px;
    padding: 24px 16px 16px 24px;
    font-weight: 400;

    p {
      color: #323233;

      span {
        color: #969799;
      }
    }

    .el-radio-group {
      margin: 12px 0 24px 0;

      .el-radio__label {
        font-weight: 400;
      }
    }

    .unit-footer {
      text-align: right;

      .el-button {
        font-size: 14px;
        border-radius: 2px;
      }

      .el-button+.el-button {
        margin-left: 12px;
      }
    }
  }
}
</style>
