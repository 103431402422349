export const filterParams = (params) => { // 过滤空参数
	Object.keys(params).forEach((item) => {
		if (params[item] === null) {
			delete params[item];
		} else if (params[item].length === 0) {
			delete params[item];
		}
	});
	return params;
};

export function filteNumber(str) { // 金钱千分位
	str = String(str);
	if (str.indexOf('.') != -1) {
		// str.splice()
		let leftStr = str.split('.')[0];
		let rightStr = str.split('.')[1];
		let newStr = strHandle(leftStr) + '.' + rightStr;
		if (newStr == "undefined" || newStr == 'null' || !newStr) {
			return '';
		} else {
			return newStr;
		}
	} else {
		if (strHandle(str) == "undefined" || strHandle(str) == 'null' || !strHandle(str)) {
			return '';
		} else {
			return strHandle(str);
		}
	}
}

function strHandle(str) {
	if (/\./.test(str)) {
		return str.replace(/\d(?=(\d{3})+\.)/g, "$&,").replace(/\d{3}(?![,.]|$)/g, "$&,");
	} else {
		return str.replace(/\d(?=(\d{3})+$)/g, "$&,");
	}
}

export const filterPackage = (params) => { // 拆包规
	let val = `${params.cartBigPackageQty?(params.cartBigPackageQty+params.bigPackageUnitName):''}
	${params.cartSmallPackageQty?(params.cartSmallPackageQty+params.smallPackageUnitName):''}
	${params.cartNonPackQty?(params.cartNonPackQty+params.baseUnitName):''}`;
	return val;
};
//解决运算精度丢失问题
// 加
export function accAdd(arg1, arg2) {
  let r1, r2, m;
  try {r1 = arg1.toString().split(".")[1].length;} catch (e) {r1 = 0;}
  try {r2 = arg2.toString().split(".")[1].length;} catch (e) {r2 = 0;}
  m = Math.pow(10, Math.max(r1, r2));
  return (accMul(arg1 , m) + accMul(arg2 , m)) / m;
}
// 减
export function Subtr(arg1, arg2) {
  let r1, r2, m, n;
  try {r1 = arg1.toString().split(".")[1].length;} catch (e) {r1 = 0;}
  try {r2 = arg2.toString().split(".")[1].length;} catch (e) {r2 = 0;}
  m = Math.pow(10, Math.max(r1, r2));
  // 动态控制精度长度
  n = (r1 >= r2) ? r1 : r2;
  return ((accMul(arg1 , m) - accMul(arg2 , m)) / m).toFixed(n);
}

// 乘
export function accMul(arg1, arg2) {
  if(arg1 == null || arg2 == null){return null;}
  let n1,n2, r1, r2; // 小数位数
  try {r1 = arg1.toString().split(".")[1].length;} catch (e) {r1 = 0;}
  try {r2 = arg2.toString().split(".")[1].length;} catch (e) {r2 = 0;}
  n1 = Number(arg1.toString().replace(".", ""));
  n2 = Number(arg2.toString().replace(".", ""));
  return n1 * n2 / Math.pow(10, r1+r2);
}

//除法
export function accDiv(arg1,arg2){
  let t1=0,t2=0,r1,r2;
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}
  // with(Math){
  r1=Number(arg1.toString().replace(".",""))
  r2=Number(arg2.toString().replace(".",""))
  return (r1/r2)*Math.pow(10,t2-t1);
  // }
}


