<template>
  <div class="y-select">
    <el-input clearable :style="styleObj" class="input" placeholder="请输入标准号或名称搜图纸" prefix-icon="el-icon-search"
              @input="handleInputChange" v-model="inputValue" @clear="inputValue = ''" @focus="handleFocus"></el-input>
    <ul class="list" v-if="listShow">
      <li :class="[checkedIndex == index ? 'active' : '', 'font-hidden']" v-for="(item, index) in listData" :key="index"
          @click.stop="handleCheckedItem(item, index)">
        <span v-if="item.valueName.includes(inputValue)">
          <span v-if="inputValue">{{ item.valueName.split(inputValue)[0] }}</span>
          <span class="active">{{ inputValue }}</span>
          <span v-if="inputValue">{{ item.valueName.split(inputValue)[1] }}</span>
        </span>
        <span v-else-if="item.valueName.toLowerCase().includes(inputValue)">
          <span v-if="inputValue">{{ item.valueName.toLowerCase().split(inputValue)[0].toUpperCase() }}</span>
          <span class="active">{{ inputValue.toUpperCase() }}</span>
          <span v-if="inputValue">{{ item.valueName.toLowerCase().split(inputValue)[1].toUpperCase() }}</span>
        </span>
        <span v-else>{{ item.valueName }}</span>
      </li>

      <div class="loading" v-if="loading">正在搜索...</div>
      <div class="noResult" v-if="noResult">没有搜索结果...</div>
    </ul>
  </div>
</template>

<script>
// import { getSqstandards } from "@/api/standardview/fastener.js";
import {getSqstandards} from "@/api_lm/lmPortals/utilityTools/standardview/fastener";

export default {
  name: "ySelect",
  props: {
    styleObj: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      inputValue: "",
      listShow: false,
      listData: [],
      checkedIndex: null,
      loading: true,
      noResult: false
    };
  },
  methods: {
    handleFocus() {
      if (this.inputValue) {
        this.listShow = true;
      }
    },
    close() {
      this.listShow = false;
    },
    handleInputChange(e) {
      if (this.inputValue) {
        this.listShow = true;
      } else {
        this.listShow = false;
      }
      this.getSimpleStandardPage();
    },
    async getSimpleStandardPage() {
      this.loading = true;
      let { data } = await getSqstandards({
        languageCode: "cn",
        keyword: this.inputValue,
        standardTypeId: 0,
        bigCategoryId: 0,
        smallCategoryId: 0,
        isGb: 1
      });
      data.data.map(val => {
        val.valueName = val.standardName + " " + val.productName;
        if (val.standardTypeId == 15 || val.standardType == 25) {
          val.valueName = val.standardName;
        }
      });
      this.listData = data.data;
      this.loading = false;
      if (this.listData.length == 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
    },

    handleCheckedItem(item, index) {
      this.checkedIndex = index;
      this.inputValue = item.valueName;
      this.listShow = false;
      this.$emit("handleCheckIndex", item.standardId);
    }
  }
};
</script>

<style lang="scss" scoped>
.y-select {
  position: relative;
  font-size: 14px !important;

  span {
    font-size: 14px !important;
  }

  .input {
    width: 220px !important;
    outline: none;
    border-color: #dcdee0;

    input {
      border-radius: 0 !important;
    }
  }

  .list {
    width: 520px;
    max-height: 240px;
    overflow: auto;
    min-height: 50px;
    position: absolute;
    top: 40px;
    left: -1px;
    z-index: 1000;
    background: #ffffff;
    border: 1px solid #ebedf0;
    box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
    border-radius: 2px;
    padding: 15px 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-sizing: border-box;
    transition: all 0.2s;
    font-size: 14px;

    .loading {
      color: $blue;
      text-align: center;
      font-size: 14px;
      margin-top: 15px;
    }

    .noResult {
      color: #969799;
      text-align: center;
      font-size: 14px;
      margin-top: 15px;
    }

    li {
      width: calc(100% - 20px);
      height: 32px;
      color: #323233;
      font-size: 14px;
      padding: 0 10px;
      line-height: 32px;
      cursor: pointer;

      .active {
        color: #ed6a0c;
      }
    }

    li.active {
      color: #ed6a0c;
    }

    li:hover {
      background: #f7f8fa;
    }
  }
}
</style>


<style lang="scss">
.y-select {
  .el-input__inner {
    height: 100%;
  }
  .el-input__icon {
    font-size: 24px !important;
  }
  .center-box{
    .button{
      background: #0052D9 !important;
      color: #fff !important;
    }
  }
}



</style>
