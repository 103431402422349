import Vue from 'vue';
import Vuex from 'vuex';
import union from './modules/union'; // 引入 union 模块
import lmPortals from './modules/lmPortals'; // 引入 lmPortals 模块

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    union, // 注册 内联盟模块
    lmPortals // 注册 外联盟模块
  }
});
