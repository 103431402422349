<template>
  <div>
    <el-dialog title="申请会员" :visible.sync="dialogVisible" :show-close="true" width="520px" :modal="false">

      <div class="content">
        <el-timeline>
          <el-timeline-item :color="(applyStatus>=activity.status)?'#0052D9':''" :class="`${activity.alreadyStatus?'already':''}`" v-for="(activity, index) in activities" :key="index">
            <div :class="`timeline-item-box ${activity.selected?'active':''}`">
              <div class="timeline-title" >{{activity.title}}</div>
              <div class="`${activity.alreadyStatus?'':'timeline-content'}`" v-html="activity.contrnt"></div>
            </div>     
          </el-timeline-item>
        </el-timeline>
        <div v-if="applyStatus==1 && $route.meta?.module !== 'lmShop'" style="color: #0052D9;cursor: pointer;" @click="applyMemberDialog">太麻烦？快速向多个卖家发起会员申请>></div>
        <div class="contact-List">
          <div v-for="(item,index) in contactList" :key="index" class="contact-item">
            <div class="contact-name">
              <span class="iconfont icon-lianxiren"></span>
              <div>联系人</div>
              <div>{{item.contactName}}</div>
            </div>
            <div class="contact-phone">
              <span class="iconfont icon-shouji"></span>
              <div>联系电话</div>
              <div>{{item.mobilePhoneNum}}</div>
            </div>
            <div class=" contact-address">
              <span class="iconfont icon-dizhi"></span>
              <div>地址</div>
              <div>{{address}}</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="applyStatus==1">
          <el-button @click="close" >取消</el-button>
          <el-button type="primary" @click="confirm">确定申请</el-button>
          </div>
        </span>
    </el-dialog>
    <apply-member v-if="applyModal" ref="apply" @closeModal="applyModal=false"></apply-member>
  </div>
</template>

<script>
import {getTenantInfo} from "@/api_lm/lmPortals/lmShop";
import {confirmLmTenantsApply, lmSupplierList} from "@/api_lm/lmPortals/home";
import applyMember from "@/views/lmPortals/components/applyMember";

export default {
  components:{
    applyMember,
  },
  data() {
    return {
      dialogVisible: false,
      applyModal: false,
      activities: [{
        title: '会员申请',
        contrnt: '',
        status:"1",
        alreadyStatus:false,
      }, {
        title: '会员审核',
        contrnt: '',
        status:"2",
        alreadyStatus:false,
      }],
      applyStatus:"",
      contactList:[{
        contactName:'',
        mobilePhoneNum:''
      },{
        contactName:'',
        mobilePhoneNum:''
      }],
      tenantId:0,
      erpTenantId:0,
      companyName:"",
      address:"",
    }
  },
  methods: {
    init(){
      try{
        lmSupplierList({
          selectId: this.erpTenantId,
        }).then(res=> {
          if (res.data.records.length>0) {
            //重新获取
            this.applyStatus = res.data.records[0].status>2?2:res.data.records[0].status;
            if (this.applyStatus!=1)
              this.getTenantInfo();
            this.setProgress();
          }
        });
      }catch (e){
        console.log("出错了，取前端传过来的参数")
        if (this.applyStatus!=1){
          this.applyStatus = 2;
          this.getTenantInfo();
        }
        this.setProgress();
      }
    },
    // 供应商信息
    async getTenantInfo(){
      let {data} = await getTenantInfo({
        supplierId:this.tenantId
      })
      this.contactList =  data.contacts
      this.address = data.areaName + " " +  data.address;
    },
    close() {
      this.dialogVisible = false
    },
    confirm() {
      confirmLmTenantsApply({
        lookYouPohone: true,
        erpTenantIds: [this.erpTenantId],
      }).then(res=>{
        if(res.data.success){
          if(res.data.data.failureNum>0) {
            this.$message.warning('会员申请失败，尝试重新提交。')
          }else{
            this.$message.success('已经成功提交会员申请！')
          }
          setTimeout(() => {
            this.$emit('success')
          }, 1000);
        }else{
          this.$message.error(res.data.errorMes)
        }
        this.dialogVisible = false
      })
    },
    setProgress() {
      if(this.applyStatus == "1") {
        this.activities[0].contrnt = `您正在向卖家 <span style="color:#D93845;font-weight: bold;">${this.companyName}</span> 发起会员申请`
        this.activities[1].contrnt = `${this.companyName} 审核后可见会员专属价`
        this.activities[0].selected = true;
        this.activities[1].selected = false;
        this.activities[0].alreadyStatus = false;//是否显示已经处理 样式。 ==true则标题变灰 ， ==false则标题点亮
        this.activities[1].alreadyStatus = false;
      }else if(this.applyStatus == "2") {
        this.activities[0].contrnt = `您已经向卖家 ${this.companyName} 发起会员申请`
        this.activities[1].contrnt = `<div><span style="color:#D93845;font-weight: bold;">${this.companyName}</span> 审核后可见<span style="color:#E37318;font-weight: bold;">会员专属价</span>。联系卖家：</div> `
        this.activities[0].selected = false;
        this.activities[1].selected = true;
        this.activities[0].alreadyStatus = true;
        this.activities[1].alreadyStatus = false;
      }
    },
    open(obj) {
      let {applyStatus,contactList,companyName,tenantId,erpTenantId} = obj
      this.applyStatus = applyStatus
      this.contactList = contactList
      this.companyName = companyName
      this.tenantId = tenantId;
      this.erpTenantId = erpTenantId;
      this.dialogVisible = true
      this.init();
    },
    applyMemberDialog(){
      this.close();
      this.applyModal = true;
    },
  }

}
</script>

<style scoped>
.active{
  background: #F0F5FD;
}
.already .timeline-item-box{
  color: #999999!important;
}
::v-deep .already .el-timeline-item__tail{
  border-left:2px solid #0052D9;
}
::v-deep .el-timeline-item__tail{
  left: 5px;
}
.content{
  max-height: 400px;
  overflow: auto;
  padding: 0px 24px;
}
.timeline-item-box{
  height: 64px;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
::v-deep .el-timeline{
  margin-top: 14px;
}
::v-deep .el-timeline >.el-timeline-item:last-child{
  padding-bottom: 0px;
}
::v-deep .el-timeline-item{
  padding-bottom: 10px;
}
::v-deep .el-timeline-item__node{
  left: 0px;
}
::v-deep .el-timeline-item__wrapper{
  padding-left: 25px;
  top: -14px;
}
::v-deep .el-dialog{
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.1), 0px 8px 10px 1px rgba(0,0,0,0.06), 0px 3px 14px 2px rgba(0,0,0,0.05);
}
::v-deep .el-dialog__header {
  padding: 16px;
}
::v-deep .el-dialog__title {
  font-size: 14px;
  font-weight: bold;
}
::v-deep .el-dialog__body {
  padding: 0px 16px 0px 16px;
}
::v-deep .el-dialog__footer{
  padding: 16px;
}
.timeline-title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom:4px ;

}
.timeline-content {
  font-size: 14px;
  color: #999999;
}
.contact-List{
  background: #F5F5F5;
  border-radius: 12px 12px 12px 12px;
  padding: 0px 16px;
  font-size: 14px;
  color: #333333;

}
.contact-item{
  border-bottom: 1px solid #DCDCDC;
  padding: 10px 0px;
}
.contact-List>:last-child{
  border-bottom: none!important;;
}
.contact-item> div{
    display: flex;
    line-height: 32px;
}
.contact-item > div >span:nth-of-type(1){
  font-size: 13px;
  color: #B0B0B0;
}
.contact-item > div >div:nth-of-type(1){
  min-width: 72px;
  margin-left: 5px;
}

</style>