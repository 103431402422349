<template>
	<div class="hgpMachine" v-if="Object.keys(installApplication).length && machineDialogVisible">
		<el-dialog v-if="!notDialog"  v-dialogDrag title="性能成分信息" custom-class="machine-dialog scrollBox" :visible.sync="machineDialogVisible" :before-close="close" width="968px"
		:close-on-click-modal="true" :close-on-press-escape="true">
			<div class="machine-content" v-if="Object.keys(installApplication).length && isShowMachineProperties">
				<p class="install-application">安装应用（拧紧力矩计算器）</p>
				<div class="module-top">
					<div class="left-box">
						<p class="reduce-line" style="margin: 16px 0 8px 0;">
							<label>P---预紧力系数</label>
							<el-select class="select-box" v-model="installAppObj.preload" placeholder="">
								<el-option
								v-for="(item, index) in installApplication.preloadList"
								:key="index"
								:label="item"
								:value="item">
								</el-option>
							</el-select>
							<!-- <span>-</span>
							<el-select class="select-box" v-model="installAppObj.maxRub" placeholder="">
								<el-option
								v-for="(item, index) in installApplication.maxRubList"
								:key="index"
								:label="item"
								:value="item">
								</el-option>
							</el-select> -->
						</p>
						<p class="reduce-line">
							<label>K---扭矩系数</label>
							<el-select class="select-box" v-model="installAppObj.minTorque" placeholder="">
								<el-option
								v-for="(item, index) in installApplication.minTorqueList"
								:key="index"
								:label="item"
								:value="item">
								</el-option>
							</el-select>
							<span>-</span>
							<el-select class="select-box" v-model="installAppObj.maxTorque" placeholder="">
								<el-option
								v-for="(item, index) in installApplication.maxTorqueList"
								:key="index"
								:label="item"
								:value="item">
								</el-option>
							</el-select>
						</p>
						<p class="reduce-line" style="margin-top: 16px; padding-top: 16px; border-top: 1px solid #686868;">
							<label>T---拧紧力矩N.m</label>
							<span class="reduce-value">{{calculateResultValue}}</span>
						</p>
            <p v-if="yieldPoint == 0" class="reduce-line" style="padding: 10px 0;font-size: 12px">（当产品无等级要求时，不计算扭矩）</p>
          </div>
					<div class="right-box">
						<p class="count-equation"><i class="iconfont icon-2" style="margin-right: 12px;"></i>扭矩计算公式 T=K*P*D</p>
						<p class="count-desc">其中：T--力矩(N.m)P--预紧力(KN)D---螺栓的公称直径(MM)</p>
						<p class="rb-p3">根据下表：</p>
						<p class="rb-p4">有润滑的表面氧化面，则扭矩系数为K=0.20</p>
						<p class="rb-p4">预紧力一般取：P=（0.6-0.7）*屈服点(N/mm2)*应力截面积(mm2)</p>
						<p class="rb-p4">屈服点和应力截面积参照GB3098.1  0.6-0.7根据VDI-2230推荐所取。</p>
					</div>
				</div>
				<div class="module-chart-box">
					<div class="chemical-component" v-if="chemicalComponentList.length">
						<p class="chemical-tit">化学成分</p>
						<div ref="echartsDom" style="width: 372px; height:329px; margin-top: 40px;"></div>
					</div>
					<div class="properties-box">
						<div class="pro-par1" v-if="Object.keys(threadStressArea).length">
							<span class="par1-tit">螺纹应力截面积</span>
							<div>
								<p class="par1-img">
									<img src="../../assets/images/gb/machine/threadArea.png" alt="">
								</p>
								<p class="par1-val">{{threadStressArea.stressArea.toFixed(2)}}mm²</p>
							</div>
						</div>
						<div class="pro-par2" v-if="mechanicalPropertiesList.length" :style="{minHeight: Object.keys(threadStressArea).length ? '239px' : '420px'}">
							<span class="par2-tit">机械性能</span>
							<div style="display: flex; align-items: center;">
								<img class="par2-img" src="../../assets/images/gb/machine/properties.png" alt="">
								<ul class="properti-box">
									<li v-for="(item, index) in mechanicalPropertiesList" :key="index">
										<span>{{item.propertyName}}</span>:
										<span style="margin-left:5px;">{{item.propertyValue}}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<p class="tip-high-text" v-if="applicationDescHtml">*应努力达到摩擦系数等级中列出的摩擦系数，从而获得尽可能高的预紧力，同时数值分散也较小，这也就是不自动的使用最小的值，同时目前的摩擦系数数值分散与等级的数值分散相符，该表格在室温时有效</p>
				<div class="temp-html" v-html="applicationDescHtml"></div>
			</div>
			<div class="machine-content" v-else>
				<div class="module-chart-box">
					<div class="chemical-component" v-if="chemicalComponentList.length">
						<p class="chemical-tit">化学成分</p>
						<div ref="echartsDom" style="width: 372px; height:329px; margin-top: 40px;"></div>
					</div>
					<div class="properties-box">
						<div class="pro-par2" v-if="mechanicalPropertiesList.length" :style="{minHeight: Object.keys(threadStressArea).length ? '239px' : '420px'}">
							<span class="par2-tit">机械性能</span>
							<div style="display: flex; align-items: center;">
								<img class="par2-img" src="@/assets/images/gb/machine/properties.png" alt="">
								<ul class="properti-box">
									<li v-for="(item, index) in mechanicalPropertiesList" :key="index">
										<span>{{item.propertyName}}</span>:
										<span style="margin-left:5px;">{{item.propertyValue}}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!isShowMachineProperties && !chemicalComponentList.length && !mechanicalPropertiesList.length">
					暂无数据
				</div>
			</div>
		</el-dialog>
    <div v-else class="machine-dialog">
      <div class="machine-content" v-if="Object.keys(installApplication).length && isShowMachineProperties">
        <p class="install-application">安装应用（拧紧力矩计算器）</p>
        <div class="module-top">
          <div class="left-box">
            <p class="reduce-line" style="margin: 16px 0 8px 0;">
              <label>P---预紧力系数</label>
              <el-select class="select-box" v-model="installAppObj.preload" placeholder="">
                <el-option
                    v-for="(item, index) in installApplication.preloadList"
                    :key="index"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
              <!-- <span>-</span>
              <el-select class="select-box" v-model="installAppObj.maxRub" placeholder="">
                <el-option
                v-for="(item, index) in installApplication.maxRubList"
                :key="index"
                :label="item"
                :value="item">
                </el-option>
              </el-select> -->
            </p>
            <p class="reduce-line">
              <label>K---扭矩系数</label>
              <el-select class="select-box" v-model="installAppObj.minTorque" placeholder="">
                <el-option
                    v-for="(item, index) in installApplication.minTorqueList"
                    :key="index"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
              <span>-</span>
              <el-select class="select-box" v-model="installAppObj.maxTorque" placeholder="">
                <el-option
                    v-for="(item, index) in installApplication.maxTorqueList"
                    :key="index"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </p>
            <p class="reduce-line" style="margin-top: 16px; padding-top: 16px; border-top: 1px solid #686868;">
              <label>T---拧紧力矩N.m</label>
              <span class="reduce-value">{{calculateResultValue}}</span>
            </p>
            <p v-if="yieldPoint == 0">（当产品无等级要求时，不计算扭矩）</p>
          </div>
          <div class="right-box">
            <p class="count-equation"><i class="iconfont icon-2" style="margin-right: 12px;"></i>扭矩计算公式 T=K*P*D</p>
            <p class="count-desc">其中：T--力矩(N.m)P--预紧力(KN)D---螺栓的公称直径(MM)</p>
            <p class="rb-p3">根据下表：</p>
            <p class="rb-p4">有润滑的表面氧化面，则扭矩系数为K=0.20</p>
            <p class="rb-p4">预紧力一般取：P=（0.6-0.7）*屈服点(N/mm2)*应力截面积(mm2)</p>
            <p class="rb-p4">屈服点和应力截面积参照GB3098.1  0.6-0.7根据VDI-2230推荐所取。</p>
          </div>
        </div>
        <div class="module-chart-box">
          <div class="chemical-component" v-if="chemicalComponentList.length">
            <p class="chemical-tit">化学成分</p>
            <div ref="echartsDom" style="width: 372px; height:329px; margin-top: 40px;"></div>
          </div>
          <div class="properties-box">
            <div class="pro-par1" v-if="Object.keys(threadStressArea).length">
              <span class="par1-tit">螺纹应力截面积</span>
              <div>
                <p class="par1-img">
                  <img src="../../assets/images/gb/machine/threadArea.png" alt="">
                </p>
                <p class="par1-val">{{threadStressArea.stressArea.toFixed(2)}}mm²</p>
              </div>
            </div>
            <div class="pro-par2" v-if="mechanicalPropertiesList.length" :style="{minHeight: Object.keys(threadStressArea).length ? '239px' : '420px'}">
              <span class="par2-tit">机械性能</span>
              <div style="display: flex; align-items: center;">
                <!--              <img class="par2-img" src="@/assets/images/commodity/properties.png" alt="">-->
                <ul class="properti-box">
                  <li v-for="(item, index) in mechanicalPropertiesList" :key="index">
                    <span>{{item.propertyName}}</span>:
                    <span style="margin-left:5px;">{{item.propertyValue}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p class="tip-high-text" v-if="applicationDescHtml">*应努力达到摩擦系数等级中列出的摩擦系数，从而获得尽可能高的预紧力，同时数值分散也较小，这也就是不自动的使用最小的值，同时目前的摩擦系数数值分散与等级的数值分散相符，该表格在室温时有效</p>
        <div class="temp-html" v-html="applicationDescHtml"></div>
      </div>
      <div class="machine-content" v-else>
        <div class="module-chart-box">
          <div class="chemical-component" v-if="chemicalComponentList.length">
            <p class="chemical-tit">化学成分</p>
            <div ref="echartsDom" style="width: 372px; height:329px; margin-top: 40px;"></div>
          </div>
          <div class="properties-box">
            <div class="pro-par2" v-if="mechanicalPropertiesList.length" :style="{minHeight: Object.keys(threadStressArea).length ? '239px' : '420px'}">
              <span class="par2-tit">机械性能</span>
              <div style="display: flex; align-items: center;">
                <!--              <img class="par2-img" src="@/assets/images/commodity/properties.png" alt="">-->
                <ul class="properti-box">
                  <li v-for="(item, index) in mechanicalPropertiesList" :key="index">
                    <span>{{item.propertyName}}</span>:
                    <span style="margin-left:5px;">{{item.propertyValue}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isShowMachineProperties && !chemicalComponentList.length && !mechanicalPropertiesList.length">
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as machine from '@/api/machine/index'
import echarts from 'echarts'
import Md5 from 'js-md5'
import { filterParams} from '@/filters'; // 过滤器函数集合
import {getMachinePropertiesForFree,getMachinePropertiesForFreeBaike} from "@/api_lm/lmPortals/gbApi";
export default {
	name: 'wlm-machine',
	props: {
		params: {
			// entranceType  11 工业百科列表 21 我的产品列表 22 我的产品详情 31 图纸草稿列表 32 图纸草稿详情
			// gbComponentId  当entranceType值为21或22时需传 默认无需传
			// nonStdId  当entranceType值为31或32时需传 默认无需传
			type: Object,
			default: {}
		},
		checkBigId: {
			type:[Number,String],
			default: '' // 默认设置1螺栓
		},
    notDialog:{
      type:Boolean,
      default:false
    }
	},
	data() {
		return {
			machineDialogVisible: false,
			installApplication: {}, //应用安装
			chemicalComponentList: [], //化学成分
			mechanicalPropertiesList: [], //机械性能
			threadStressArea: {}, // 螺纹应力截面积
			applicationDescHtml: '', //描述文本
			yieldPoint: '', //屈服点
			nominalValue: '', // 螺栓的公称直径
			installAppObj: {
				minRub: '',
				maxRub: '',
				minTorque: '',
				maxTorque: '',
				preload: '' //预紧力系数
			},
			echartsOption: {
				series : [
					{
						type: 'pie',    // 设置图表类型为饼图
						radius: '50%',  // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
						data: [],        // 数据数组，name 为数据项名称，value 为数据项值
						itemStyle: {
							normal: {
								label: {
									show: true,
									formatter: '{b}'
								}
							}
						}
					}
				]
			},
			privateKey: 'DevX3QIqik6mHmyuw',
			machineApiList: [ // 机械性能api
				'getMachinePropertiesParams',
				'getMachinePropertiesForFree',
        'getMachinePropertiesForFreeBaike'
			]
		}
	},
	computed: {
		isShowMachineProperties() { //当前选中大类的id
			// 1  73  306  315 螺栓   螺柱  螺钉  组合件
			// console.log(this.checkBigId)
			// 获取列表大类id要传0，多个判断
			if (this.checkBigId == 1 || this.checkBigId == 0 || this.checkBigId == 73 || this.checkBigId == 306 || this.checkBigId == 315 ) {
				return true
			} else {
				return false
			}
		},
		calculateResultValue() {
			// 计算规则 = 预紧力系数（preload） * 屈服点（yieldPoint） * 螺纹应力截面积（stressArea） / 1000 * 螺栓的公称直径（nominalValue） * 扭矩系数（minTorque || maxTorque）
			var minVal = (this.installAppObj.preload * this.yieldPoint * this.threadStressArea.stressArea / 1000 * this.nominalValue * this.installAppObj.minTorque).toFixed(2)
			var maxVal = (this.installAppObj.preload * this.yieldPoint * this.threadStressArea.stressArea / 1000 * this.nominalValue * this.installAppObj.maxTorque).toFixed(2)
			return minVal + '-' + maxVal
		}
	},
	methods: {
		_getMachinePropertiesParams() {
			// 初始化安装应用参数
			this.installAppObj.preload = ''
			this.installAppObj.minTorque = ''
			this.installAppObj.maxTorque = ''

			this.machineDialogVisible = true
			var machineParamsObj = {
				entranceType: this.params.entranceType || '',
				gbComponentId: this.params.gbComponentId || '',
				materialId: this.params.materialId || '',
				nominalId: this.params.nominalId || '',
				nonStdId: this.params.nonStdId || '',
				standardId: this.params.standardId || '',
				strengthId: this.params.strengthId || '',
				surfaceId: this.params.surfaceId || '',
				timestamp: new Date().getTime(),
				toothTypeId: this.params.toothTypeId
			}
			if (this.params.gbComponentId>0){//当有componentId时，只需要传以下参数
        machineParamsObj = {
          entranceType: this.params.entranceType || '',
          gbComponentId: this.params.gbComponentId || '',
          timestamp: new Date().getTime()
        }
      }
			var signStr = ''
			for (var i in machineParamsObj) {
				signStr += i + '=' + machineParamsObj[i] + '&'
			}
			signStr = signStr.substring(0, signStr.length - 1) + this.privateKey
			// console.log(signStr)
			machineParamsObj.sign = Md5(signStr)

			// var apiIndex = this.params.gbComponentId>0?1:2;
      var apiIndex = 1;
      machine[this.machineApiList[apiIndex]](filterParams(machineParamsObj)).then(res => {
      // getMachinePropertiesForFree(filterParams(machineParamsObj)).then(res => {
				// console.log(res)
				if (res.data.status != 10001) {
					this.installApplication = res.data.installApplication //应用安装
					this.chemicalComponentList = res.data.chemicalComponentList //化学成分
					this.mechanicalPropertiesList = res.data.mechanicalPropertiesList //机械性能
					this.threadStressArea = res.data.threadStressArea // 螺纹应力截面积
					this.applicationDescHtml = res.data.applicationDescHtml //描述文本
					this.yieldPoint = res.data.yieldPoint //屈服点
					this.nominalValue = res.data.nominalValue // 螺栓的公称直径

					this.echartsOption.series[0].data = []
					this.chemicalComponentList.forEach(item => {
						if (item.value.indexOf('-') != -1) {
							var name = item.name += item.value
							var val = item.value.substring(0, item.value.indexOf('-'))
							this.echartsOption.series[0].data.push({name: name, value: val})
						} else {
							var name = item.name += item.value
							this.echartsOption.series[0].data.push({name: name, value: item.value.replace(/(\≤|\=|\<|\>|\≥|\%|)/gi, '')})

							// var reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
							// if(reg.test(item.value)){
							// 	console.log("当前是汉字")
							// }
						}
					})
					this.$nextTick(() => {
						if (this.chemicalComponentList.length) { // 需要显示且有数据去加载饼状图
							this.initEchart()
						}
					})
				} else {
					this.$message.error(res.data.message || '获取参数失败')
					this.machineDialogVisible = false
				}
			}).catch( err => {
				this.$message.error('网络错误')
				this.machineDialogVisible = false
            })
		},
		initEchart(){
			var myChart = echarts.init(this.$refs.echartsDom)
			myChart.setOption(this.echartsOption, true)
        },
		open() {
			this.machineDialogVisible = true
		},
		close() {
			this.machineDialogVisible = false
		}
	}
}
</script>

<style lang="scss">
	.hgpMachine {
    font-size: 14px;
		.machine-dialog{
			.machine-content {
				color: #333333;
				.install-application {
					font-size: 14px;
				}
				.module-top {
					margin-top: 12px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.left-box {
						width: 326px;
						min-height: 173px;
						background: rgba(0, 0, 0, 0.7);
						color: #fff;
						border-radius: 4px;
						.reduce-line {
							label {
								display: inline-block;
								width: 137px;
								padding-left: 8px;
							}
							.select-box {
								width: 61px;
								.el-input__inner {
									background: rgba(255, 255, 255, 0.35);
									border: none;
									padding: 0 6px;
									color: #fff;
								}
								.el-input__suffix {
									color: #fff;
								}
							}
							.reduce-value {
								display: inline-block;
								vertical-align: middle;
								width: 135px;
								height: 32px;
								line-height: 32px;
								padding-left: 12px;
								background: rgba(255, 255, 255, 0.35);
								border-radius: 2px;
							}
						}
					}
					.right-box {
						padding: 17px;
						width: 476px;
						min-height: 173px;
						background: #FFFAEB;
						color: #271A00;
						.count-equation {
							font-size: 12px;
						}
						.count-desc {
							font-size: 14px;
							margin: 12px 0 11px;
						}
						.rb-p3 {
							font-size: 12px;
							color: #666666;
							margin-bottom: 4px;
						}
						.rb-p4 {
							font-size: 12px;
							color: #666666;
							line-height: 17px;
						}
					}
				}
				.module-chart-box {
					margin-top: 16px;
					display: flex;
					justify-content: space-between;
					align-items: top;
					.chemical-component {
						width: 420px;
						min-height: 420px;
						background: #F7F7F7;
						padding: 16px;
						.chemical-tit {
							font-size: 14px;
							color: #000;
						}
					}
					.properties-box {
						font-size: 14px;
						color: #000;
						.pro-par1 {
							width: 476px;
							min-height: 165px;
							background: #F7F7F7;
							margin-bottom: 16px;
							padding: 16px;
							.par1-img {
								width: 108px;
								height: 108px;
								border: 1px dashed #333;
								margin-top: 8px;
								display: inline-block;
								vertical-align: middle;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.par1-val {
								margin-left: 137px;
								display: inline-block;
								vertical-align: middle;
							}
						}
						.pro-par2 {
							width: 476px;
							background: #F7F7F7;
							padding: 16px;
							.par2-img {
								width: 56px;
								height: 56px;
								margin-left: 10px;
							}
							.properti-box {
								margin-left: 28px;
								li {
									margin-bottom: 20px;
								}
							}
						}
					}
				}
				.tip-high-text {
					font-size: 12px;
					color: #ED7F0E;
					margin: 16px 0;
				}
				.temp-html {
					table {
						border-collapse: collapse;
						border-spacing: 2px;
						tbody {
							tr {
								&:nth-child(1), &:nth-child(2) {
									background: #F7F7F7;
								}
								td {
									padding: 8px;
									line-height: 20px;
									vertical-align: middle;
									border: 1px solid #E8E8E8;
									color: #333333;
									word-break: normal;
								}
							}
						}
					}
				}
				// div.temp-html :nth-child(3){
				// 	margin: 10px 0 !important;
				// }
			}
			.el-dialog__header {
				display: flex;
				align-items: center;
				font-weight: 600;
				box-sizing: border-box;
				height: 44px;
				background-color: #F6F6F6;
				border-bottom: 1px solid #DADADA;
				border-radius: 4px 4px 0px 0px;
				.el-dialog__headerbtn {
					top: 16px;
					right: 16px;
				}
				.el-dialog__title {
					font-size: 14px;
					margin-left: 16px;
				}
			}
			.el-dialog__body {
				padding: 16px 24px;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			.el-dialog__headerbtn .el-dialog__close {
				color: #333333;
			}
		}

    .scrollBox {
      height: 80vh;
      overflow-y: auto;
    }
	}
</style>
