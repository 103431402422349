import request from "@/api_lm/request"
const baseUrl = process.env.VUE_APP_WLM_BASE_URL

/**
 * 获取我的供应商
 */
export function mySupplierList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/find-goods/lm-supplier-page-my`,
    method: "get",
    params: query,
  });
}

/**
 * 获取供应商联系方式
 * @params params
 */
export function getContactWay(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/tenant-info`,
    method: "get",
    params: query,
  });
}
