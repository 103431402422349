<template>
  <!-- 查看尺寸图纸 -->
  <hgp-dialog title="尺寸图纸" :visible.sync="dialogPicture" width="770px" @handleClose="handleClose" class="no-print"
              :appendBodyClass="appendBodyClass" :appendBody="appendBody">
    <div slot="content" class="pictureCount">
      <el-radio-group v-model="tabPosition">
        <el-radio-button label="1" v-if="viewItems.simpleImage">简图</el-radio-button>
        <el-radio-button label="0" v-if="viewItems.detailImage">详图</el-radio-button>
        <el-radio-button label="2" v-if="viewItems.model3D">3D预览</el-radio-button>
      </el-radio-group>
      <span style="font-size: 12px;color: #969799;margin-left: 24px;line-height: 26px;">使用鼠标滚轮可以放大缩小图纸</span>
      <!--      <el-button type="primary" @click="preview" style="position: absolute;right: 20px">放大查看></el-button>-->
      <div class="container">
        <hgp-image v-if="tabPosition != 2" :src="pictureImg"></hgp-image>
        <iframe allowfullscreen="true" v-else :src="pictureImg" height="520px" width="100%"
                style="margin-top:20px;"></iframe>
      </div>
      <div v-if="row.nonstandardId != 0">
        <div v-if="submemberproductlist.length > 0 && tabPosition != '2'">
          <div v-if="disintegrate">
            <el-button @click="lookSingle(index)" v-for="(item,index) in submemberproductlist" :key="index">
              {{ item.standardName }}
            </el-button>
          </div>
          <div v-else>
            <el-button @click="balck">返回</el-button>
          </div>
        </div>

      </div>
      <div v-else>
        <div v-if="constituteList.length > 0 && tabPosition != '2'">
          <div v-if="disintegrate">
            <el-button @click="lookSingle(index)" v-for="(item,index) in constituteList" :key="index">
              {{ item.standardName }}
            </el-button>
          </div>
          <div v-else>
            <el-button @click="balck">返回</el-button>
          </div>
        </div>
      </div>
    </div>
  </hgp-dialog>
</template>
<script>
import {
  getweb3dCustomizedUrl, // 非标
  getweb3dUrlPortal,
  getMating, // 标准组合件
  submemberproducts, // 非标组合件

} from "@/api_lm/union/gbApi";
import {
  mapGetters
} from 'vuex'

export default {
  name: "hgp-scan-picture",
  props: {
    dialogPicture: {
      type: Boolean,
      default() {
        return false
      }
    },
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    viewItems: {
      type: Object,
      default() {
        return {
          "model3D": true,
          "simpleImage": true,
          "detailImage": true
        }
      }
    },
    appendBody: {
      type: Boolean,
      default() {
        return false
      }
    },
    appendBodyClass: {
      type: String,
      default: ''
    },
    combine: {
      type: Object,
      default() {
        return {}
      }
    },
    // 尾货图纸 1
    source: {
      type: [String, Number],
      default() {
        return ''
      }
    }
  },
  computed: {},
  watch: {
    dialogPicture: {
      handler(val) {
        if (val) {
          this.tabPosition = 1;
          this.viewProductImg()
        }
      },
      immediate: true,
      deep: true
    },
    tabPosition(val) {
      if (val != 2) {
        if (this.row.nonstandardId != 0) {
          let standsId = this.composeIndex > -1 ? this.submemberproductlist[this.composeIndex].taskId : this
              .row.nonstandardId;
          this.pictureImg = process.env.VUE_APP_BASE_URL +
              "/apiz/rest/asm/center/v1/lm/find-goods/fastener?tk=" + "&svgShowType=" + val +
              "&source=" + this.source +
              "&removeBottomLeft=false&noBgImage=false&taskId=" + standsId;
        } else {
          let standsIdSecond = this.composeIndex > -1 ? this.constituteList[this.composeIndex].standardId :
              this.row.standardId;
          this.pictureImg = process.env.VUE_APP_BASE_URL +
              "/apiz/rest/asm/center/v1/lm/find-goods/fastener-common-image-portal?tk=" +
              "&source=" + this.source +
              "&standardId=" + standsIdSecond + "&materialId=" +
              this.row.materialId + "&textureId=" + this.row.textureId + "&strengthId=" + this.row
                  .strengthId + "&nominalId=" + this.row.nominalId +
              "&length=" + this.row.length + "&toothTypeId=" + this.row.toothType1Id + "&toothType2Id=" +
              this.row.toothType2Id + "&surfaceTreatmentId=" + this.row
                  .surfaceTreatmentId + "&threadRotationId=" + this.row.threadRotation +
              "&sizeLevel=&removeBottomLeft=false&width=200&height=140&productQrCodeUrl=&svgType=2&svgShowType=" +
              val +
              "&cid=" + (this.row.componentId && this.row.componentId != 'null' ? this.row.componentId : '') +
              "&combineTreeId=" + (this.combine.combineTreeId || '') + '&componentId=' + (this.combine.componentId || '');
        }
      } else {

        this.pictureImg = '';
        if (this.row.nonstandardId != 0) {
          getweb3dCustomizedUrl({
            taskId: this.row.nonstandardId
          }).then(res => {
            if (res.data.status == 10001) return this.pictureImg = '';
            this.pictureImg = res.data.content;
          })
        } else {
          getweb3dUrlPortal({
            textureId: this.row.textureId,
            surfaceId: this.row.surfaceTreatmentId,
            materialId: this.row.materialId,
            standardId: this.row.standardId,
            nominalId: this.row.nominalId,
            toothTypeId1: this.row.toothType1Id,
            toothTypeId2: this.row.toothType2Id,
            LengthValue: this.row.length,
            threadRotation: this.row.threadRotation,
            combineTreeId: this.combine.combineTreeId,
            componentId: this.combine.componentId,
            topStandardId: this.combine.topStandardId,
            combinedIdPath: this.combine.combinedIdPath,
          }).then(res => {
            if (res.data.status == 10001) return this.pictureImg = '';
            this.pictureImg = res.data.content;
          })
        }
      }
    }
  },
  data() {
    return {
      tabPosition: 1, // 图纸切换
      pictureImg: '',
      composeIndex: -1,
      submemberproductlist: [],
      constituteList: [],
      disintegrate: false,

    }
  },
  methods: {
    preview() {
      let routeUrl = this.$router.resolve({
        path: `/fasternerImg?componentId=${this.row.componentId}`
      });
      window.open(routeUrl.href, '_blank');
    },
    load3D() {
      if (this.row.nonstandardId != 0) {
        getweb3dCustomizedUrl({
          taskId: this.row.nonstandardId
        }).then(res => {
          if (res.data.status == 10001) return this.pictureImg = '';
          this.pictureImg = res.data.content;
        })
      } else {
        getweb3dUrlPortal({
          textureId: this.row.textureId,
          surfaceId: this.row.surfaceTreatmentId,
          materialId: this.row.materialId,
          standardId: this.row.standardId,
          nominalId: this.row.nominalId,
          toothTypeId1: this.row.toothType1Id,
          toothTypeId2: this.row.toothType2Id,
          LengthValue: this.row.length,
          threadRotation: this.row.threadRotation,
          combineTreeId: this.combine.combineTreeId,
          componentId: this.combine.componentId,
          topStandardId: this.combine.topStandardId,
          combinedIdPath: this.combine.combinedIdPath,
        }).then(res => {
          if (res.data.status == 10001) return this.pictureImg = '';
          this.pictureImg = res.data.content;
        })
      }
    },

    viewProductImg() { // 查看图纸
      // this.tabPosition = 1;
      if (this.viewItems.detailImage && this.viewItems.simpleImage && this.viewItems.model3D) {
        this.tabPosition = 1;
      } else if (this.viewItems.detailImage && !this.viewItems.simpleImage) {
        this.tabPosition = 0;
      } else if (!this.viewItems.detailImage && this.viewItems.simpleImage) {
        this.tabPosition = 1;
      } else if (!this.viewItems.detailImage && !this.viewItems.simpleImage && this.viewItems.model3D) {
        this.tabPosition = 2;
        this.pictureImg = '';
        this.load3D();
        return;
      }
      this.balck();
      if (this.row.nonstandardId != 0) {
        let submemberproductsparams = {
          id: this.row.nonstandardId
        }
        submemberproducts(submemberproductsparams).then((res) => {
          let data = res.data
          if (data.success == false) return;
          this.submemberproductlist = data;
        });
      } else {
        getMating({
          standardId: this.row.standardId
        }).then(res => {
          if (res.status == 200) {
            this.constituteList = res.data;
          }
        })
      }
    },
    balck() {
      this.disintegrate = true;
      if (this.row.nonstandardId != 0) {
        this.pictureImg = process.env.VUE_APP_BASE_URL +
            "/apiz/rest/asm/center/v1/lm/find-goods/fastener?tk=" +
            "&source=" + this.source +
            "&svgShowType=" + this.tabPosition + "&removeBottomLeft=false&noBgImage=false&taskId=" + this.row.nonstandardId;
      } else {
        this.pictureImg = process.env.VUE_APP_BASE_URL +
            "/apiz/rest/asm/center/v1/lm/find-goods/fastener-common-image-portal?tk=" +
            "&source=" + this.source +
            "&standardId=" + this.row.standardId + "&materialId=" + this.row.materialId + "&textureId=" + this
                .row.textureId +
            "&strengthId=" + this.row.strengthId + "&nominalId=" + this.row.nominalId + "&length=" + this.row
                .length + "&toothTypeId=" + this.row.toothType1Id +
            "&toothType2Id=" + this.row.toothType2Id + "&surfaceTreatmentId=" + this.row.surfaceTreatmentId +
            "&threadRotationId=" + this.row.threadRotation +
            "&sizeLevel=&removeBottomLeft=false&width=200&height=140&productQrCodeUrl=&svgType=2&svgShowType=" +
            this.tabPosition + "&cid=" + (this.row.componentId && this.row.componentId != 'null' ? this.row.componentId : '') +
            "&combineTreeId=" + (this.combine.combineTreeId || '') + '&componentId=' + (this.combine.componentId || '');

      }
    },
    lookSingle(index) {
      this.composeIndex = index;
      this.disintegrate = false;
      if (this.row.nonstandardId != 0) {
        this.pictureImg = process.env.VUE_APP_BASE_URL +
            "/apiz/rest/asm/center/v1/lm/find-goods/fastener?tk=" +
            "&source=" + this.source +
            "&svgShowType=1&removeBottomLeft=false&noBgImage=false&taskId=" + this.submemberproductlist[index]
                .taskId;
      } else {
        this.pictureImg = process.env.VUE_APP_BASE_URL +
            "/apiz/rest/asm/center/v1/lm/find-goods/fastener-common-image-portal?tk=" +
            "&source=" + this.source +
            "&standardId=" + this.constituteList[index].standardId + "&materialId=" + this.row.materialId +
            "&textureId=" + this.row.textureId +
            "&strengthId=" + this.row.strengthId + "&nominalId=" + this.row.nominalId + "&length=" + this.row
                .length + "&toothTypeId=" + this.row.toothType1Id +
            "&toothType2Id=" + this.row.toothType2Id + "&surfaceTreatmentId=" + this.row.surfaceTreatmentId +
            "&threadRotationId=" + this.row.threadRotation +
            "&sizeLevel=&removeBottomLeft=false&width=200&height=140&productQrCodeUrl=&svgType=2&svgShowType=" +
            this.tabPosition + "&cid=" + (this.row.componentId && this.row.componentId != 'null' ? this.row.componentId : '') +
            "&combineTreeId=" + (this.combine.combineTreeId || '') + '&componentId=' + (this.combine.componentId || '');
      }
    },
    handleClose() { // 弹窗关闭
      this.$emit('update:dialogPicture', false)
    },
  }
}
</script>
<style lang="scss">
.pictureCount{
  padding: 20px;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
}
</style>
