<template>
  <div>
    <hgp-dialog title="推荐您申请以下卖家的会员" :appendToBody="false"  :visible.sync="dialogVisible"  width="800px" @handleClose="handleClose" :closeModel="false">
      <div slot="content" >
        <div class="content" @click="handleClickOutside">
          <div class="banner">
            <img src="../../../assets/images/lmPortals/home/banner_apply.png">
          </div>
          <div class="p-24 search-cont">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" inline>
              <el-form-item>
                <div class="nav-title" style="display: flex; justify-content:space-between;align-items:center;"
                    @click.stop="showTabs = true,activeName='province'">
                  <div v-if="addressInfo" :style="addressInfo.regionName=='区域/位置'?'color: rgba(0,0,0,0.4);':''">{{ addressInfo.regionName }}</div>
                  <i v-if="addressInfo.regionName=='区域/位置'" class="iconfont icon-sanjiaoxia"></i>
                  <i v-else class="iconfont el-icon-circle-close" @click.stop="clearAreaCode"></i>
                </div>
                <div class="border" v-if="showTabs"></div>
                <div ref="navbar">
                  <el-tabs class="y-tabs nav-tabs"  v-if="showTabs" v-model="activeName" type="card">
                    <el-tab-pane label="省份" name="province">
                      <div class="area-box" >
                        <div :class="addressInfo && addressInfo.provinceCode == item.value ? 'active font-hidden': 'font-hidden'"
                            @click.stop="handleClickProvince(item, index)" v-for="(item, index) in syntheticalData" :key="item.value">
                          {{ item.label }}
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="城市" name="city" v-if="addressInfo.provinceCode">
                      <div class="area-box" v-if="provinceIndex>=0 && syntheticalData[provinceIndex].children">
                        <div :class="addressInfo && addressInfo.cityCode == item.value? 'active font-hidden': 'font-hidden'"
                            @click.stop="handleClickCity(item, index)" v-for="(item, index) in syntheticalData[provinceIndex].children" :key="item.value">
                          {{ item.label }}
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="区域" name="area" v-if="addressInfo.cityCode">
                      <div class="area-box" v-if="provinceIndex>=0 && cityIndex >= 0 && syntheticalData[provinceIndex].children[cityIndex]">
                        <div @click.stop="handlClickArea(item, index)" :class=" addressInfo && addressInfo.areaCode == item.value? 'active font-hidden': 'font-hidden'"
                            v-for="(item, index) in syntheticalData[provinceIndex].children[cityIndex].children" :key="item.value">
                          {{ item.label }}
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-form-item>
              <el-form-item>
                <el-select style="width: 120px;" v-model="ruleForm.status" placeholder="申请状态" clearable>
                  <el-option
                      v-for="(item,index) in statusList"
                      :key="index"
                      :label="item.id"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input style="width: 240px;" v-model="ruleForm.keyWords" placeholder="输入卖家名称" clearable/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="lmSupplierList">搜索</el-button>
                <el-button @click="resetForm">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <el-table :data="tableData" ref="multipleTable" @selection-change="handleSelectionChange"
                      @row-click="handleRowClick" height="400" border class="hgp-table" stripe>
              <el-table-column type="selection" align="left" width="40" :selectable="checkboxT">
              </el-table-column>
              <el-table-column prop="supplierName" align="left" label="卖家名称">
                <template slot-scope="scope">
                  <div class="flex align-center justify-between">
                    <div>{{scope.row.companyShortName}}</div>
                    <shopButton class="m-r-10 flex-shrink-0" :jywShop="scope.row.jywShop" :erpTenantId="scope.row.erpTenantId" :showContact="true"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="areaName" align="left" label="卖家位置" width="180"></el-table-column>
              <el-table-column label="申请状态" align="left" width="160">
                <template slot-scope="scope">
                  <span style="padding: 6px 12px;cursor: pointer;line-height: 14px;font-weight: 600;" :style="{color: colorList[scope.row.status],background: backgroundList[scope.row.status]}">{{scope.row.statusName}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="submitForm">提交申请</el-button>
        </div>
      </div>
    </hgp-dialog>
    <hgp-dialog title="提交申请" :visible.sync="warningVisible"  width="400px" @handleClose="handleClose" :closeModel="false">
      <div slot="content" >
        <div class="warning">
          <div class="title">卖家会员申请已提交！</div>
          <div class="warning-num">
            <span style="margin-right: 16px;">成功提交 <span style="color: #00A870;">({{totalNum}}) </span></span>
            <span>提交失败 <span style="color: #D93845;">({{failureNum}}) </span></span>
          </div>
        </div>
        <div class="footer" style="justify-content: center;border: none;">
          <el-button type="primary" @click="Close">确定</el-button>
        </div>
      </div>
    </hgp-dialog>
  </div>
</template>

<script>
import {lmSupplierPageList,lmTenantsApplyStatus,confirmLmTenantsApply,getTenantCity} from "@/api_lm/lmPortals/home";
import shopButton from "@/views/lmPortals/components/shopButton";

export default {
  name: "applyMember",
  components:{
    shopButton,
  },
  data(){
    return{
      options: [],
      provinceIndex: 0,
      cityIndex: 0,
      activeName: "province",
      showTabs: false,
      totalNum: 0,
      failureNum: 0,
      warningVisible: false,
      dialogVisible: true,
      ruleForm: {
        status: '',
        keyWords: '',
      },
      statusList: [],
      syntheticalData: [],
      cityCode: [],
      tableData: [],
      addressInfo: {
        regionName: "区域/位置",
        regionCode: [],
      },
      colorList: ['#666666','#00A870','#F67600','#0C65E7'],
      backgroundList: ['#EEEEEE','#E8F8F2','#FFF2EC','#F1F5FF'],
      multipleSelection: [],
      maxSelectionCount: 5,
    }
  },
  created() {
    this.lmSupplierList()
    this.lmApplyStatus()
    this.getCitys()
  },
  methods:{
    getCitys(){
      getTenantCity().then(res=>{
        this.syntheticalData = res.data;
      })
    },
    handleClickOutside(event){ // 点击外部关闭弹窗
      if (!this.$refs.navbar.contains(event.target)) {
        this.showTabs = false
      }
    },
    // 清除选择
    clearAreaCode(){
      this.addressInfo.regionCode = []
      this.addressInfo.regionName = '区域/位置'
      this.provinceIndex = 0;
      this.cityIndex = 0;
      this.activeName = "province";
      this.addressInfo.provinceCode = '';
      this.addressInfo.cityCode = "";
      this.addressInfo.areaCode = "";
    },
    //点击省份
    handleClickProvince(item, index) {
      this.provinceIndex = index;
      this.cityIndex = 0;
      this.activeName = "city";
      this.addressInfo.provinceCode = item.value;
      this.addressInfo.cityCode = "";
      this.addressInfo.areaCode = "";
      if(item.value == '') { // 全部
        this.addressInfo.regionCode = []
        this.addressInfo.regionName = '全部'
        this.showTabs = false;
        return;
      }
    },
    //点击城市
    handleClickCity(item, index) {
      this.cityIndex = index;
      this.activeName = "area";
      this.addressInfo.cityCode = item.value;
      this.addressInfo.areaCode ='';
      let options=JSON.parse(JSON.stringify(this.syntheticalData))
      this.provinceIndex = options.findIndex((val) => {
        return val.value == this.addressInfo.provinceCode;
      });

      this.cityIndex = options[this.provinceIndex].children.findIndex(
          (val) => {
            return val.value == this.addressInfo.cityCode;
          }
      );
      if(item.value == '') { // 全部
        this.addressInfo.regionCode = [this.syntheticalData[this.provinceIndex].value]
        this.addressInfo.regionName = [this.syntheticalData[this.provinceIndex].label,'全部'].toString()
            .replace(/,/g, "/");
        this.showTabs = false;
        return;
      }
    },
    //点击地区
    handlClickArea(item, index) {
      if(item.value == ''){
        this.addressInfo.regionCode = [this.syntheticalData[this.provinceIndex].value,this.syntheticalData[this.provinceIndex].children[this.cityIndex].value]
        this.addressInfo.regionName = [this.syntheticalData[this.provinceIndex].label,this.syntheticalData[this.provinceIndex].children[this.cityIndex].label,'全部'].toString()
            .replace(/,/g, "/");
        this.showTabs = false;
        return
      }
      this.addressInfo.areaCode = item.value;

      this.syntheticalData.forEach((provinceItem) => {
        provinceItem.children.forEach((cityItem) => {
          cityItem.children.forEach((areaItem) => {
            if (areaItem.value == item.value) {
              this.addressInfo.regionCode = [
                provinceItem.value,
                cityItem.value,
                areaItem.value,
              ];
              this.addressInfo.regionName = [
                provinceItem.label,
                cityItem.label,
                areaItem.label,
              ].toString().replace(/,/g, "/");
            }
          });
        });
      });
      this.showTabs = false;
    },
    handleSelectionChange(val){
      if (val.length > this.maxSelectionCount) {
        let del_row = val.pop()
        this.$refs.multipleTable.toggleRowSelection(del_row, false)
        this.$message.warning('最多选5家')
        return
      }
      this.multipleSelection = val
    },
    //支持选择行时，自动选中checkbox
    handleRowClick(row) {
      // 切换行的选中状态
      if (row.status == 1){//只有status == 1 的情况下，允许选中。
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    async lmApplyStatus(){
      let {data} = await lmTenantsApplyStatus()
      this.statusList = data
    },
    checkboxT(row,index){
      if(row.status ==1){
        return 1
      }else {
        return 0
      }
    },
    // 请求 获取我的联盟商列表接口
    async lmSupplierList(){
      let regionCode = JSON.parse(JSON.stringify(this.addressInfo.regionCode.filter(e=>e != ''))).join(',')
      let {data} = await lmSupplierPageList({
        ...this.ruleForm,
        ...{
          start: 1,
          limit: 100,
          areaCode: regionCode
        }
      })
      this.tableData = data.records
    },
    async submitForm() {
      let arr = []
      this.multipleSelection.forEach(e=>{
        arr.push(e.erpTenantId)
      })
      let {data} = await confirmLmTenantsApply({
        lookYouPohone: true,
        erpTenantIds: arr,
      })
      if(data.success){
        this.totalNum = arr.length
        if(data.data.failureNum>0) {
          this.failureNum = data.data.failureNum
        }
        this.dialogVisible = false;
        this.warningVisible = true
      }else{
        this.$message.error(data.errorMes)
      }
    },
    resetForm() {
      this.ruleForm.status = ''
      this.ruleForm.keyWords = ''
      this.clearAreaCode()
      this.lmSupplierList()
    },
    Close(){
      this.warningVisible = false
      this.$emit('closeModal')
    },
    handleClose() {
      this.$emit('closeModal')
    },
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .el-dialog{
    transform: none;
    position: static;
    margin: 0 auto !important;
  }
  .el-dialog__wrapper{
    display: flex;
    align-items: center;
  }
}
.nav-tabs {
  width: 420px;
  height: 300px;
  position: absolute;
  top: 28px;
  left: 0px;
  z-index: 999;
  background: #ffffff;
  border: 1px solid #dcdee0;
  box-sizing: border-box;
  // display: none;
}
.area-box {
  display: flex;
  flex-wrap: wrap;
  > div {
    color: #333333;
    width: 60px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    margin-bottom: 14px;
    margin-left: 12px;

  }
  > div:hover,
  .active {
    border: 1px solid $hgp-main-color;
    color: $hgp-main-color;
    line-height: 23px;
  }
}
.warning{
  padding: 32px 0;
  text-align: center;
  background: #fff;
  .title{
    font-size: 18px;
    color: rgba(0,0,0,0.9);
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .warning-num{
    font-size: 12px;
  }
}
.content{
  color: #333;
  .table{
    padding: 0 24px 24px;
    background: #fff;
  :deep {
    .el-table th.el-table__cell{
      background: #f5f5f5;
    }
    .el-table th.el-table__cell > .cell {
      color: #333;
      font-weight: 600;
    }
    .el-tabs__item{
      height: initial;
      line-height: initial;
    }
  }
  }
  .banner{
    img{
      width: 100%;
    }
  }
  .search-cont{
    background: #fff;
    .nav-title{
      width: 192px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #DCDCDC;
      padding: 0 8px;
      .iconfont{
        color: rgba(0,0,0,0.4)
      }
    }
    .y-tabs{
      top: 42px;
      padding-top: 8px;
      box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.12), 0px 4px 5px 0px rgba(0,0,0,0.08), 0px 1px 10px 0px rgba(0,0,0,0.05);
    }
    :deep{
      .el-tabs--card>.el-tabs__header{
        padding-left: 20px;
      }
      .el-tabs__item{
        background: #F7F8FA;
      }
      .el-tabs__item.is-active{
        background: #fff;
        color: #0052D9;
        font-weight: 600;
      }
    }
  }
  .tit{
    color: #666;
    font-size: 12px;
    margin-top: 16px;
    padding: 8px 16px;
    height: 36px;
    background: #F7F7F7;
    border-radius: 2px;
    border: 1px solid #DCDCDC;
  }
}
.footer{
  padding: 0 16px;
  display:flex;
  align-items: center;
  justify-content: end;
  height: 56px;
  background: #FFFFFF;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #EEEEEE;
}
</style>
