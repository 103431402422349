<template>
  <div class="y-back">
    <div class="return" @click="handleBackUrl">
      <i class="iconfont icon-fanhui"/>
      <slot>返回上一步</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "y-back",
  props: {
    //标题
    url: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleBackUrl() {
      if (this.url) return this.$router.push(this.url);
      if (this.$listeners.tap) return this.$emit("tap");
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.y-back {
  .return {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    min-width: 100px;
    background: #fff;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #dcdee0;
    .iconfont {
      font-size: 12px;
      margin-right: 4px;
    }
  }
}
</style>
