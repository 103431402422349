import api from '../';

 /**
 *  获取单位下拉数据源接口
 * @param {componentCode}
 */
export const getBaseUnit = (params) => api.get(`/apigb/v1/shopping-cart/select-data/base-unit`, {params});

 /**
 *  加入购物车接口
 * @param {componentCode，qty，baseUnit}
 */
export const addCart = (params) => api.post(`/apigb/v2/mall-shoppingcart/shopping-cart-save${params.length>0 && params[0].hideLoading ? ('?hideLoading='+params[0].hideLoading) : ''}`, params);

 /**
 *  购物车商品项列表接口
 * @param {}
 */
export const getCartList = (params) => api.get(`/apigb/v1/shopping-cart/items`, {params});

 /**
 *  删除购物车中商品项接口
 * @param {cartItemIds}
 */
export const deleteItems = (params) => api.post(`/apigb/v1/shopping-cart/delete-items`, params);
/**
 *  删除购物车中商品项接口NEW
 * @param {cartItemIds}
 */
export const deleteCartItems = (params) => api.post(`/apigb/v2/mall-shoppingcart/shopping-delete`, params);
/**
 *  清空失效商品接口
 * @param {cartItemIds}
 */
export const deleteInvalidItems = (params) => api.post(`/apigb/v1/shopping-cart/delete-invalid`, params);

/**
 *  修改购物车中商品项信息接口
 * @param {cartItemId，qty，baseUnit}
 */
export const updateItems = (params) => api.post(`/apigb/v1/shopping-cart/update-item`, params);

/**
 *  移入到收藏夹接口
 * @param {cartItemId，qty，baseUnit}
 */
export const moveFavorite = (params) => api.post(`/apigb/v1/shopping-cart/move-to-favorite`, params);

 /**
 *  获取购物车列表
 * @param {}
 */
  export const getShoppingCartList = (params) => api.get(`/apigb/v2/mall-shoppingcart/list`, {params});
/**
 *  获取购物车数量
 * @param {}
 */
export const getCartCount = (params) => api.get(`/apigb/v2/mall-shoppingcart/count`, {params});

/**
 *  购物车拆零明细数据
 * @param {}
 */
export const getScatteredList = (params) => api.get(`/apigb/v2/mall-shoppingcart/scattered-list`, {params});

/**
 *  首页购物车数据
 * @param {}
 */
export const getHomePageCartList = (params) => api.get(`/apigb/v2/mall-shoppingcart/slide/list`, {params});
/**
 *  获取已选购物车的总金额
 */
export const getSelectAmount = (params) => api.post(`/apigb/v2/mall-shoppingcart/select-amount`,params)
/**
 *  商品详情小计价格--计算
 */
export const getProductAmount = (params) => api.post(`/apigb/v1/searchgoods/product-amount`,params)
/**
 *  购物车获取商户活动标签
 */
export const getTenantTagList = (params) => api.get(`/apigb/v1/tag/tenant/tag/list`,{params})
