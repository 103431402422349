import api from '../';
import request from "@/api_lm/request";

const baseUrl = process.env.VUE_APP_WLM_BASE_URL;
/**
 *  获取机械性能参数 （非标及工业百科）
 * @param  //orderId
 */
export const getMachinePropertiesParams = (params) => api.post(`/apigb/v1/mechanical-behavior/detail`, params)


/**
 * 获取机械性能参数 （产品及详情）
 * 工宝对应API
 * export const getMachinePropertiesForFree = (params) => api.post(`/apigb/v1/mechanical-behavior/detail-for-free`, params)
 *
 * 原接口：/wlm-api/lmap/v1/lm/mechanical-behavior 换成 /wlm-api/apigb/v1/searchgoods/mechanical-behavior
 * * @params params
 */
export function getMachinePropertiesForFree(query) {
    return request({
        url: `${baseUrl}/wlm-api/apigb/v1/searchgoods/mechanical-behavior`,
        method: "post",
        data: query,
    });
}

export function getMachinePropertiesForFreeBaike(query) {
    return request({
        url: `${baseUrl}/wlm-api/lmap/v1/lm/mechanical-behavior`,
        method: "post",
        data: query,
    });
}