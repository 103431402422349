import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from 'element-ui';
import store from '../store'; // 引入 Vuex Store

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect: () => {
      // let redirectPath = '/union' // 默认重定向路径
      let redirectPath = '/lmPortals' // 默认重定向路径
      const origin = window.location.origin;
      // 除开发环境外
      if (process.env.NODE_ENV !== 'development') {
        // 内联盟
        if (origin.includes(process.env.VUE_APP_BASE_URL)) redirectPath = '/union';
        // 外联盟
        if (origin.includes(process.env.VUE_APP_WLM_BASE_URL)) redirectPath = '/lmPortals';
      }
      return redirectPath
    }
  },


  // 内联盟
  {
    path: '/union',
    redirect: '/union/home',
    meta: {
      module: 'union',
    },
    component: () => import(/* webpackChunkName: "union" */ '../layout/unionLayout/index'),
    children: [
      {
        path: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/union/home'),
        meta: {
          title: '首页',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        path: 'workbench',
        component: () => import(/* webpackChunkName: "workbench" */ '../views/union/workbench/index'),
        meta: {
          title: '工作台',
        },
      },
      {
        path: 'productList',
        component: () => import(/* webpackChunkName: "productList" */ '../views/union/productList/index'),
        meta: {
          title: '商品列表',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        // 非标
        path: 'noStdProductList',
        component: () => import(/* webpackChunkName: "noStdProductList" */ '../views/union/productList/index'),
        meta: {
          title: '商品列表',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        path: 'richText',
        component: () => import(/* webpackChunkName: "richText" */ '../views/union/richText/index'),
        meta: {
          title: '文章',
        },
      },
      {
        path: 'infoCenter',
        component: () => import(/* webpackChunkName: "infoCenter" */ '../views/union/infoCenter/index'),
        meta: {
          title: '信息中心',
        },
      },
      {
        path: 'openPage',
        component: () => import(/* webpackChunkName: "openPage" */ '../views/union/openPage/index'),
        meta: {
          title: '申请开通',
        },
      },
      {
        path: 'oldOpenPage',
        component: () => import(/* webpackChunkName: "openPage" */ '../views/union/openPage/oldIndex'),
        meta: {
          title: '新申请开通',
        },
      },
      {
        path: 'cart',
        component: () => import(/* webpackChunkName: "openPage" */ '../views/union/cart/index'),
        meta: {
          title: '待采清单',
        },
      },
    ]
  },

  // 外联盟
  {
    path: '/lmPortals',
    redirect: '/lmPortals/home',
    meta: {
      requiresAuth: true, // 添加需要登录的元信息
      footerMenu: true, // 底部菜单
      module: 'lmPortals',
    },
    component: () => import(/* webpackChunkName: "lmPortals" */ '../layout/lmPortalsLayout/index'),
    children: [
      {
        path: 'home',
        component: () => import(/* webpackChunkName: "lmPortals/home" */ '../views/lmPortals/home/index'),
        meta: {
          title: '首页',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        // FIXME "店铺" 是属于外联盟（/lmPortals）模块中的独立模块
        path: '/lmShop',
        redirect: '/lmShop/home',
        component: () => import(/* webpackChunkName: "lmPortals/lmShop" */ '../layout/shopLayout/index'),
        meta: {
          title: '店铺',
          cleanLayout: true,
          module: 'lmShop',
        },
        children: [
          {
            path: 'home',
            component: () => import(/* webpackChunkName: "/lmShop/home" */ '../views/lmPortals/lmShop/home/index'),
            meta: {
              title: '首页',
              shopFloatingMenu: true, // 浮动菜单
            },
          },
          {
            path: 'productList',
            component: () => import(/* webpackChunkName: "/lmShop/productList" */ '../views/lmPortals/productList/index'),
            meta: {
              title: '商品列表',
              shopFloatingMenu: true, // 浮动菜单
            },
          },
          {
            path: 'companyIntro',
            component: () => import(/* webpackChunkName: "/lmShop/companyIntro" */ '../views/lmPortals/lmShop/companyIntro/index'),
            meta: {
              title: '公司介绍',
            },
          },
          {
            path: "standardview",
            meta: {
              title: "零件数字库",
            },
            component: () => import(/* webpackChunkName: "/lmShop/standardview" */ "../views/lmPortals/utilityTools/standardview/fastener"),
          },
          {
            path: "standardQuery",
            meta: {
              title: "零件数字库（看图模式）",
            },
            component: () => import(/* webpackChunkName: "/lmShop/standardQuery" */ "../views/lmPortals/utilityTools/standardQuery/index.vue"),
          },
          {
            path: 'ygpProject',
            meta: {
              // requiresAuth: true, // 添加需要登录的元信息
            },
            component: () => import(/* webpackChunkName: "lmShop/ygpProject" */ '../views/lmPortals/ygpProject/index'),
            children: [
              {
                path: '',    //
                meta: {
                  title: "易改图",
                },
                component: () => import(/* webpackChunkName: "lmShop/ygpProject/edit" */ "../views/lmPortals/ygpProject/edit"),
              },
              {
                path: 'list',    //
                meta: {
                  title: "在线改图-草稿列表",
                },
                component: () => import(/* webpackChunkName: "lmShop/ygpProject/list" */ "../views/lmPortals/ygpProject/list"),
              },
            ]
          },
          {
            path: 'cart',
            component: () => import(/* webpackChunkName: "/lmShop/cart" */ '../views/lmPortals/cart/index'),
            meta: {
              title: '购物车',
              background:'#FFFFFF',
            },
          },
          {
            path: 'confirmOrder',
            component: () => import(/* webpackChunkName: "/lmShop/confirmOrder" */ '../views/lmPortals/userCart/confirmOrder'),
            meta: {
              title: '提交订单',
            },
          },
          {
            path: 'paymentPage',
            component: () => import(/* webpackChunkName: "/lmShop/paymentPage" */ '../views/lmPortals/paymentPage'),
            meta: {
              title: '我的收银台',
            },
          },
          {
            path: 'enterpriseInfo',
            component: () => import(/* webpackChunkName: "/lmShop/enterpriseInfo" */ '../views/lmPortals/enterpriseInfo'),
            meta: {
              title: '企业资料',
              footerMenu: false, // 底部菜单
            },
          },
          {
            path: 'salesOrder',
            component: () => import(/* webpackChunkName: "/lmShop/salesOrder" */ '../views/lmPortals/salesOrder'),
            meta: {
              title: '我的订单',
            },
          },
        ]
      },
      {
        path: 'invite',
        component: () => import(/* webpackChunkName: "lmPortals/invite" */ '../views/lmPortals/invite/index'),
        meta: {
          requiresAuth: false,
          cleanLayout: true,
          title: '邀请',
        },
      },
      {
        path: 'login',
        component: () => import(/* webpackChunkName: "lmPortals/login" */ '../views/lmPortals/login/index'),
        meta: {
          requiresAuth: false,
          cleanLayout: true,
          title: '登录',
        },
      },
      {
        path: 'productList',
        component: () => import(/* webpackChunkName: "lmPortals/productList" */ '../views/lmPortals/productList/index'),
        meta: {
          title: '商品列表',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        // 非标
        path: 'noStdProductList',
        component: () => import(/* webpackChunkName: "lmPortals/noStdProductList" */ '../views/lmPortals/productList/index'),
        meta: {
          title: '商品列表',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        path: 'cart',
        component: () => import(/* webpackChunkName: "lmPortals/cart" */ '../views/lmPortals/cart/index'),
        meta: {
          title: '购物车',
          background:'#FFFFFF',
        },
      },
      {
        path: 'confirmOrder',
        component: () => import(/* webpackChunkName: "lmPortals/confirmOrder" */ '../views/lmPortals/userCart/confirmOrder'),
        meta: {
          title: '提交订单',
        },
      },
      {
        path: 'enterpriseInfo',
        component: () => import(/* webpackChunkName: "lmPortals/enterpriseInfo" */ '../views/lmPortals/enterpriseInfo'),
        meta: {
          title: '企业资料',
          footerMenu: false, // 底部菜单
        },
      },
      {
        path: 'salesOrder',
        component: () => import(/* webpackChunkName: "lmPortals/salesOrder" */ '../views/lmPortals/salesOrder'),
        meta: {
          title: '销售订单',
        },
      },
      {
        path: "utilityTools",
        meta: {
          title: "实用工具",
        },
        component: () => import(/* webpackChunkName: "utilityTools" */ "../views/lmPortals/utilityTools/index"),
        children: [
          {
            path: "",
            meta: {
              title: "实用工具",
            },
            component: () => import(/* webpackChunkName: "utilityTools/home" */ "../views/lmPortals/utilityTools/home.vue"),
          },
          {
            path: 'platingCodeQuery',
            meta: {
              title: '镀别代号查询',
            },
            component: () => import(/* webpackChunkName: "utilityTools/platingCodeQuery" */ '../views/lmPortals/utilityTools/platingCodeQuery/index.vue'),
          },
          {
            path: 'gradeControl',
            meta: {
              title: '钢号中外对照表',
            },
            component: () => import(/* webpackChunkName: "utilityTools/gradeControl" */ '../views/lmPortals/utilityTools/gradeControl/index.vue'),
          },
          {
            path: 'standardControl',
            meta: {
              title: '标准对照',
            },
            component: () => import(/* webpackChunkName: "utilityTools/standardControl" */ '@/views/lmPortals/utilityTools/standardControl/index.vue'),
          },
          {
            path: "ygt",
            meta: {
              title: "易改图",
            },
            component: () => import(/* webpackChunkName: "utilityTools/ygt" */ "@/views/lmPortals/utilityTools/ygt.vue"),
          },
          {
            path: "threadStressarea",
            meta: {
              title: "螺纹应力截面积",
            },
            component: () => import(/* webpackChunkName: "utilityTools/threadStressarea" */ "@/views/lmPortals/utilityTools/threadAcc/stressArea"),
          },
          {
            path: "threadDimension",
            meta: {
              title: "螺纹尺寸标准值查询",
            },
            component: () => import(/* webpackChunkName: "utilityTools/threadDimension" */ "@/views/lmPortals/utilityTools/threadAcc/dimension.vue"),
          }
        ],
      },
      {
        path: "standardview",
        meta: {
          title: "零件数字库",
        },
        component: () => import(/* webpackChunkName: "lmPortals/standardview" */ "@/views/lmPortals/utilityTools/standardview/fastener"),
      },
      {
        path: "standardQuery",
        meta: {
          title: "标准查询",
          footerMenu: true, // 底部菜单
        },
        component: () => import(/* webpackChunkName: "utilityTools/standardQuery" */ "@/views/lmPortals/utilityTools/standardQuery/index.vue"),
      },
      {
        path: 'quickOrder',
        component: () => import(/* webpackChunkName: "lmPortals/quickOrder" */ '../views/lmPortals/quickOrder/index'),
        meta: {
          title: '快速下单',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        path: 'myGoods',
        component: () => import(/* webpackChunkName: "lmPortals/myGoods" */ '../views/lmPortals/myGoods/index'),
        meta: {
          title: '我的商品档',
          floatingMenu: true, // 浮动菜单
        },
      },
      {
        path: 'mySupplier',
        component: () => import(/* webpackChunkName: "lmPortals/myGoods" */ '../views/lmPortals/mySupplier/index'),
        meta: {
          title: '我的卖家',
          floatingMenu: true, // 浮动菜单
          footerMenu: true, // 底部菜单
        },
      },
      {
        path: 'ygpProject',
        meta: {
          // requiresAuth: true, // 添加需要登录的元信息
        },
        component: () => import(/* webpackChunkName: "lmPortals/ygpProject" */ '../views/lmPortals/ygpProject/index'),
        children: [
          {
            path: '',    //
            meta: {
              title: "易改图",
            },
            component: () => import(/* webpackChunkName: "lmPortals/ygpProject/edit" */ "../views/lmPortals/ygpProject/edit"),
          },
          {
            path: 'list',    //
            meta: {
              title: "在线改图-草稿列表",
            },
            component: () => import(/* webpackChunkName: "lmPortals/ygpProject/list" */ "../views/lmPortals/ygpProject/list"),
          },
        ]
      },
      {
        path: 'paymentPage',
        component: () => import(/* webpackChunkName: "lmPortals/paymentPage" */ '../views/lmPortals/paymentPage'),
        meta: {
          title: '我的收银台',
        },
      },
      {
        path: 'payResult',
        component: () => import(/* webpackChunkName: "lmPortals/paymentPage" */ '../views/lmPortals/paymentPage/payResult'),
        meta: {
          title: '支付结果',
        },
      },
      {
        path: 'helpCenter',
        component: () => import(/* webpackChunkName: "lmPortals/paymentPage" */ '../views/lmPortals/helpCenter'),
        meta: {
          title: '帮助中心',
        },
      },

    ]
  },

  {
    path: '/order',
    meta: {
      requiresAuth: false, // 添加需要登录的元信息
      module: 'order',
    },
    component: () => import(/* webpackChunkName: "order" */ '../layout/orderLayout/index'),
    children: [
      {
        path: 'fwsm',
        component: () => import(/* webpackChunkName: "order/richText" */ '../views/order/richText'),
        meta:{
          code:'PC_LIANMENG_DESCR_20240725'
        }
      },
      {
        path: '/t/invite',    //
        component: () => import(/* webpackChunkName: "order/richText" */ '../views/order/transfer/inviteTransfer'),
      },
    ]
  }
]

/**
 * 递归地合并父路由的 meta 到子路由
 * @param {Array} routes - 路由配置数组
 * @param {Object} parentMeta - 父路由的 meta 数据
 */
function inheritMeta(routes, parentMeta = {}) {
  routes.forEach(route => {
    // 合并父级的 meta 和当前路由的 meta
    route.meta = {
      ...parentMeta,
      ...(route.meta || {})
    };
    if (route.children && route.children.length > 0) {
      inheritMeta(route.children, route.meta);
    }
  });
}

inheritMeta(routes)

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  // 前往的路由是否需要登录
  // （！！！登出所在页的路由需要把requiresAuth设置为false，否则会死循环）
  const requiresAuth = to.meta ? to.meta.requiresAuth : false;
  if (requiresAuth) {
    // 外联盟检查是否有token
    // if (to.path.includes('/lmPortals')) {
    //   const { token } = JSON.parse(localStorage.getItem('lmPortals')) || {};
    //   if (!token) {
    //     // 如果未登录，提示用户并跳转到登录页
    //     // Message.error('您还没有登录，请先登录');
    //     return next('/lmPortals/login');
    //   }
    // }
    if (!from.path.includes('/union')){
      //切换路由时，刷新消息未读数量
      store.dispatch('lmPortals/getUserUnreadCount');
    }
    // 店铺模块需要tid
    if (to.meta?.module === 'lmShop' && !to.query.tid) {
      let tid = ''
      if (from.meta?.module === 'lmShop' && from.query.tid ) tid = from.query.tid
      else  tid = store.state.lmPortals.shopTenantId;
      if (tid) {
        // 如果 tid 存在，则添加到 query 并继续导航
        return next({...to, query: { ...to.query, tid }});
      } else {
        Message.error('店铺ID 不存在!');
        if (from.path === '/') return  next({ path: '/lmPortals/home' });
        else return next(false);// 继续导航
      }
    }
  }
  next();// 继续导航
});

let originPush =  VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject){
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  }else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, ()=>{}, ()=>{});
  }
}

export default router
