import request from "@/api_lm/request"
const baseUrl = process.env.VUE_APP_WLM_BASE_URL

/**
 * 获取我的联盟商列表接口
 */
export function lmSupplierList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/lm-supplier-page`,
    method: "get",
    params: {
      ...query,
      limit:100
    },
  });
}
/**
 * 首页供应商分页查询
 */
export function lmSupplierPageList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/lm-supplier-page`,
    method: "get",
    params: query,
  });
}
/**
 * 我申请了会员的供应商查询
 */
export function lmSupplierPageListMy(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/find-goods/lm-supplier-page-my`,
    method: "get",
    params: query,
  });
}
/**
 * 申请状态枚举
 */
export function lmTenantsApplyStatus(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/tenant/lm-tenants-apply-status`,
    method: "get",
    params: query,
  });
}
/**
 * 提交申请
 */
export function confirmLmTenantsApply(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/tenant/lm-tenants-apply`,
    method: "post",
    data: query,
  });
}
/**
 * 供应商地址
 */
export function getTenantCity(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/help/citys-tenant-jh`,
    method: "get",
    params: query,
  });
}

/**
 * 首页-产品分类接口
 */
export function goodsClassifyv2(params,data) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/find-goods/goods-classifyv3`,
    method: "post",
    params,
    data
  });
}

/**
 * 标准列表-筛选条件
 * /lmcp/v1/lm/find-goods/filter-std
 */
export function getFilterStd(data) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/searchgoods/find-goods/filter-simple-fast-lm`,
    method: "post",
    data,
  });
}

/**
 * 首页-标准列表
 * /lmcp/v1/lm/find-goods/dh-std
 */
export function findGoodsDhStd(data) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/searchgoods/find-goods/std-simple-fast-lm`,
    method: "post",
    data,
  });
}

/**
 * 首页-标准列表(材质球)
 * 将表色接口分开，表色接口后一步加载
 * /lmcp/v1/lm/find-goods/dh-std
 */
export function findGoodsDhStdSurfaces(data) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/searchgoods/find-goods/surfaces-simple-fast-lm`,
    method: "post",
    data,
    headers:{
      isLoading: false
    },
  });
}

